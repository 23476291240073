// https://github.com/WICG/focus-visible
import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import 'focus-visible/dist/focus-visible'
import ReactDOM from 'react-dom/client'
import App from './App'
import RootApp from './RootApp'
import theme from './theme'
import './theme/fonts.css'

const config: AwsRumConfig = {
  sessionSampleRate: 1,
  guestRoleArn: process.env.VITE_AWS_RUM_GUEST_ROLE,
  identityPoolId: process.env.VITE_AWS_RUM_IDENTITY_POOL_ID,
  endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
  telemetries: ['performance', 'errors', 'http'],
  allowCookies: true,
  enableXRay: false,
  sessionEventLimit: 0
}

const APPLICATION_ID: string = process.env.VITE_AWS_RUM_APPLICATION_ID as string
const APPLICATION_VERSION: string = '1.0.0'
const APPLICATION_REGION: string = 'eu-west-1'

export const awsRum: AwsRum = new AwsRum(
  APPLICATION_ID,
  APPLICATION_VERSION,
  APPLICATION_REGION,
  config
)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RootApp font={theme.fonts.body}>
    <App />
  </RootApp>
)
