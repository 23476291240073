import { DeleteIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Text } from '@chakra-ui/react'
import React from 'react'

type SelectedProductRowProps = {
  label: string
  value: string
  labelWidth?: string
  onDelete?: () => void
}

export default function SelectedProductRow({
  label,
  value,
  labelWidth = '130px',
  onDelete
}: SelectedProductRowProps): React.ReactElement {
  return (
    <Flex alignItems="center" gap="16px">
      <Text data-testid="card-row-label" variant="faint" w={labelWidth}>
        {label}
      </Text>
      <Flex alignItems="center" gap="7px">
        <Text data-testid="card-row-value" variant="label" fontSize="16px" fontWeight="700">
          {value}
        </Text>
        <IconButton
          isRound={true}
          variant="link"
          colorScheme="red"
          aria-label="Done"
          fontSize="15px"
          size="sm"
          icon={<DeleteIcon color="red" />}
          onClick={onDelete}
        />
      </Flex>
    </Flex>
  )
}
