import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import * as React from 'react'

const DebtSettlementTooltip: React.FC = () => (
  <Flex flexDirection="column">
    <Text fontSize={16} fontWeight={500} mb={4}>
      You may have credit payments that we can remove from the equation.
    </Text>
    <Text fontWeight={400} fontSize={14}>
      For instance, the account for your current financed vehicle (if you have one) is a good
      example. If you’re planning to settle (close) that account when you take up a new vehicle
      finance, we don’t have to take it into consideration when calculating your affordability.
    </Text>
    <UnorderedList color="white" fontWeight={300} fontSize={12}>
      <ListItem>
        Consider a situation where we determine that you can afford a new monthly car payment of no
        more than R2000.
      </ListItem>
      <ListItem>
        Now, imagine confirming that you’re about to finish paying off an existing vehicle finance
        contract that currently costs you R3000 per month.
      </ListItem>
      <ListItem>
        In this case, we&apos;d recalculate your affordability, factoring in that the R3000 per
        month payment is soon to be a thing of the past. Your “new” affordability would be
        significantly higher.
      </ListItem>
    </UnorderedList>
  </Flex>
)

export default DebtSettlementTooltip
