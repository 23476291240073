// https://github.com/WICG/focus-visible
import { CSSReset } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import 'focus-visible/dist/focus-visible'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import ApolloGQLProvider from './context/ApolloProvider'
import './theme/fonts.css'

const RootApp = ({ children, font }: { children: React.ReactElement; font: string }) => (
  <React.StrictMode>
    <ApolloGQLProvider>
      <BrowserRouter>
        <CSSReset />
        <Global
          styles={css`
            * {
              font-family: ${font};
            }
          `}
        />
        <HelmetProvider>{children}</HelmetProvider>
      </BrowserRouter>
    </ApolloGQLProvider>
  </React.StrictMode>
)

export default RootApp
