import { EmailIcon, QuestionIcon } from '@chakra-ui/icons'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Accordion as ChakraAccordion,
  Heading,
  IconButton,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useMediaQuery
} from '@chakra-ui/react'
import * as React from 'react'
import { FaQuestion, FaXmark } from 'react-icons/fa6'
import { Accordion, SupportRequestMessage } from '../'

const FAQs = [
  {
    title: 'I am not receiving an OTP?',
    description:
      "Network constraints from our providers may be causing delays in OTP's being submitted. Please allow a few hours to pass and then try again. Should the issue persist for longer please get in contact with us using the below comment box."
  },
  {
    title: 'The vehicle I want to finance is not available on your list?',
    description:
      'It is possible for delays to occur between the launch of a vehicle and it being updated on QuickApp. Please select a vehicle that best describes what you would like to finance - we will update the specific vehicle during the course of the financing process. '
  },
  {
    title: 'I need assistance with completing this finance application.',
    description:
      'We would be happy to assist you with securing pre-approval. Simply send an email to finance@vwfs.co.za and we will contact you to ensure your journey to vehicle finance is as simple as possible.'
  },
  {
    title: 'I want to make changes to the information I have provided during the QuickApp.',
    description:
      'QuickApp applications serve as indicative pre-approvals of what you could afford to finance. During the validation and processing of finance applications changes can be made to the structure and terms of the agreement.'
  },
  {
    title: 'Does a QuickApp application place any obligation on me?',
    description:
      'No - the pre-approval does not place any obligation on you or Volkswagen Financial Services.'
  },
  {
    title: 'I can no longer see a previous QuickApp application?',
    description:
      'In accordance with legislation, our QuickApp applications are valid for 7 days. We automatically archive applications after 7 days in keeping with this regulatory requirement.'
  }
]

const CREDIT_BUREAUS = ['Compuscan', 'TransUnion', 'Experian']

const HelpAndSupport: React.FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const initialFocusRef = React.useRef<HTMLDivElement>(null)
  return (
    <Popover flip={false} placement="top-end" closeOnBlur={false} initialFocusRef={initialFocusRef}>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <span
              style={{
                zIndex: 99999,
                position: 'fixed',
                marginLeft: isMobile ? '80vw' : '95vw',
                marginTop: '85vh'
              }}
            >
              <IconButton
                size="lg"
                colorScheme="warning"
                aria-label="help button"
                icon={isOpen ? <FaXmark /> : <FaQuestion size={25} />}
              />
            </span>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverHeader fontWeight="semibold">
                <Heading data-testid="landing-page-head" fontSize={['1xl', '2xl']}>
                  Help center
                </Heading>
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Tabs defaultIndex={0} variant="line">
                  <TabPanels>
                    <TabPanel padding={0}>
                      <Text textAlign="center" py={4} fontWeight={600} fontSize={18}>
                        How can we help?
                      </Text>
                      <ChakraAccordion allowToggle>
                        {FAQs.map((faqs) => (
                          <Accordion
                            key={faqs.title}
                            title={faqs.title}
                            description={faqs.description}
                          />
                        ))}

                        <AccordionItem>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              <Text
                                data-testid="landing-page-description"
                                fontSize={['md', '15px']}
                                variant={'subHeading'}
                                fontWeight={500}
                              >
                                Why have I been declined pre-approval?
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            <Text
                              data-testid="landing-page-description"
                              fontSize={14}
                              mb={4}
                              fontWeight={300}
                            >
                              Numerous factors could lead to failure to secure pre-approval - should
                              you wish to engage further please follow the provided contact channels
                              and we would be happy to assist you.
                            </Text>
                            <Text
                              data-testid="landing-page-description"
                              fontSize={14}
                              fontWeight={300}
                            >
                              The credit bureaus you can contact for a dispute or for any further
                              information of your credit profile are:
                            </Text>
                            <UnorderedList>
                              {CREDIT_BUREAUS.map((item) => (
                                <ListItem key={item}>{item}</ListItem>
                              ))}
                            </UnorderedList>
                          </AccordionPanel>
                        </AccordionItem>
                      </ChakraAccordion>
                    </TabPanel>
                    <TabPanel>
                      <SupportRequestMessage onClosePopover={onClose} />
                    </TabPanel>
                  </TabPanels>
                  <TabList alignItems="center" placeContent="center" mt={6}>
                    <Tab>
                      <QuestionIcon />
                      <Text fontSize={16} fontWeight={700} ml={2}>
                        Help
                      </Text>
                    </Tab>
                    <Tab>
                      <EmailIcon />
                      <Text fontSize={16} fontWeight={700} ml={2}>
                        Message
                      </Text>
                    </Tab>
                  </TabList>
                </Tabs>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}

export default HelpAndSupport
