import { Button, Flex, Heading, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { monthlyExpenseDefaultValues, monthlyExpenseSchema } from '../../utils'
import formatFinanceAmount, {
  reverseFormatNumber
} from '../../utils/math/format/formatFinanceAmount'
import { ExpenseCategory, MonthlyExpense } from '../Form/IncomeDetailForm'
import { TextInput } from '../FormElements'

type MonthlyExpenseCalculatorProps = {
  calculateTotal: (total: number) => void
  setMonthlyExpense: (monthlyExpense: MonthlyExpense) => void
  monthlyExpense?: MonthlyExpense
}

function MonthlyExpenseCalculator({
  calculateTotal,
  setMonthlyExpense,
  monthlyExpense
}: MonthlyExpenseCalculatorProps): React.ReactElement<MonthlyExpenseCalculatorProps> {
  const [error, setError] = React.useState<string>('')

  const methods = useForm({
    defaultValues: monthlyExpense ?? monthlyExpenseDefaultValues,
    resolver: zodResolver(monthlyExpenseSchema)
  })

  type ExpenseFormInput = {
    name: ExpenseCategory
    label: string
  }

  const inputs: ExpenseFormInput[] = [
    { name: 'rent', label: 'Rent (excluding home loan/s and bond/s)' },
    { name: 'householdExpenses', label: 'Household expenses (including groceries)' },
    {
      name: 'transportCost',
      label: 'Transport expenses (including fuel and insurance, excluding car payments)'
    },
    { name: 'entertainmentExpense', label: 'Entertainment' },
    { name: 'educationExpense', label: 'Education' },
    { name: 'childMaintenance', label: 'Child maintenance' },
    { name: 'otherCosts', label: 'Other costs (excluding any previously categorized expenses)' }
  ]

  const calculate = (): void => {
    const values = methods.getValues()
    let total = 0

    for (const [, value] of Object.entries(values)) {
      const expenseValue = reverseFormatNumber(value ?? '0')
      if (expenseValue > 0) {
        total += expenseValue
      }
    }

    if (total > 0) {
      setError('')
      setMonthlyExpense(values)
      calculateTotal(total)
    } else {
      setError('Monthly expense is required!')
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: ExpenseCategory) => {
    const { value } = e.target
    const numericValue = value.replace(/\D/g, '') // remove non-numeric characters
    const formattedValue = formatFinanceAmount(numericValue)
    methods.setValue(name, formattedValue)
  }

  return (
    <Flex
      align="center"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex={1}
      data-testid="expense-calculator-form"
    >
      <Stack spacing={2} mx="auto" w={[300, 500]} paddingX={6}>
        <Heading>Monthly expenses</Heading>
        <Text>
          In order to calculate your total monthly expenses, please enter the totals for each of the
          following expense items:
        </Text>
        <Text fontWeight={600}>Please note:</Text>
        <Text>We pick up certain expenses with the credit bureau.</Text>
        <Text>These expenses include:</Text>
        <UnorderedList>
          <ListItem>Existing vehicle finance.</ListItem>
          <ListItem>Home loans and bonds.</ListItem>
          <ListItem>Personal loans.</ListItem>
        </UnorderedList>
        <Text>
          These expenses can therefore be excluded from the relevant categories, should they be
          applicable to you.
        </Text>
        <FormProvider {...methods}>
          <Stack width={['80%', '60%', '80%', '100%']} justify={'center'} align="center">
            {inputs.map((value) => (
              <TextInput
                data-testid={`expense-calculator-form-${value.name}`}
                name={value.name}
                label={value.label}
                key={value.name}
                onChange={(e) => handleInputChange(e, value.name)}
              />
            ))}
            <Button data-testid="expense-calculator-submit-button" size="lg" onClick={calculate}>
              Calculate
            </Button>
          </Stack>
        </FormProvider>
        <Text color="red">{error}</Text>
      </Stack>
    </Flex>
  )
}
export default MonthlyExpenseCalculator
