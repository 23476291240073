import { Balloon } from './score.grid'

export class SliderValues {
  public monthlyInstallment: number | undefined = undefined
  public monthlyInstallmentDiff: number | undefined = undefined

  public terms: number | undefined = undefined
  public deposit: number | undefined = undefined
  public baloon: number | undefined = undefined

  public baloonItems: Balloon[] | undefined = undefined
  public interrestRate: number | undefined = undefined
}
