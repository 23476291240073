import { Stack } from '@chakra-ui/react'
import React from 'react'
import AddressForm from '../../components/Form/AddressScreen'

function AddressScreen(): React.ReactElement {
  return (
    <Stack width={['400px', '500px', '100%']}>
      <AddressForm />
    </Stack>
  )
}

export default AddressScreen
