import { Button, HStack, Select, Spinner, Stack, Text } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { RadioInput } from 'src/models/RadioInput'
import { TextInput } from '../..'
import { useAuth } from '../../../context/AuthProvider'
import { RegisterInput } from '../../../generated'
import { ID_NUMBER_REGEX, createUserDefaultValues, createUserSchema } from '../../../utils'
import VWRadioGroup from '../../VWRadioGroup'

const PHONE_NUMBER_CODE = '+27'

/**
 * It takes the form values, adds the gender and phone number code to it, and then sends it to the
 * server
 */
function CreateUserForm() {
  const { register, baseURL } = useAuth()
  const [error, setError] = useState<string>('')
  const [isGettingOtp, setIsGettingOtp] = useState<boolean>(false)
  const [gender, setGender] = useState<string>()
  const [year, setYear] = useState<string>()
  const [month, setMonth] = useState<string>()
  const [day, setDay] = useState<string>()
  const navigate = useNavigate()

  type typeOfGenderValues = 'male' | 'female'
  const genderValues: RadioInput<typeOfGenderValues>[] = [
    { value: 'male', message: 'Male' },
    { value: 'female', message: 'Female' }
  ]

  const addZeroToMonth = (month: number): string => {
    if (month.toString().split('').length < 2) return `0${month}`
    return month.toString()
  }

  const currentYear = new Date().getFullYear()
  const yearOptions = [...Array(100).keys()].map((x) => {
    const indexedYear = currentYear - x
    return (
      <option value={indexedYear} key={x}>
        {indexedYear}
      </option>
    )
  })

  const monthOptions = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ].map((month, i) => {
    return (
      <option value={addZeroToMonth(i + 1)} key={month}>
        {month}
      </option>
    )
  })

  const dayOptions = () => {
    const dayArr = (noOfDays: number) =>
      [...Array(noOfDays).keys()].map((x) => {
        const fixedDay = (x + 1).toString()
        const lengthIsSmallerThan2 = fixedDay.split('').length < 2
        const fomattedDay = lengthIsSmallerThan2 ? `0${fixedDay}` : fixedDay
        return (
          <option value={fomattedDay} key={x}>
            {fomattedDay}
          </option>
        )
      })
    switch (month) {
      case '01':
        return dayArr(31)
      case '02':
        if (parseInt(year ?? '1') % 4 === 0) return dayArr(29)
        return dayArr(28)
      case '03':
        return dayArr(31)
      case '04':
        return dayArr(30)
      case '05':
        return dayArr(31)
      case '06':
        return dayArr(30)
      case '07':
        return dayArr(31)
      case '08':
        return dayArr(31)
      case '09':
        return dayArr(30)
      case '10':
        return dayArr(31)
      case '11':
        return dayArr(30)
      case '12':
        return dayArr(31)
    }
    return dayArr(30)
  }

  const methods = useForm({
    defaultValues: createUserDefaultValues,
    resolver: zodResolver(createUserSchema)
  })

  const idNumber = methods.watch('idNumber')

  function formatUserNumberToInternationalFormat(userNumber: string): string {
    return `${PHONE_NUMBER_CODE}${userNumber.slice(1)}`
  }

  React.useEffect(() => {
    if (!isNaN(Number(idNumber)) && idNumber.length > 1) {
      if (!ID_NUMBER_REGEX.test(idNumber)) {
        methods.setError('idNumber', {
          type: 'custom',
          message: 'Please insert a valid ID'
        })
      } else {
        methods.clearErrors('idNumber')
      }
      const genderCode = idNumber.substring(6, 10)
      const genderFromId = parseInt(genderCode) < 5000 ? 'female' : 'male'
      setGender(genderFromId)
    } else {
      methods.clearErrors('idNumber')
    }
  }, [idNumber])

  /**
   * It takes the form values, adds the gender and phone number code to it, and then sends it to the
   * server
   */
  const checkDOB = (): boolean => {
    if ((!year || !month || !day) && isNaN(Number(idNumber))) {
      setError('Please select your date of birth')
      return false
    }
    methods.setValue('dob', `${year}-${month}-${day}`)
    return true
  }

  const checkGender = (): boolean => {
    if (!gender) {
      setError('Please select your gender')
      return false
    }
    return true
  }

  async function onSubmit(): Promise<void> {
    if (!checkGender()) return
    if (!checkDOB()) return
    setIsGettingOtp(true)
    methods.clearErrors()
    setError('')
    const formValues = methods.getValues()

    const phoneNumber = formatUserNumberToInternationalFormat(formValues.phoneNumber.trim())

    const input: RegisterInput = {
      ...formValues,
      gender,
      phoneNumber
    }

    const res = await register?.({
      ...input
    })

    if (res?.error) {
      setError(res.error)
      setIsGettingOtp(false)
    }

    setIsGettingOtp(false)
  }

  return (
    <FormProvider {...methods}>
      <HStack>
        <Text data-testid="signup-login-alert" fontSize="sm" color="brand.1000">
          Already have a profile?
        </Text>
        <Button
          data-testid="signup-login-link"
          variant="callToAction"
          onClick={() => navigate(`${baseURL}login`)}
        >
          Log In
        </Button>
      </HStack>
      <Stack spacing={2} justify="center" color="brand.200">
        <TextInput
          data-testid="signup-name-text-input"
          label="Name"
          name="name"
          id="name-input"
          type="text"
        />
        <TextInput
          data-testid="signup-surname-text-input"
          label="Surname"
          name="surname"
          type="text"
        />
        <TextInput data-testid="signup-email-text-input" label="Email" name="email" type="email" />
        <TextInput
          label="Phone Number"
          name="phoneNumber"
          data-testid="signup-phone-number-text-input"
          type="text"
          placeholder="073 234 5678"
        />
        <TextInput
          data-testid="signup-id-text-input"
          label="ID or Passport Number"
          name="idNumber"
          type="text"
          customError={methods.formState.errors.idNumber?.message}
        />
        {isNaN(Number(idNumber)) && (
          <>
            <Stack pt="1rem">
              <VWRadioGroup values={genderValues} setValue={setGender} currentValue={gender} />
            </Stack>
            <HStack spacing={4} justify="center" pt="1rem">
              <Select
                data-testid="signup-year-select-input"
                placeholder="Year"
                onChange={(event) => setYear(event.target.value)}
              >
                {yearOptions}
              </Select>
              <Select
                data-testid="signup-month-select-input"
                placeholder="Month"
                onChange={(event) => setMonth(event.target.value)}
              >
                {monthOptions}
              </Select>
              <Select
                placeholder="Day"
                data-testid="signup-day-select-input"
                onChange={(event) => setDay(event.target.value)}
                disabled={!month || !year}
              >
                {dayOptions()}
              </Select>
            </HStack>
          </>
        )}

        <Stack spacing={6}>
          <Text data-testid="signup-error-text" fontSize="sm" color="red.300">
            {error}
          </Text>
          <Button
            data-testid="signup-submit-button"
            loadingText=""
            size="lg"
            id="login-button"
            onClick={methods.handleSubmit(onSubmit)}
            isDisabled={!isEmpty(methods.formState.errors)}
          >
            {isGettingOtp ? <Spinner /> : 'Create Account'}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default CreateUserForm
