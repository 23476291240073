import { Box, Stack } from '@chakra-ui/react'
import React from 'react'
import EmploymentDetailsForm from '../../components/Form/EmployeeDetailsForm'

const EmployeeDetailsScreen = (): React.ReactElement => {
  return (
    <Stack width={['400px', '500px', '100%']}>
      <Box>
        <EmploymentDetailsForm />
      </Box>
    </Stack>
  )
}

export default EmployeeDetailsScreen
