import { ChakraProvider, CSSReset } from '@chakra-ui/react'
import { FC, ReactElement } from 'react'
import { RecoilRoot } from 'recoil'
import {
  AppProvider,
  AuthProvider,
  PreApprovalStepsProvider,
  UserDataProvider
} from '../../context'
import ApolloGQLProvider from '../../context/ApolloProvider'

type Props = {
  theme: Record<string, any>
  children: ReactElement
  baseURL: string
  appName: string
}
export const AppContent: FC<Props> = ({ theme, children, baseURL, appName }) => {
  return (
    <ApolloGQLProvider>
      <RecoilRoot>
        <AuthProvider baseURL={baseURL} appName={appName}>
          <UserDataProvider>
            <AppProvider>
              <ChakraProvider theme={theme}>
                <CSSReset />
                <PreApprovalStepsProvider>{children}</PreApprovalStepsProvider>
              </ChakraProvider>
            </AppProvider>
          </UserDataProvider>
        </AuthProvider>
      </RecoilRoot>
    </ApolloGQLProvider>
  )
}
