import { Avatar, Button, Flex, Icon, Spacer, Stack, Text } from '@chakra-ui/react'
import { FiLogOut } from 'react-icons/fi'
import { Outlet } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import Logo from '../Logo'
import React from 'react'

type Props = {
  logo: string
}

const Navbar: React.FC<Props> = ({ logo }) => {
  const { user, logout } = useAuth()

  return (
    <>
      <Flex
        alignItems="center"
        as="header"
        backgroundColor="base.100"
        justifyContent="space-between"
        paddingX={4}
      >
        <Stack m={0} p={0} flex={1}>
          <Logo width="230px" logo={logo} />
        </Stack>

        <Spacer />

        <Flex height={16} alignItems="center" justifyContent="center" as="nav" flex={1} gap={5}>
          <Avatar name={`${user?.name} ${user?.surname}`} bg="base.200" color="primary.500" />
          <Flex alignItems="center">
            <Stack direction="column" spacing={0}>
              <Text color="primary.800" order={0}>{`${user?.name} ${user?.surname}`}</Text>
              <Text fontWeight={400} lineHeight="24px" color="base.500" order={1}>
                {user ? user.email : ''}
              </Text>
            </Stack>
          </Flex>
          <Button data-testid="navbar-logout" variant="outline" onClick={logout}>
            <Icon as={FiLogOut} />
            Log Out
          </Button>
        </Flex>
      </Flex>
      <Outlet />
    </>
  )
}

export default Navbar
