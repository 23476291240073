import { Flex, Link, Text } from '@chakra-ui/react'
import * as React from 'react'
import { FooterOptionsCardProps, isValidEmail } from '../../utils'

export default function FooterOptionsCard({
  label,
  value,
  icon
}: FooterOptionsCardProps): React.ReactElement {
  const valueIsArray = typeof value === 'string'
  return (
    <Flex alignItems="center" gap="70px">
      <Flex alignItems="center" gap="10px">
        {icon}
        <Text variant="faint" width="80px" textAlign="start">
          {label}:{' '}
        </Text>
      </Flex>
      {valueIsArray ? (
        !isValidEmail(value) ? (
          <Text width="170px" textAlign="start">
            {value}
          </Text>
        ) : (
          <Link href={`mailto:${value}`} isExternal color="blue.300">
            {value}
          </Link>
        )
      ) : (
        <Flex flexDirection="column">{value?.map((item) => <Text key={item}>{item}</Text>)}</Flex>
      )}
    </Flex>
  )
}
