import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons'
import { Avatar, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import AffordabilityAmountText from '../../../components/AffordabilityAmountText'
import VWRadioGroup from '../../../components/VWRadioGroup'
import { useAuth } from '../../../context/AuthProvider'
import { usePreApprovalSteps } from '../../../context/PreApprovalStepsProvider'
import { useData } from '../../../context/UserDataProvider'
import { useSendDeclinedPreApprovalEmailMutation } from '../../../generated'
import { RadioInput } from '../../../models/RadioInput'

type ApprovedRejecetionDealModalProps = {
  onGoToDashboard?: () => void
  onCompleteDetails?: () => void
  dealApproved: boolean
}
export default function ApprovedRejecetionDealModal({
  onGoToDashboard,
  onCompleteDetails,
  dealApproved
}: ApprovedRejecetionDealModalProps): React.ReactElement {
  const { currentStep } = usePreApprovalSteps()
  const { affordableAmount, persistedProgress } = useData()
  const savedVehicleData = persistedProgress?.progress?.[1]
  const { user } = useAuth()
  const [sendDeclinedPreApprovalEmail] = useSendDeclinedPreApprovalEmailMutation()
  const { isOpen: loading, onOpen: startLoading, onClose: endLoading } = useDisclosure()

  const [contactMethod, setContactMethod] = React.useState<string>('email')

  const contactMethodValues: RadioInput<string>[] = [
    { value: 'email', message: 'Email' },
    { value: 'phone', message: 'Phone call' },
    { value: 'noContact', message: 'Do not contact me' }
  ]

  const handleContactMethod = (value: string) => {
    setContactMethod(value)
  }

  const saveAndExitHandler = async () => {
    startLoading()
    if (contactMethod !== 'noContact') {
      await sendDeclinedPreApprovalEmail({
        variables: {
          input: { contactPreference: contactMethod }
        }
      })
    }
    endLoading()
    onGoToDashboard?.()
  }

  const getModalTitle = () => {
    if (currentStep?.() === 0 && dealApproved)
      return `Great news ${user?.name}! Based on the information you’ve provided, you can afford to finance a maximum amount of`
    if (dealApproved) {
      return 'Congratulations! You are approved!'
    }
    return 'Unfortunately we cannot pre-approve you at this time'
  }
  return (
    <Flex
      data-testid="approve-modal"
      alignItems="center"
      p="24px"
      flexDirection="column"
      alignSelf="center"
      gap="32px"
    >
      <Avatar
        icon={dealApproved ? <CheckCircleIcon /> : <CloseIcon color="red" />}
        p="2px"
        backgroundColor="primary.50"
      />
      <Text data-testid="modal-title" variant="title" fontSize="30px" textAlign="center">
        {getModalTitle()}
      </Text>
      {!dealApproved && (
        <Flex flexDirection="column" alignItems="center" gap="16px" width="100%">
          <Text variant="faint" textAlign="center">
            Should you wish to be contacted regarding this vehicle finance application, please
            select your preferred method of contact and VWFS SA will contact you.
          </Text>
          <VWRadioGroup
            currentValue={contactMethod}
            setValue={(value) => {
              handleContactMethod(value)
            }}
            values={contactMethodValues}
          />
        </Flex>
      )}
      {dealApproved && (
        <Flex flexDirection="column" alignItems="center" gap="16px">
          {currentStep?.() !== 0 && (
            <Text variant="faint" textAlign="center">
              We will finance this vehicle for you
            </Text>
          )}
          {currentStep?.() === 0 && dealApproved ? (
            <AffordabilityAmountText amount={affordableAmount} />
          ) : (
            <>
              <Text fontWeight={800} fontSize="20px" color={'primary.500'} textAlign="center">
                {savedVehicleData.make} {savedVehicleData.model}
              </Text>
              <ConfettiExplosion
                data-testid="approved-confetti"
                force={0.9}
                duration={10000}
                particleCount={1000}
                width={3000}
              />
            </>
          )}
          <Text variant="faint" textAlign="center">
            {currentStep?.() !== 0
              ? '*Subject to validation of your application details'
              : 'Get a personalized deal structure for the vehicle of your dreams right away.'}
          </Text>
          {currentStep?.() !== 0 && (
            <Text variant="bold" fontWeight={700} textAlign="center">
              Structure a deal that works for you right away. We just need a few more details.
            </Text>
          )}
        </Flex>
      )}
      <Flex gap="12px">
        {!dealApproved && (
          <Button
            isLoading={loading}
            data-testid="dashboard-button"
            onClick={saveAndExitHandler}
            variant="outline"
          >
            Save and Exit
          </Button>
        )}
        {onCompleteDetails && dealApproved && (
          <>
            <Button data-testid="dashboard-button" onClick={onGoToDashboard} variant="outline">
              Go to Dashboard
            </Button>
            <Button data-testid="complete-details-button" onClick={onCompleteDetails}>
              Complete Details
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  )
}
