import { Term } from './term'
export class ScoreGrid {
  public term: Term[] | undefined = undefined
  public hasGrid: number | undefined = undefined
}

export const defaultGrid = {
  term: [
    {
      months: 72,
      deposit: [
        {
          percentage: 10,
          balloon: [
            {
              percentage: 30,
              decision: 'A',
              linkedRate: 10.49,
              fixedRate: 11.24
            },
            {
              percentage: 15,
              decision: 'A',
              linkedRate: 10.24,
              fixedRate: 10.74
            },
            {
              percentage: 0,
              decision: 'A',
              linkedRate: 9.99,
              fixedRate: 10.49
            }
          ]
        },
        {
          percentage: 0,
          balloon: [
            {
              percentage: 30,
              decision: 'A',
              linkedRate: 10.74,
              fixedRate: 11.49
            },
            {
              percentage: 15,
              decision: 'A',
              linkedRate: 10.49,
              fixedRate: 11
            },
            {
              percentage: 0,
              decision: 'A',
              linkedRate: 10.24,
              fixedRate: 10.74
            }
          ]
        }
      ]
    },
    {
      months: 60,
      deposit: [
        {
          percentage: 10,
          balloon: [
            {
              percentage: 30,
              decision: 'A',
              linkedRate: 10.49,
              fixedRate: 11.24
            },
            {
              percentage: 15,
              decision: 'A',
              linkedRate: 10,
              fixedRate: 10.74
            },
            {
              percentage: 0,
              decision: 'A',
              linkedRate: 9.99,
              fixedRate: 10.49
            }
          ]
        },
        {
          percentage: 0,
          balloon: [
            {
              percentage: 36,
              decision: 'A',
              linkedRate: 10.74,
              fixedRate: 11.49
            },
            {
              percentage: 30,
              decision: 'A',
              linkedRate: 10.74,
              fixedRate: 11.25
            },
            {
              percentage: 15,
              decision: 'A',
              linkedRate: 10.49,
              fixedRate: 10.99
            },
            {
              percentage: 0,
              decision: 'A',
              linkedRate: 10.24,
              fixedRate: 10.74
            }
          ]
        }
      ]
    }
  ]
}
