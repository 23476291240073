import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Spinner,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import * as React from 'react'
import { FiCreditCard } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { CreditAccountCardGroup, DebtSettlementCheckbox, DebtSettlementTooltip } from '../'
import { useAuth } from '../../context/AuthProvider'
import { usePreApprovalSteps } from '../../context/PreApprovalStepsProvider'
import { useData } from '../../context/UserDataProvider'
import {
  CalculatorInput,
  DebtSettlementAccount,
  useGetDebtSettlementAccountsMutation,
  useUpdateMonthlyIncomeMutation
} from '../../generated'
import { ERROR_MESSAGES, SERVER_ERROR_MESSAGE } from '../../utils'
import formatFinanceAmount from '../../utils/math/format/formatFinanceAmount'
import NoDebtSettlement from '../NoDebtSettlement'

type Props = {
  isOpen: boolean
  onClose(): void
  isApproved: boolean
  banksWith: string
  incomeData: () => CalculatorInput
  showApprovalModal: (value: boolean) => void
  onCloseDrawer: () => void
}

const DebtSettlementDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  isApproved,
  onCloseDrawer,
  banksWith,
  showApprovalModal,
  incomeData
}) => {
  const navigate = useNavigate()
  const { baseURL } = useAuth()
  const { isStepLoading, next, setIsStepLoading } = usePreApprovalSteps()
  const {
    debtSettlementAccounts,
    setDebtSettlementAccounts,
    debtSettlementRequestBody,
    preApprovalRequestBody
  } = useData()
  const [creditAccountsNumber, setCreditAccountsNumber] = React.useState<string[]>([])
  const [isAccepted, setIsAccepted] = React.useState<boolean>(false)
  const { isOpen: isTimedOut, onOpen: onTimedOut, onClose: onCloseTimeOut } = useDisclosure()
  const [error, setError] = React.useState<string>('')

  const [getDebtSettlementAccounts, { loading }] = useGetDebtSettlementAccountsMutation({
    onCompleted: (res) => {
      setDebtSettlementAccounts?.(res.getDebtSettlementAccounts as DebtSettlementAccount[])
    },
    onError: () => {
      onTimedOut()
    },
    fetchPolicy: 'no-cache'
  })

  const fetchDebtSettlement = async () => {
    onCloseTimeOut()
    await getDebtSettlementAccounts({
      variables: {
        input: {
          debtSettlementRequestBody
        }
      }
    })
  }

  React.useEffect(() => {
    fetchDebtSettlement()
  }, [isOpen, isApproved])

  const handleCreditAccountCheck = (value: string[]) => {
    setCreditAccountsNumber(value)
  }

  const handleAcceptCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccepted(event.target.checked)
  }

  const totalCapitalBalance = React.useMemo(() => {
    let total = 0
    debtSettlementAccounts?.forEach((crediAccount) => {
      if (creditAccountsNumber.includes(crediAccount.accountNumber)) {
        total += crediAccount.installmentAmount
      }
    })

    return total
  }, [creditAccountsNumber])

  const [updateMonthlyIncomeMutation] = useUpdateMonthlyIncomeMutation({
    onCompleted: async (response) => {
      const responseData = response?.updateMonthlyIncome

      if (responseData?.vafAmount?.status === 'D') {
        showApprovalModal(false)
        onCloseDrawer()
      } else if (responseData?.vafAmount?.status === 'A') {
        showApprovalModal(true)
        onCloseDrawer()
      }

      setError(
        ERROR_MESSAGES[responseData?.vafAmount?.errorMessage as string] ??
          responseData?.vafAmount?.errorMessage
      )

      setIsStepLoading?.(false)
    },
    onError: () => {
      setIsStepLoading?.(false)
      setError(SERVER_ERROR_MESSAGE)
    },
    fetchPolicy: 'no-cache'
  })

  const submitWithSettlementAccounts = async () => {
    setIsStepLoading?.(true)
    setError('')
    const preApprovalReqBody = JSON.parse(preApprovalRequestBody ?? '{}')
    const input = incomeData()
    const preApprovalRequestBodyJSON = JSON.stringify({
      ...preApprovalReqBody,
      income: {
        netIncome: input.monthlyNet,
        grossIncome: input.monthlyGross
      },
      expenses: {
        totalMonthlyExpenses: input.monthlyExpenses
      },
      financeDetails: {
        settlementInformation: creditAccountsNumber?.map((account) => {
          return {
            acountNumber: account
          }
        })
      }
    })
    await updateMonthlyIncomeMutation({
      variables: {
        input: {
          ...incomeData(),
          banksWith: banksWith,
          eventType: 'ESTIMATE_AFFORDABILITY_WITH_DEBT_SETTLEMENT',
          preApprovalRequestBody: preApprovalRequestBodyJSON
        }
      }
    })
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex alignItems="flex-start">
            <Icon as={FiCreditCard} boxSize={8} mr={4} />
            <Text variant="title">Debt settlement</Text>
          </Flex>
          <Text fontWeight={400} fontSize={16} mb={6} variant="label">
            Will you be settling any of the credit accounts we found on your name?
          </Text>
          <Text fontWeight={300} fontSize={14} variant="faint" mb={6}>
            Settling could improve your credit score.
          </Text>
          <Tooltip
            hasArrow
            cursor="pointer"
            label={<DebtSettlementTooltip />}
            bg="primary.600"
            color="base.100"
          >
            <Flex alignItems="center">
              <QuestionOutlineIcon mr={4} color="primaryDark.500" />
              <Text color="primaryDark.500" fontWeight={400} fontSize={14}>
                Why are we asking this?
              </Text>
            </Flex>
          </Tooltip>
        </DrawerHeader>

        <DrawerBody>
          {!loading && !isTimedOut && debtSettlementAccounts?.length === 0 ? (
            <NoDebtSettlement isApproved={isApproved} />
          ) : (
            <CreditAccountCardGroup
              isDisabled={isStepLoading}
              creditAccounts={debtSettlementAccounts ?? []}
              onChange={handleCreditAccountCheck}
              value={creditAccountsNumber}
            />
          )}

          {isTimedOut && debtSettlementAccounts?.length === 0 && (
            <Flex direction="column" alignItems="center">
              <Button
                data-testid="settlemt-reload-button"
                w="100%"
                padding="20px"
                mt={8}
                variant="outline"
                onClick={() => {
                  fetchDebtSettlement()
                }}
              >
                Reload debt settlement
              </Button>
              <Text color="red" textAlign="center">
                We are currently experiencing a high volume of traffic. Kindly Click the reload
                button again. Thank you for your patience.
              </Text>
            </Flex>
          )}
          {loading && (
            <Flex alignItems="center" width="100%" justify="center">
              <Spinner />
            </Flex>
          )}
        </DrawerBody>

        <DrawerFooter>
          <Flex flexDirection="column">
            <Text color="base.600" fontWeight={400} fontSize={14} mt={4}>
              Any vehicle finance deal we show you will depend on this account being settled.
            </Text>
            <Divider my={4} />
            <Flex justifyContent="space-between" alignItems="center" mb={8}>
              <Text color="base.800" fontWeight={400} fontSize={14}>
                This will free up in your monthly budget:
              </Text>
              <Text color="primaryDark.500" fontWeight={400} fontSize={14}>
                R{formatFinanceAmount(`${totalCapitalBalance}`)}
              </Text>
            </Flex>
            {creditAccountsNumber.length > 0 && (
              <DebtSettlementCheckbox
                isDisabled={isStepLoading}
                isChecked={isAccepted}
                onChange={handleAcceptCheck}
              />
            )}
            <Flex justifyContent="flex-end" mt={8}>
              <Button
                data-testid="settlement-skip-button"
                w="103px"
                padding="20px"
                mr={4}
                variant="outline"
                onClick={() => {
                  if (isApproved) {
                    next?.()
                  } else {
                    navigate(`${baseURL}auth/dashboard`)
                  }
                }}
                isDisabled={isStepLoading}
              >
                Skip for now
              </Button>
              <Button
                data-testid="submit-with-debt-settlement"
                w="103px"
                padding="20px"
                isDisabled={!isAccepted}
                onClick={submitWithSettlementAccounts}
                isLoading={isStepLoading}
              >
                Continue
              </Button>
            </Flex>
            <Text color="red">{error}</Text>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default DebtSettlementDrawer
