import { Box, Image, ImageProps } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import images from '../../theme/images'

type Props = { logo: string } & ImageProps
function Logo({ logo, width, ...rest }: Props): React.ReactElement {
  const { isAuthenticated, baseURL } = useAuth()
  const navigate = useNavigate()
  const baseUrl = baseURL ?? '/'
  return (
    <Box
      onClick={() => navigate(isAuthenticated ? `${baseUrl}auth/dashboard` : baseUrl)}
      cursor="pointer"
    >
      <Image
        sx={{
          width: width ?? '300px'
        }}
        objectFit="cover"
        data-testid="logo-image"
        {...rest}
        src={logo}
      />
    </Box>
  )
}

export function AudiLogo({ ...rest }: ImageProps): React.ReactElement {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()

  return (
    <Box onClick={() => navigate(isAuthenticated ? '/auth/dashboard' : '/')} cursor="pointer">
      <Image data-testid="audi-logo-image" {...rest} src={images.audiLogo} />
    </Box>
  )
}
export default Logo

Logo.defaultProps = {
  width: 175,
  height: 'auto'
}

AudiLogo.defaultProps = {
  width: 175,
  height: 'auto'
}
