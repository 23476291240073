import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import { usePreApprovalSteps } from '../../context/PreApprovalStepsProvider'
import { useSendDeclinedPreApprovalEmailMutation } from '../../generated'
import { RadioInput } from '../../models/RadioInput'
import VWRadioGroup from '../VWRadioGroup'

type NoDebtSettlementProps = {
  isApproved: boolean
}
export const NoDebtSettlement: React.FC<NoDebtSettlementProps> = ({ isApproved }) => {
  const { baseURL } = useAuth()
  const { next } = usePreApprovalSteps()
  const [sendDeclinedPreApprovalEmail] = useSendDeclinedPreApprovalEmailMutation()
  const { isOpen: loading, onOpen: startLoading, onClose: endLoading } = useDisclosure()
  const navigate = useNavigate()

  const [contactMethod, setContactMethod] = useState<string>('email')

  const contactMethodValues: RadioInput<string>[] = [
    { value: 'email', message: 'Email' },
    { value: 'phone', message: 'Phone call' },
    { value: 'noContact', message: 'Do not contact me' }
  ]

  const handleContactMethod = (value: string) => {
    setContactMethod(value)
  }

  const saveAndExitHandler = async () => {
    if (contactMethod !== 'noContact') {
      startLoading()
      await sendDeclinedPreApprovalEmail({
        variables: {
          input: { contactPreference: contactMethod }
        }
      })
      endLoading()
    }

    navigate(`${baseURL}auth/dashboard`)
  }

  return (
    <Flex
      data-testid="approve-modal"
      alignItems="center"
      p="24px"
      flexDirection="column"
      alignSelf="center"
      gap="16px"
    >
      <Text data-testid="modal-title" variant="title" fontSize="16px" textAlign="center">
        Unfortunately you do not have debt settlement accounts
      </Text>
      <Flex flexDirection="column" alignItems="center" gap="16px" width="100%">
        <Text variant="faint" textAlign="center">
          {isApproved
            ? 'Get a personalized deal structure for the vehicle of your dreams right away.'
            : 'Should you wish to be contacted regarding this vehicle finance application, please select your preferred method of contact and VWFS SA will contact you.'}
        </Text>
        {!isApproved && (
          <>
            <VWRadioGroup
              currentValue={contactMethod}
              setValue={(value) => {
                handleContactMethod(value)
              }}
              values={contactMethodValues}
              direction="column"
            />
            <Button
              isLoading={loading}
              data-testid="dashboard-button"
              onClick={saveAndExitHandler}
              variant="outline"
            >
              Save and Exit
            </Button>
          </>
        )}
      </Flex>
      {isApproved && (
        <>
          <Button
            data-testid="dashboard-button"
            onClick={() => navigate(`${baseURL}auth/dashboard`)}
            variant="outline"
          >
            Go to Dashboard
          </Button>
          <Button data-testid="complete-details-button" onClick={() => next?.()}>
            Complete Details
          </Button>
        </>
      )}
    </Flex>
  )
}

export default NoDebtSettlement
