import { Stack, Text } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import z from 'zod'
import { SelectInput } from '../../components/FormElements'
import { useAuth } from '../../context/AuthProvider'
import { usePreApprovalSteps } from '../../context/PreApprovalStepsProvider'
import { FormSelectionData, useData } from '../../context/UserDataProvider'
import { FinanceApplicationStatus, MaritalStatusInput } from '../../generated'
import { SelectInput as SelectInputProps } from '../../models/SelectInput'
import { SERVER_ERROR_MESSAGE } from '../../utils'
import { StepsNavigation } from '../ApplicationSection'
import dayjs from 'dayjs'

export const maritualStatusSchema = z.object({
  marriageType: z.object({
    label: z.string(),
    value: z.string({ required_error: 'Marriage type status is required' })
  }),
  marritalStatus: z
    .object({
      label: z.string(),
      value: z.string({ required_error: 'Maritual status is required' })
    })
    .required()
})

const maritalStatuses: Array<FormSelectionData> = [
  { value: 'married', label: 'Married' },
  { value: 'widowed', label: 'Widowed' },
  { value: 'divorced', label: 'Divorced' },
  { value: 'unmarried', label: 'Unmarried' }
]
const marriageContractTypes: SelectInputProps<string>[] = [
  { value: 'ACC', label: 'Accrual System' },
  { value: 'ANC', label: 'With Antenuptial Agreement' },
  { value: 'COP', label: 'Community Of Property' },
  { value: 'TRI', label: 'Tribal' }
]

function MaritalStatusScreen(): React.ReactElement {
  const { maritalStatus, user } = useAuth()
  const { updateMaritalStatus, persistedProgress, persistProgress } = useData()
  const { isStepLoading, setIsStepLoading, currentStep } = usePreApprovalSteps()
  const savedMaritalStatus = persistedProgress?.progress[currentStep?.() ?? 3]

  //State fields ////
  const savedStatus = savedMaritalStatus?.status ?? ''
  const savedContract = savedMaritalStatus?.contractType ?? ''
  const [error, setError] = useState<string>('')

  const methods = useForm({
    defaultValues: {
      marritalStatus: maritalStatuses.find((item) => item.value === savedStatus),
      marriageType: marriageContractTypes.find((item) => item.value === savedContract)
    },
    resolver: zodResolver(maritualStatusSchema)
  })
  const watchMarried = methods.watch('marritalStatus')

  //Functions ////
  const next = async (): Promise<boolean> => {
    const status = methods.getValues().marritalStatus?.value
    const contractType = methods.getValues().marriageType?.value

    if (!status) {
      setError('Please provide your Marital Status')
      return false
    }
    const userId = user?.id
    if (!userId) {
      setError(SERVER_ERROR_MESSAGE)
      return false
    }

    if (contractType === undefined && status === 'married') {
      console.log('contractType', contractType)
      setError('Please provide your Marriage Contract Type')
      return false
    }
    console.log('status', status)
    setError('')
    setIsStepLoading?.(true)
    try {
      const input: MaritalStatusInput = {
        status,
        contractType: contractType ?? ''
      }
      await updateMaritalStatus?.(input)

      const todayDate = dayjs(`${new Date()}`).format('DD/MM/YYYY')

      if (user) {
        persistProgress?.({
          id: persistedProgress?.id as string,
          status: FinanceApplicationStatus.Pending,
          isCompleted: false,
          progress: {
            ...persistedProgress?.progress,
            currentStep: currentStep?.() && currentStep?.() + 1,
            [`${currentStep?.()}`]: {
              ...input,
              maritalStatusPageCompletedAt: todayDate
            }
          }
        })
      }
      setIsStepLoading?.(false)
      return true
    } catch (e) {
      setError(SERVER_ERROR_MESSAGE)
    }
    setIsStepLoading?.(false)
    return false
  }

  return (
    <FormProvider {...methods}>
      <Stack spacing={1} width={['400px', '500px', '100%']}>
        <SelectInput
          label="Marital status"
          data-testid="marital-status-select"
          defaultValue={
            !savedStatus ? undefined : maritalStatuses.find((item) => item.value === savedStatus)
          }
          placeholder="Please select"
          name="marritalStatus"
          options={maritalStatuses}
          isDisabled={isStepLoading}
        />
        {watchMarried?.value === 'married' && (
          <>
            <SelectInput
              label="Marriage Contract Type"
              data-testid="marriage-contract-type-select"
              defaultValue={marriageContractTypes.find(
                (item) => item.value === maritalStatus?.contractType
              )}
              placeholder="Please select"
              name="marriageType"
              options={marriageContractTypes}
              isDisabled={watchMarried?.value !== 'married' || isStepLoading}
            />
          </>
        )}

        <Text
          data-testid="error-message"
          id="marital-status-error-text"
          color="red.600"
          align="start"
          mt="2rem"
        >
          {error}
        </Text>
        <StepsNavigation
          primaryActionId="marital-status-button"
          isLoading={isStepLoading}
          secondaryAction={() => {
            // do nothing
          }}
          primaryAction={async () => {
            return next()
          }}
        />
      </Stack>
    </FormProvider>
  )
}

export default MaritalStatusScreen
