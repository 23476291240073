import { ErrorBoundary } from './layouts'
import Navigation from './navigation'
import vwTheme from './theme'
import images from './theme/images'
import { AppContent } from './components/AppContent'

function App() {
  return (
    <AppContent theme={vwTheme} baseURL="/" appName="vwfs">
      <ErrorBoundary>
        <Navigation
          logo={images.vwfsLogo}
          landingImage={images.vwLoginImg}
          baseURL="/"
          title="VWFS"
        />
      </ErrorBoundary>
    </AppContent>
  )
}

export default App
