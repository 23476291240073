import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Avatar, Button, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'

type Props = {
  startOverHandler: () => void
  onClose: () => void
  isLoading: boolean
}

const CancelFinanceApplicationModalt: React.FC<Props> = ({
  startOverHandler,
  isLoading,
  onClose
}) => {
  return (
    <Flex alignItems="center" p="24px" flexDirection="column" alignSelf="center" gap="32px">
      <Avatar icon={<InfoOutlineIcon />} />
      <Text variant="label">Are you sure you want to start over?</Text>
      <Text variant="faint" textAlign="center">
        Your car loan application is in progress and you may lose any unsaved changes.
      </Text>
      <Flex gap="12px">
        <Button data-testid="application-next-button" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-testid="cancel-application-submit-button"
          onClick={startOverHandler}
          isLoading={isLoading}
        >
          Yes, I&apos;m Sure
        </Button>
      </Flex>
    </Flex>
  )
}

export default CancelFinanceApplicationModalt
