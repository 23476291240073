/**
 * https://chakra-ui.com/docs/styled-system/theming/theme
 *
 * Please read through the Default Theme documentation for a clear understanding before extending it,
 * Chakra theming is a powerful tool 🛠
 */

import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { StepsTheme as Steps } from 'chakra-ui-steps'

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true
}

// 3. Extend the theme to include custom colors, fonts, etc
export const colors = {
  brand: {
    1500: '#F5F5F580',
    1400: '#667085',
    1300: '#D0D5DD',
    1200: '#475467',
    1100: '#D0D5DD',
    1000: '#98A2B3',
    900: '#344054',
    800: '#101828',
    700: '#333333',
    600: '#006384',
    500: '#333333',
    400: '#98A2B3',
    300: '#344054',
    200: '#101828',
    100: '#333333'
  },
  base: {
    100: '#FFFFFF',
    200: '#E7E7E7',
    300: '#D0D0D0',
    400: '#b9b9b9',
    500: '#A2A2A2',
    600: '#8B8B8B',
    700: '#737373',
    800: '#5C5C5C',
    900: '#454545',
    1000: '#F4F5F7'
  },
  primary: {
    50: '#D6E5FF',
    100: '#005AF5',
    200: '#0043B8',
    300: '#00348F',
    400: '#002D7A',
    500: '#001D50',
    600: '#00163D',
    700: '#000F28',
    800: '#000714',
    900: '#000000'
  },
  primaryDark: {
    100: '#CCF1FF',
    200: '#99E4FF',
    300: '#66D6FF',
    400: '#33C9FF',
    500: '#00B0F0',
    600: '#0096CC',
    700: '#007099',
    800: '#004B66',
    900: '#002533'
  },
  secondary: {
    100: '#CCEBFF',
    200: '#A9E3FF',
    300: '#66C2FF',
    400: '#33ADFF',
    500: '#0081D6',
    600: '#007ACC',
    700: '#005C99',
    800: '#003D66',
    900: '#001F33'
  },
  secondaryDark: {
    100: '#F1F3F4',
    200: '#E3E6E8',
    300: '#C6CDD2',
    400: '#B6BFC5',
    500: '#8D9BA5',
    600: '#5A6872',
    700: '#444E55',
    800: '#2D3439',
    900: '#171A1C'
  },
  success: {
    100: '#E3F7D4',
    200: '#C6EFA9',
    300: '#AAE67F',
    400: '#8DDE54',
    500: '#72D62A',
    600: '#5AAB21',
    700: '#448019',
    800: '#2D5610',
    900: '#172B08'
  },
  warning: {
    100: '#FFF3CC',
    200: '#FFE799',
    300: '#FFDB66',
    400: '#FFCF3300',
    500: '#FFC507',
    600: '#CC9C00',
    700: '#997500',
    800: '#664E00',
    900: '#000000'
  },
  error: {
    100: '#FFE5E5',
    200: '#FFCCCC',
    300: '#FF9999',
    400: '#FF6666',
    500: '#FF3F3F',
    600: '#CC0000',
    700: '#990000',
    800: '#660000',
    900: '#330000'
  },
  info: {
    100: '#CCDAFF',
    200: '#99B6FF',
    300: '#6691FF',
    400: '#336DFF',
    500: '#2160FF',
    600: '#003ACC',
    700: '#002B99',
    800: '#001D66',
    900: '#000E33'
  }
}

export const components = {
  Input: {
    baseStyle: {
      field: {
        borderRadius: '8px',
        height: '40px',

        bg: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        border: '1px solid #E4E7EC',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        _focus: {
          border: '1px solid #99BEFF',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCF1FF'
        },
        _hover: {
          border: '1px solid #006384',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCF1FF'
        }
      }
    },
    defaultProps: {
      variant: null // null here
    }
  },
  Textarea: {
    variants: {
      outline: {
        borderRadius: '8px',
        height: '40px',

        bg: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        border: '1px solid #E4E7EC',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        _focus: {
          border: '1px solid #99BEFF',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCF1FF'
        },
        _hover: {
          border: '1px solid #006384',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCF1FF'
        }
      }
    },
    defaultProps: {
      variants: 'outline' // null here
    }
  },
  PinInputField: {
    baseStyle: {
      field: {
        borderRadius: '8px',
        bg: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        border: '1px solid #E4E7EC',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        _focus: {
          border: '1px solid #99BEFF',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCF1FF'
        },
        _hover: {
          border: '1px solid #006384',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCF1FF'
        }
      }
    }
  },
  Steps,
  Slider: {
    baseStyle: {
      track: {
        height: '8px',
        borderRadius: '4px',
        bg: '#E4E7EC'
      },
      thumb: {
        width: '24px',
        height: '24px',
        bg: '#FFFFFF',
        boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)'
      },
      filledTrack: {
        bg: 'primary.700',
        height: '8px',
        borderRadius: '4px'
      }
    }
  },
  Button: {
    baseStyle: {
      height: '40px',
      borderRadius: '100px',
      padding: '10px 16px',
      fontWeight: '400',
      fontSize: '14px'
    },
    variants: {
      outline: {
        backgroundColor: '#FFFFFF',
        color: 'primary.500',
        borderColor: 'base.300',
        _hover: {
          color: 'primary.500',
          borderColor: 'base.500',
          backgroundColor: '#FFFFFF'
        }
      },
      link: {
        backgroundColor: 'transparent',
        color: '#667085',
        fontWeight: '400',
        fontSize: '14px',
        border: 'none',
        shadow: 'none',
        _hover: { backgroundColor: 'transparent', textDecoration: 'underline' }
      },
      callToAction: {
        color: 'primaryDark.500',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
        paddingLeft: 1,
        _hover: {
          backgroundColor: ''
        },
        border: 'none',
        shadow: 'none',
        backgroundColor: '',
        fontWeight: 700
      }
    },
    defaultProps: {
      colorScheme: 'primary'
    }
  },
  Box: {
    baseStyle: {
      bg: 'brand.300',
      color: 'brand.200'
    },
    variants: {
      // Make a variant, we'll call it `base` here and leave it empty
      base: {},
      secondary: {
        //...define other variants
      }
    },
    defaultProps: {
      // Then here we set the base variant as the default
      variant: 'base'
    }
  },
  FormLabel: {
    baseStyle: {
      fontSize: '14px',
      lineHeight: '40px',
      fontWeight: '400',
      color: 'primary.800',
      marginBottom: '0'
    }
  },
  Tooltip: {
    baseStyle: {
      bg: 'primary.500',
      color: 'base.100',
      borderRadius: '8px',
      padding: '8px 12px',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '400'
    }
  },
  Select: {
    defaultProps: {
      focusBorderColor: 'base.500'
    },
    baseStyle: {
      borderRadius: '0px',
      padding: '10px 16px',
      display: 'flex',
      border: '1px solid primary.200',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      _focus: {
        borderColor: 'blue.500',
        boxShadow: '0 0 0 1px blue.500'
      },

      field: {
        backgroundColor: 'base.100'
      },
      icon: {
        color: 'blue.400'
      }
    }
  },
  Radio: {
    variants: {
      primary: () => ({
        control: {
          _checked: {
            color: 'brand.700'
          }
        }
      })
    },
    defaultProps: {
      variant: 'primary',
      colorScheme: 'primary'
    }
  },
  Text: {
    baseStyle: {},
    variants: {
      // Make a variant, we'll call it `base` here and leave it empty
      base: {},
      secondary: {
        //...define other variants
      },
      faint: {
        color: 'secondaryDark.600',
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: '24px'
      },
      referenceValue: {
        color: 'base.900',
        fontSize: '30px',
        fontWeight: '700'
      },
      label: {
        color: '#101828',
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '30px'
      },
      title: {
        color: 'primary.500',
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '32px'
      }
    }
  },
  List: {
    baseStyle: {
      container: {
        listStylePos: 'inside', // change listStylePos to inside,
        color: '#667085',
        fontSize: '14px',
        fontWeight: '400px',
        paddingLeft: '10px'
      },
      item: {
        p: 2, // set padding to 2
        '&::marker': {
          // change color for marker
        }
      },
      icon: {
        //change color for icon
      }
    }
  },
  Heading: {
    baseStyle: {
      color: 'primary.500'
    }
  }
}

export const textStyles = {
  caption: {
    fontSize: '12px',
    lineHeight: '14px'
  },
  p: {
    fontSize: '16px',
    lineHeight: 1.2
  },
  h1: {
    fontSize: ['48px', '72px'],
    fontWeight: 900,
    lineHeight: 1
  },
  h2: {
    fontSize: ['36px', '48px'],
    lineHeight: 1
  },
  h3: {
    fontSize: ['24px', '36px'],
    fontWeight: 'semibold',
    lineHeight: 1.2
  },
  h4: {
    fontSize: ['18px', '24px'],
    lineHeight: ['20px', '24px']
  },
  h5: {
    fontSize: ['16px', '18px'],
    fontWeight: 'semibold',
    lineHeight: 1.2
  }
}

const theme = extendTheme({
  shadows: { outline: '0 0 0 2px var(--chakra-colors-cyan-500)' },
  colors,
  config,
  components,
  textStyles,
  fonts: {
    heading: 'VWHead',
    body: 'VWText'
  }
})

export default theme
