import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VWRadioGroup from '../../../components/VWRadioGroup'
import { StepsNavigation } from '../../../containers/ApplicationSection'
import { useAppContext } from '../../../context/AppProvider'
import { useAuth } from '../../../context/AuthProvider'
import { usePreApprovalSteps } from '../../../context/PreApprovalStepsProvider'
import { useData } from '../../../context/UserDataProvider'
import { FinanceApplicationStatus, useAddUserVehicleMutation } from '../../../generated'
import { RadioInput } from '../../../models/RadioInput'
import {
  ERROR_MESSAGES,
  IN_PROGRESS_FINANCE_APPLICATION_KEY,
  SERVER_ERROR_MESSAGE
} from '../../../utils'
import { DealCalculator } from '../../../utils/math/dealCalc'
import { defaultGrid } from '../../../utils/math/dealCalc/scoreGrid'
import formatFinanceAmount from '../../../utils/math/format/formatFinanceAmount'
import { FinanceDetails, ScoreGrid } from '../../../utils/types'

function PaymentStructureScreen(): React.ReactElement {
  const navigate = useNavigate()
  const { aboutVehicle } = useAppContext()
  const { appName, baseURL } = useAuth()
  const {
    preApprovalId,
    paymentMode,
    persistedProgress,
    persistProgress,
    financeRequestBody,
    setFinanceData
  } = useData()
  const { setCurrentStep, isStepLoading, setIsStepLoading } = usePreApprovalSteps()

  const vafResponse =
    persistedProgress?.progress?.financeData && JSON.parse(persistedProgress?.progress?.financeData)
  const financeDetails: FinanceDetails = vafResponse?.financeDetails

  const scoreGrid: ScoreGrid = vafResponse?.scoreGrid ?? defaultGrid

  const vehicleData = React.useMemo(() => {
    const savedVehicleData = persistedProgress?.progress?.[1]
    return {
      carHistory: savedVehicleData?.carHistory ?? aboutVehicle?.carHistory,
      year: savedVehicleData?.year ?? aboutVehicle.year,
      make: savedVehicleData?.make ?? aboutVehicle.make,
      model: savedVehicleData?.model ?? aboutVehicle.model,
      purchasePrice: savedVehicleData?.purchasePrice ?? aboutVehicle.purchasePrice,
      bank: savedVehicleData?.bank ?? aboutVehicle.bank,
      mmCode: savedVehicleData?.mmCode ?? aboutVehicle.mmCode,
      dealerCode: savedVehicleData?.dealerCode ?? aboutVehicle.dealerCode,
      vehicleType: savedVehicleData?.vehicleType ?? aboutVehicle.vehicleType
    }
  }, [persistedProgress, aboutVehicle])

  /* The price of the car. */
  const price = parseFloat(
    vehicleData.purchasePrice ??
      window.sessionStorage.getItem('purchasePrice') ??
      `${vafResponse?.price}`
  )

  const [depositAmount, setDepositAmount] = React.useState<number>(financeDetails?.deposit)

  const [paymentTerm, setPaymentTerm] = React.useState<number>(financeDetails?.term)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [interestType, setInterestType] = React.useState<typeOfInterest>('Linked')

  const retailPrice = price

  const [carModel] = React.useState<string>(`${vehicleData.make} ${vehicleData.model}`)

  const [monthlyRepaymentAmount, setMonthlyRepaymentAmount] = useState<number>(
    financeDetails?.monthlyInstallment
  )

  const [balloonPerc, setballoonPerc] = useState<number>(financeDetails?.balloonPerc ?? 0)
  const [balloonPaymentAmount, setBalloonPaymentAmount] = useState<number>(0)

  const calculator = new DealCalculator(
    scoreGrid,
    price,
    retailPrice,
    financeDetails?.initiationFee,
    price,
    new Date(financeDetails?.firstInstallmentDate),
    new Date(financeDetails?.loanExpiryDate),
    financeDetails?.monthlyInstallment,
    financeDetails?.term
  )

  /* A subscription to the event that is emitted when the monthly repayment amount changes. */
  calculator.installmentEvent.subscribe((value) => {
    setMonthlyRepaymentAmount(() => value)
  })

  /* A subscription to the event that is emitted when the deposit amount changes. */
  calculator.depositEvent.subscribe((value) => {
    setDepositAmount(() => value)
  })

  /* Subscribing to the event that is emitted when the balloon payment amount changes. */
  calculator.balloonEvent.subscribe((value) => {
    setBalloonPaymentAmount(() => (value / maxBallonPayment) * 100)
  })

  const vehiclePayLoad = () => {
    return {
      preApprovalId,
      newUsed: (vehicleData?.carHistory ?? 'New')[0],
      financeDetails: {
        price,
        term: paymentTerm,
        depositAmt: depositAmount,
        interestRate: calculator.currentIntererstRate,
        rateType: interestType[0],
        balloonPercent: balloonPerc
      }
    }
  }
  const vehicleInPut = () => {
    return {
      make: vehicleData.make as string,
      model: vehicleData.model as string,
      price,
      deposit: depositAmount,
      monthlyInstallment: monthlyRepaymentAmount,
      balloonPayment: balloonPaymentAmount,
      paymentTerm: paymentTerm,
      interestType,
      financedAmount: financeDetails?.amountToBeFinanced,
      vafStatus: '',
      mmCode: aboutVehicle?.mmCode ?? persistedProgress?.progress?.mmCode,
      year: parseInt(vehicleData.year as string, 10),
      feesPaymentMethod: paymentMode,
      payLoad: JSON.stringify({
        ...vehiclePayLoad()
      }),
      appName: appName as string
    }
  }

  const [saveVehicleDataMutation] = useAddUserVehicleMutation({
    onCompleted: async (response) => {
      if (
        response?.addUserVehicle?.data &&
        JSON.parse(response?.addUserVehicle?.data as string).financeIdentifiers.referenceNumber
      ) {
        const todayDate = dayjs(`${new Date()}`).format('DD/MM/YYYY')
        setFinanceData?.(response?.addUserVehicle?.data ?? '')

        await persistProgress?.({
          id: persistedProgress?.id as string,
          status: FinanceApplicationStatus.Completed,
          isCompleted: true,
          progress: {
            ...persistedProgress?.progress,
            5: {
              depositAmount,
              monthlyRepayment: monthlyRepaymentAmount,
              balloonAmount: balloonPaymentAmount,
              paymentTerm: paymentTerm,
              interestStructure: interestType,
              paymentStructurePagecompletedAt: todayDate,
              referenceNumber: JSON.parse((response?.addUserVehicle?.data as string) ?? '{}')
                ?.financeIdentifiers?.referenceNumber
            }
          }
        })
      }

      if (['A', 'R'].includes(response?.addUserVehicle?.status as string)) {
        sessionStorage.removeItem(IN_PROGRESS_FINANCE_APPLICATION_KEY)
        navigate(`${baseURL}auth/approved-deal`)
      }

      if (response?.addUserVehicle?.errorMessage) {
        setErrorMessage(
          ERROR_MESSAGES[response?.addUserVehicle.errorMessage as string] ??
            response?.addUserVehicle?.errorMessage
        )
      }

      setIsStepLoading?.(false)
    },
    onError: () => {
      setIsStepLoading?.(false)
      setErrorMessage(SERVER_ERROR_MESSAGE)
    }
  })

  async function submitApplication() {
    setErrorMessage('')
    setIsStepLoading?.(true)
    const financeRequestJSON = JSON.stringify({
      ...JSON.parse(financeRequestBody ?? '{}'),
      financeDetails: JSON.parse(vehicleInPut().payLoad as string)?.financeDetails
    })

    //refactoring
    await saveVehicleDataMutation({
      variables: { input: { ...vehicleInPut(), financeRequestBody: financeRequestJSON } }
    })
  }

  type typeOfPaymentTerm = '60' | '72'
  const paymentTermValues: RadioInput<typeOfPaymentTerm>[] = [
    { value: '60', message: '60' },
    { value: '72', message: '72' }
  ]
  type typeOfInterest = 'Linked' | 'Fixed'
  const interestValues: RadioInput<typeOfInterest>[] = [
    { value: 'Linked', message: 'Linked' },
    { value: 'Fixed', message: 'Fixed' }
  ]

  /**
   * When the monthly repayment amount changes, set the monthly repayment amount to the value passed in,
   * and set the deposit amount to the finance amount minus the monthly repayment amount multiplied by
   * the payment term.
   * @param {number} value - the value of the input field
   */
  function handleMonthlyRepaymentChange(value: number) {
    calculator.handleInstallmentChanges(value, checkBalloon)
    setMonthlyRepaymentAmount(() => value)
  }

  /**
   * It sets the deposit amount and the monthly repayment amount.
   * @param {number} value - the value of the slider
   */
  function handleDepositChange(value: number) {
    calculator.handleDepositChanged(value)
    setDepositAmount(() => value)
  }

  /**
   * It takes a number as an argument, and sets the balloon payment amount to that number
   * @param {number} value - The value of the input field
   */
  function handleBalloonPaymentChange(value: number) {
    setballoonPerc(() => value)
    calculator.handleBalloonChanged(maxBallonPayment * (value / 100))
    setBalloonPaymentAmount(() => maxBallonPayment * (value / 100))
  }

  /**
   * It takes a number as an argument, sets the paymentTerm state to that number, and then calls the
   * handleTermChanged function on the calculator object, passing in the number
   * @param {number} value - The value of the slider
   */
  function handlePaymentTermChange(value: number) {
    setPaymentTerm(value)
    calculator.handleTermChanged(value)
  }

  /**
   * `handleInterestTypeChange` is a function that takes a value of type `typeOfInterest` and sets the
   * interest type to the value passed in
   * @param {typeOfInterest} value - typeOfInterest
   */
  function handleInterestTypeChange(value: typeOfInterest) {
    calculator.handleTermTypeChange(value[0].toUpperCase() as 'L' | 'F')
    setInterestType(value)
  }

  const balloonPaymentInfo = "Balloon payment is the final payment you'll make on your car loan."

  const checkBalloon = true

  const maxBallonPayment =
    calculator.getMaxBalloonAmount(depositAmount, paymentTerm) > 0
      ? calculator.getMaxBalloonAmount(depositAmount, paymentTerm)
      : 0
  return (
    <>
      <Flex align="center" flexDirection="column" w="100%">
        <Box w={[460, 550, '100%']}>
          <Stack spacing={8} mx="auto">
            <Flex alignItems="center" gap="20px">
              <Text fontWeight={400} align={'center'}>
                Amount to finance:
              </Text>
              <Text
                color={'brand.700'}
                fontSize="30"
                fontWeight="700"
                align={'center'}
              >{`R ${formatFinanceAmount(financeDetails?.amountToBeFinanced.toString())}`}</Text>
            </Flex>
            <Flex alignItems="center" gap="20px">
              <Text fontWeight="700">{carModel}</Text>

              <Button
                data-testid="change-vehicle-button"
                variant="link"
                bg="rgba(0, 0, 0, 0)"
                border="none"
                shadow="none"
                _hover={{ bg: 'rgba(0, 0, 0, 0)', textDecoration: 'underline' }}
                onClick={() => {
                  setCurrentStep?.(1)
                }}
              >
                Change Vehicle
              </Button>
            </Flex>

            <FormLabel lineHeight="0">Payment term (months)</FormLabel>
            <VWRadioGroup
              values={paymentTermValues}
              setValue={(value) => handlePaymentTermChange(parseInt(value))}
              currentValue={paymentTerm?.toString()}
              width="15rem"
              isDisabled={isStepLoading}
            />
            <FormLabel lineHeight="0">Interest </FormLabel>
            <VWRadioGroup
              values={interestValues}
              setValue={handleInterestTypeChange}
              currentValue={interestType}
              width="15rem"
              isDisabled={isStepLoading}
            />

            {/* A slider that allows the user to change the monthly repayment amount. */}
            <Flex justifyContent="space-between" mb={8}>
              <FormLabel lineHeight="0">Monthly repayment</FormLabel>
            </Flex>

            <Slider
              data-testid="monthly-repayment-slider"
              aria-label="monthly-repayment"
              value={monthlyRepaymentAmount}
              onChange={handleMonthlyRepaymentChange}
              max={calculator.getMonthlyPaymentMaximum(paymentTerm, 0)}
              min={calculator.getMonthlyPaymentMinimum(paymentTerm, 0, checkBalloon)}
              isDisabled={isStepLoading}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <Tooltip
                hasArrow
                isOpen={true}
                placement="top"
                label={`R ${formatFinanceAmount(monthlyRepaymentAmount?.toString())}`}
              >
                <SliderThumb />
              </Tooltip>
            </Slider>

            {
              // Deposit Slider
            }
            {/* A slider that allows the user to select a deposit amount. */}
            <Flex justifyContent="space-between" mb={8}>
              <FormLabel lineHeight="0">Deposit amount</FormLabel>
            </Flex>

            <Slider
              data-testid="monthly-deposit-slider"
              aria-label="deposit"
              value={depositAmount}
              onChange={handleDepositChange}
              max={calculator.getMaxDepositAmount(financeDetails?.amountToBeFinanced)}
              min={calculator.getMinDeposit(price)}
              isDisabled={isStepLoading}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <Tooltip
                hasArrow
                placement="top"
                isOpen={true}
                label={`R ${formatFinanceAmount(depositAmount?.toString())}`}
              >
                <SliderThumb />
              </Tooltip>
            </Slider>

            {
              // Balloon Slider
            }

            <Flex justifyContent="space-between" mb={8} opacity={!checkBalloon ? 0.25 : 1}>
              <FormLabel lineHeight="0" alignContent="center">
                {`Balloon payment max: R ${formatFinanceAmount(maxBallonPayment.toString())} `}
                <Tooltip label={balloonPaymentInfo}>
                  <QuestionOutlineIcon />
                </Tooltip>
              </FormLabel>
            </Flex>
            <Slider
              data-testid="monthly-ballon-slider"
              aria-label="top"
              value={balloonPerc}
              onChange={handleBalloonPaymentChange}
              min={0}
              max={100}
              isDisabled={!checkBalloon || isStepLoading}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <Tooltip
                hasArrow
                placement="top"
                isOpen={true}
                label={`R ${formatFinanceAmount(
                  (maxBallonPayment * (balloonPerc / 100)).toString()
                )}`}
              >
                <SliderThumb />
              </Tooltip>
            </Slider>
            <Text data-testid="error-message" color={'red'}>
              {errorMessage}
            </Text>
            <StepsNavigation
              isLoading={isStepLoading}
              primaryActionId="submit-button-payment-structure"
              secondaryAction={() => {
                // do nothing
              }}
              primaryAction={async () => {
                await submitApplication()
                return false
              }}
            />
          </Stack>
        </Box>
      </Flex>
    </>
  )
}
export default PaymentStructureScreen
