import z from 'zod'
import { PHONE_NUMBER_REGEX } from './auth'

export const employeeDetailDefaultValues = {
  companyName: '',
  industryCategory: '',
  industry: '',
  timeOfEmploymentYears: '0',
  timeOfEmploymentsMonths: '0',
  phoneNumber: '',
  occupation: ''
}

export const employeeDetailSchema = z.object({
  companyName: z.string().min(1, 'Please enter the name of the company you work for'),
  industryCategory: z.object({
    label: z.string(),
    value: z.string({ required_error: 'Industry category is required' })
  }),
  timeOfEmploymentYears: z.string(),
  timeOfEmploymentsMonths: z.string(),
  phoneNumber: z
    .string()
    .max(10, 'Please ensure you input a 10 digit mobile number')
    .min(10, 'Please ensure you input a 10 digit mobile number')
    .regex(PHONE_NUMBER_REGEX, 'Please enter a valid phone number (without + )'), //Limits input to numbers only
  occupation: z.object({
    label: z.string(),
    value: z.string({ required_error: 'Occupation is required' })
  })
})
