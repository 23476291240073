import { Button, useToast } from '@chakra-ui/react'
import * as React from 'react'
import { FiSave } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../context/AuthProvider'
import { usePreApprovalSteps } from '../../../context/PreApprovalStepsProvider'
import { useData } from '../../../context/UserDataProvider'

export default function SaveProgressButton(): React.ReactElement {
  const toast = useToast()
  const navigate = useNavigate()
  const data = useData()
  const { baseURL } = useAuth()
  const { isStepLoading } = usePreApprovalSteps()
  const onSaveProgress = () => {
    // save the application progress including state, with data that's only saved in memory to be used later
    data.saveProgress?.()
    toast({
      title: 'Progress Saved',
      description: "We have saved your progress, you'll continue from where you left",
      status: 'success',
      duration: 9000,
      isClosable: true,
      colorScheme: 'primaryDark'
    })
    navigate(`${baseURL}auth/dashboard`)
  }
  return (
    <Button
      variant="outline"
      isDisabled={isStepLoading}
      onClick={onSaveProgress}
      leftIcon={<FiSave />}
      data-testid="save-progress-main-button"
    >
      Save and Close
    </Button>
  )
}
