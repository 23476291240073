import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Avatar, Button, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'

type Props = {
  removeHandler: () => void
  onClose: () => void
  productName: string
}

const RemoveVAPsModal: React.FC<Props> = ({ removeHandler, onClose, productName }) => {
  return (
    <Flex alignItems="center" p="24px" flexDirection="column" alignSelf="center" gap="32px">
      <Avatar icon={<InfoOutlineIcon />} />
      <Text variant="faint" textAlign="center">
        Are you sure you want to remove {productName}.
      </Text>
      <Flex gap="12px">
        <Button data-testid="application-next-button" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button data-testid="cancel-application-submit-button" onClick={removeHandler}>
          Yes, I&apos;m Sure
        </Button>
      </Flex>
    </Flex>
  )
}

export default RemoveVAPsModal
