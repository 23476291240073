import { CalendarIcon, EmailIcon, PhoneIcon, TimeIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import FooterOptionsCard from '../FooterOptionsCard/index'

type Props = {
  email: string
  phone: string
  fax: string
  openHours: string[]
  additionalText: string
}

export default function Footer({
  email,
  phone,
  fax,
  openHours,
  additionalText
}: Props): React.ReactElement {
  const optionElements = [
    <FooterOptionsCard key="phone" label="Telephone" value={phone} icon={<PhoneIcon />} />,
    <FooterOptionsCard key="email" label="E-mail" value={email} icon={<EmailIcon />} />,
    <FooterOptionsCard key="fax" label="Fax" value={fax} icon={<CalendarIcon />} />,
    <FooterOptionsCard key="hour" label="Hours" value={openHours} icon={<TimeIcon />} />
  ]
  return (
    <Box as="footer" paddingX={4} paddingY={2} textAlign="center">
      <Divider />
      <Flex
        pt="20px"
        justifyContent="space-between"
        textAlign="start"
        gap="20px"
        flexDirection={['column', 'column', 'row']}
      >
        <Flex flexDirection="column" gap="5px">
          <Text variant="label" pb="10px">
            For any finance related queries or questions,please contact
          </Text>
          {optionElements}
        </Flex>
        <Flex flexDirection="column" gap="5px">
          <Text variant="label" pb="10px">
            Please use the following details for any queries you might have regarding the use of
            this website
          </Text>
          {optionElements.map((item, i) => (i != 2 ? item : null))}
        </Flex>
      </Flex>
      <Text pt="10px" fontWeight="400" textAlign="start" fontSize="12px">
        {additionalText}
      </Text>
    </Box>
  )
}
