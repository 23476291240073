import * as React from 'react'
import { CreateUserForm } from '../../components'
import HomePageLayout from '../HomePageLayout'

type Props = {
  landingImage: string
  logo: string
}

function SignupScreen({ landingImage, logo }: Props): React.ReactElement {
  return (
    <HomePageLayout
      landingImage={landingImage}
      logo={logo}
      title="Sign Up"
      subTitle="Create a secure profile on QuickApp."
      contentPosition="flex-start"
    >
      <CreateUserForm />
    </HomePageLayout>
  )
}

export default SignupScreen
