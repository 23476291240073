import { CloseIcon } from '@chakra-ui/icons'
import { Avatar, Button, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useSendDeclinedPreApprovalEmailMutation } from '../../../generated'
import { RadioInput } from '../../../models/RadioInput'
import VWRadioGroup from '../../VWRadioGroup'

type DeclinedPreApprovalModalProps = {
  onGoToDashboard?: () => void
  onOpenSettlementDrawer: () => void
  onClose: () => void
}
export const DeclinedPreApprovalModal: React.FC<DeclinedPreApprovalModalProps> = ({
  onGoToDashboard,
  onOpenSettlementDrawer,
  onClose
}) => {
  const toast = useToast()
  const [sendDeclinedPreApprovalEmail] = useSendDeclinedPreApprovalEmailMutation()
  const { isOpen: loading, onOpen: startLoading } = useDisclosure()

  const [contactMethod, setContactMethod] = useState<string>('email')

  const contactMethodValues: RadioInput<string>[] = [
    { value: 'email', message: 'Email' },
    { value: 'phone', message: 'Phone call' },
    { value: 'noContact', message: 'Do not contact me' },
    { value: 'checkDebtSettlement', message: 'Check debt settlement' }
  ]

  const handleContactMethod = (value: string) => {
    setContactMethod(value)
  }

  const saveAndExitHandler = async () => {
    if (contactMethod === 'email' || contactMethod === 'phone') {
      startLoading()
      sendDeclinedPreApprovalEmail({
        variables: {
          input: { contactPreference: contactMethod }
        }
      }).then(() => {
        toast({
          title: 'Contact alert!',
          description: 'We have received your contact details. You will hear from us soon!',
          status: 'success',
          duration: 9000,
          isClosable: true,
          colorScheme: 'primaryDark'
        })
        onGoToDashboard?.()
      })
    } else if (contactMethod === 'checkDebtSettlement') {
      onClose()
      onOpenSettlementDrawer()
    } else {
      onGoToDashboard?.()
    }
  }

  return (
    <Flex
      data-testid="approve-modal"
      alignItems="center"
      p="24px"
      flexDirection="column"
      alignSelf="center"
      gap="32px"
    >
      <Avatar icon={<CloseIcon color="red" />} p="2px" backgroundColor="primary.50" />
      <Text data-testid="modal-title" variant="title" fontSize="16px" textAlign="center">
        Unfortunately we cannot pre-approve you at this time
      </Text>
      <Flex flexDirection="column" alignItems="center" gap="16px" width="100%">
        <Text variant="faint" textAlign="center">
          Should you wish to be contacted regarding this vehicle finance application, please select
          your preferred method of contact and VWFS SA will contact you.
        </Text>
        <VWRadioGroup
          currentValue={contactMethod}
          setValue={(value) => {
            handleContactMethod(value)
          }}
          values={contactMethodValues}
          direction="column"
        />
      </Flex>
      <Flex gap="12px">
        <Button
          isLoading={loading}
          data-testid="dashboard-button"
          onClick={saveAndExitHandler}
          variant="outline"
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  )
}

export default DeclinedPreApprovalModal
