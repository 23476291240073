import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import React from 'react'
import { FiRefreshCcw } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import { usePreApprovalSteps } from '../../context/PreApprovalStepsProvider'
import { useData } from '../../context/UserDataProvider'
import { useCancelFinaceApplicationMutation } from '../../generated'
import SaveProgressButton from '../Actions/SaveProgress'
import VWModal from '../Modal'
import CancelFinanceApplicationModal from '../Modal/CancelFinanceApplicationModal'

type StepsHeaderProps = {
  title?: string
  subtitle?: string
  backLink?: string
  backLinkText?: string
  subfield?: string
  subFieldId?: string
}
export default function StepsHeader({
  title = 'Individual finance application',
  subtitle = "In order to estimate your affordability, we'll need the following:",
  backLinkText = 'Back to dashboard',
  subfield,
  subFieldId
}: StepsHeaderProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentStep, currentStepInfo } = usePreApprovalSteps()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { baseURL } = useAuth()
  const { persistedProgress, setPersistedProgress } = useData()
  const toast = useToast()

  const [cancelFinaceApplication, { loading: cancelling }] = useCancelFinaceApplicationMutation({
    onCompleted: () => {
      setPersistedProgress?.(undefined)
    }
  })

  const handleCancelApplication = async () => {
    try {
      if (!persistedProgress?.id) {
        return
      }
      await cancelFinaceApplication({
        variables: {
          input: {
            financeApplicationId: persistedProgress?.id as string,
            step: currentStepInfo?.().label as string
          }
        }
      })
      toast({
        title: 'Finance Application Restared',
        description: 'Your Finance application was restarted successlly',
        status: 'success',
        duration: 9000,
        isClosable: true,
        colorScheme: 'primaryDark'
      })
      navigate(`${baseURL}auth/application?step=0`)
      onClose()
    } catch (error) {
      console.log({ error })
    }
  }

  const showConfirmationModal = (): void => {
    navigate(`${baseURL}auth/dashboard`)
  }
  return (
    <Flex flexDirection="column" alignItems="flex-start" gap="2rem" width="100%">
      <VWModal
        showFooter={false}
        onClose={onClose}
        size="full"
        isOpen={isOpen}
        modalBody={
          <CancelFinanceApplicationModal
            startOverHandler={handleCancelApplication}
            isLoading={cancelling}
            onClose={onClose}
          />
        }
      >
        <Box />
      </VWModal>
      <Button
        data-testid="dashboard-link"
        color="primaryDark.500"
        variant="link"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        height="20px"
        gap="8px"
        mt="1.5rem"
        onClick={showConfirmationModal}
      >
        <ArrowBackIcon />
        {backLinkText}
      </Button>
      <Flex width="100%" flexDirection={['column', 'column', 'row', 'row', 'row']} pl="16px">
        <Stack>
          <Text variant="title">{title}</Text>
          {subfield && (
            <Text id={subFieldId} variant="referenceValue">
              {subfield}
            </Text>
          )}
          <Text variant="faint">{subtitle}</Text>
        </Stack>

        <Spacer />

        {currentStep?.() !== 0 && !pathname.includes('approved-deal') && (
          <Stack direction="row" alignSelf="end">
            <Button
              data-testid="application-next-button"
              colorScheme="red"
              variant="outline"
              borderColor="red"
              color="red"
              onClick={onOpen}
              leftIcon={<FiRefreshCcw />}
            >
              Start over
            </Button>
            <SaveProgressButton />
          </Stack>
        )}
      </Flex>
      <Divider />
    </Flex>
  )
}
