/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { Component, ErrorInfo, ReactNode } from 'react'
import { awsRum } from '../../main'
import images from '../../theme/images'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    awsRum.recordError(error)
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Flex height="100vh" justify="center">
          <Stack
            flexDirection="column"
            padding={4}
            alignItems="cetner"
            justify="center"
            pt="3rem"
            spacing={8}
            mx="auto"
            w={[300, 500, 750]}
            paddingX={6}
          >
            <Flex justify="center">
              <Image data-testid="error-icon" src={images.errorIcon} />
            </Flex>
            <Flex justify="center" flexDirection="column">
              <Heading textAlign="center" fontSize={16} mb={2}>
                Something went wrong...
              </Heading>
              <Text fontSize={14} fontWeight={300} textAlign="center">
                We had some trouble loading this page. Please refresh the page to try again or get
                in touch if the problem sticks around!
              </Text>
            </Flex>

            <Flex justify="center">
              <Button
                loadingText="Submitting"
                size="sm"
                colorScheme="primary"
                color="white"
                _hover={{
                  backgroundColor: 'brand.400'
                }}
                onClick={() => {
                  window.sessionStorage.removeItem('vw_User')
                  window.sessionStorage.removeItem('financeApplication')
                  window.location.reload()
                }}
                id="logout-button-error"
                w={[300, 500, 200]}
              >
                Refresh app
              </Button>
            </Flex>
          </Stack>
        </Flex>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
