import { ChevronRightIcon } from '@chakra-ui/icons'
import { Button, useDisclosure } from '@chakra-ui/react'
import * as React from 'react'
import { DocumentsUploadModal } from '../'

const UploadDocumentsButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        data-testid="dashboard-link"
        rightIcon={<ChevronRightIcon />}
        variant="outline"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        onClick={onOpen}
      >
        Upload Documents
      </Button>
      <DocumentsUploadModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default UploadDocumentsButton
