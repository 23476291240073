import { Route, Routes } from 'react-router-dom'
import { Navbar } from '../components'
import {
  AffordabilityCalculatorScreen,
  ApplicationSection,
  ApprovalScreen,
  DashboardScreen,
  EmployeeDetailsScreen,
  LoginScreen,
  NotFoundScreen,
  OTPScreen,
  PaymentStructureScreen,
  SignupScreen
} from '../containers'
import AddressScreen from '../containers/AddressScreen'
import MaritalStatusScreen from '../containers/MaritalStatusScreen'
import TellUsAboutYourVehicleScreen from '../containers/TellUsAboutScreen'
import { RequireAuth } from '../context/AuthProvider'
import SessionProvider from '../context/SessionProvider'
import { PageWrap } from '../layouts'

/**
 * Trying to keep navigation as simple as possible. Easier to comprehend as a new developer.
 * This will still be expanded on greatly. Perhaps lazy imports for Screens for performance 🚀
 * @returns
 */

type Props = {
  landingImage: string
  logo: string
  baseURL: string
  title: string
}

function Navigation({ landingImage, logo, baseURL, title }: Props) {
  return (
    <Routes>
      {/*
       * Unauthenticated pages
       */}
      <Route>
        <Route element={<PageWrap title={title} />}>
          <Route
            path={`${baseURL}`}
            element={<SignupScreen landingImage={landingImage} logo={logo} />}
          />
          <Route
            path={`${baseURL}login`}
            element={<LoginScreen landingImage={landingImage} logo={logo} />}
          />
          <Route path={`${baseURL}otp`} element={<OTPScreen landingImage={landingImage} />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Route>
      </Route>
      {/*
       * Authenticated pages
       *
       * All paths will be prepended with /auth and are protected by the AuthProvider
       * When a Guest tries to navigate to any of these, they will be taken to /
       */}
      <Route
        element={
          <RequireAuth baseURL={`${baseURL}`}>
            <Navbar logo={logo} />
          </RequireAuth>
        }
      >
        <Route
          path={`${baseURL}auth`}
          element={
            <SessionProvider>
              <PageWrap title={title} />
            </SessionProvider>
          }
        >
          <Route path="calculator" element={<AffordabilityCalculatorScreen />} />
          <Route path="dashboard" element={<DashboardScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
          {/* fee-payment-mode screen does  as the tell-us-about-screen */}
          <Route path="tell-us-about-your-vehicle" element={<TellUsAboutYourVehicleScreen />} />
          <Route path="address" element={<AddressScreen />} />
          <Route path="payment-structure" element={<PaymentStructureScreen />} />
          <Route path="marital-status" element={<MaritalStatusScreen />} />
          <Route path="employement-details" element={<EmployeeDetailsScreen />} />
          <Route path="approved-deal" element={<ApprovalScreen />} />
          <Route path="application" element={<ApplicationSection />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default Navigation
