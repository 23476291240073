import { Button, HStack, Spinner, Stack, Text } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../context/AuthProvider'
import { LoginInput } from '../../../generated'
import { loginDefaultValues, loginSchema } from '../../../utils'
import { TextInput } from '../../FormElements'

export const SAIdRegx = new RegExp(
  /(([0-9]{2})(0|1)([0-9])([0-3])([0-9]))([ ]?)(([0-9]{4})([ ]?)([0-1][8]([ ]?)[0-9]))/gm
)

function LoginForm() {
  const [reCaptchaToken, setReCaptchaToken] = React.useState('')
  const { login, baseURL } = useAuth()
  const [isAuthenticating, setIsAuthenticating] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')
  const navigate = useNavigate()

  const methods = useForm({
    defaultValues: loginDefaultValues,
    resolver: zodResolver(loginSchema)
  })

  const handleRecaptcha = (value: any) => {
    setReCaptchaToken(value)
  }

  const onSubmit = async () => {
    setError('')
    methods.clearErrors()
    if (!methods.getValues().idNumber) {
      //This step is done manually because if the field is left blank,
      //no error message can be shown, even if set manually
      setError('Please enter a valid ID or passport')
      return
    }

    setIsAuthenticating(true)
    const value = methods.getValues().idNumber
    const loginInput: LoginInput = {}
    if (value.match(SAIdRegx)) {
      loginInput.idNumber = value
    } else {
      loginInput.passportNumber = value
    }
    const res = await login?.(loginInput)

    if (res?.error) {
      setError(res.error)
      setIsAuthenticating(false)
    }

    setIsAuthenticating(false)
  }

  return (
    <FormProvider {...methods}>
      <Stack spacing={4} color="brand.200">
        <TextInput
          data-testid="login-id-text-input"
          label="ID or Passport Number"
          name="idNumber"
          type="text"
          color="brand.800"
        />
        <Text data-testid="login-id-text-input-error-test" fontSize={'sm'} color={'red.300'}>
          {error}
        </Text>
        <Stack spacing={6}>
          {/* @ts-expect-error Server Component */}
          <ReCAPTCHA sitekey={process.env.VITE_APP_RECAPTCHA_KEY} onChange={handleRecaptcha} />
          <Button
            data-testid="login-submit-button"
            loadingText=""
            size="lg"
            onClick={() => onSubmit()}
            id="login-button"
            hidden={!reCaptchaToken}
          >
            {!isAuthenticating ? 'Get OTP' : <Spinner />}
          </Button>
          <HStack justify={'center'}>
            <Text fontSize={'sm'} color={'brand.1000'} justifyContent="center" align={'center'}>
              Don&apos;t have an account?
            </Text>

            <Button
              data-testid="login-sign-up-link"
              variant={'callToAction'}
              onClick={() => navigate(`${baseURL}`)}
            >
              Sign Up
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default LoginForm
