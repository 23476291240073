import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet, useLocation } from 'react-router-dom'
import { HelpAndSupport, Footer } from '../../components'
import { awsRum } from '../../main'
import { audiFooterOptionsvalues, footerOptionsvalues } from '../../utils/constants'

type PageWrapProps = FlexProps & {
  title: string
}

function PageWrap({ children, title, ...rest }: PageWrapProps): JSX.Element {
  const location = useLocation()

  React.useEffect(() => {
    awsRum.recordPageView(location.pathname)
  }, [location])

  const footerOptions = title === 'AUDIFS' ? audiFooterOptionsvalues : footerOptionsvalues

  return (
    <>
      <Helmet title={title} />
      <Flex
        bg="base.1000"
        as="main"
        boxShadow={`0 -1px 0 0 rgba(204, 204, 204, ${useColorModeValue('.4', '.2')})`}
        {...rest}
      >
        {children}
        <Outlet />
        <HelpAndSupport />
        <Footer {...footerOptions} />
      </Flex>
    </>
  )
}

export default PageWrap

PageWrap.defaultProps = {
  flex: 1,
  flexDirection: 'column',
  marginX: 'auto',
  justifyContent: 'flex-start',
  minHeight: 'calc(100vh - 4rem)' // minus height of GuestNavbar or Navbar,
}
