/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, Subject } from 'rxjs'
// tslint:disable
import BigNumber from 'bignumber.js'
import { Decimal } from 'decimal.js'

import moment from 'moment'
import { Deposit } from './deposit'
import { PVFactors } from './pvFactors'
import { ScoreGrid } from './scoreGrid'
import { SliderValues } from './sliderValues'

export type InterestRateType = 'F' | 'L'
export class DealCalculator {
  public depositEvent: Subject<number> = new Subject()
  public $depositEvent: Observable<number> = this.depositEvent.asObservable()

  public balloonEvent: Subject<number> = new Subject()
  public $balloonEvent: Observable<number> = this.balloonEvent.asObservable()

  public installmentEvent: Subject<number> = new Subject()
  public $installmentEvent: Observable<number> = this.installmentEvent.asObservable()

  public terms: { value: number; label: string }[] = []
  public maxMonthly = 0
  public minMonthly = 0
  public minDeposit = 0
  public maxDeposit = 0
  public minBalloon = 0
  public maxBalloon = 0
  public defaultFixedRate = 12.53
  public defaultLinkedRate = 11.99
  public currentBalloon = 0
  public currentIntererstRateType: InterestRateType = 'L'
  public currentIntererstRate = 0
  public principleDebtDisplay = 0

  private maxTerm = 0
  private scoreGrid: ScoreGrid
  private log: string[] = []
  private currentTerm = 0
  private currentDeposit = 0
  private allowLog = false

  /**
   * It takes in a bunch of parameters, and then sets the scoreGrid, terms, currentTerm,
   * principleDebtDisplay, and setRanges
   * @param {any} grid - any - this is the grid that is returned from the API.
   * @param {number} price - The price of the vehicle
   * @param {number} retailPrice - The price of the vehicle
   * @param {number} initiationFees - The initiation fees charged by the bank
   * @param {number} principleDebt - The amount of money the customer is borrowing.
   * @param {Date} firstInstallmentDate - The date of the first installment
   * @param {Date} loanExpiryDate - The date the loan expires
   * @param {number} monthlyInstallment - The monthly installment amount
   * @param {number} term - number - The term of the loan in months
   */
  constructor(
    grid: any,
    public price: number,
    public retailPrice: number,
    public initiationFees: number,
    public principleDebt: number,
    public firstInstallmentDate: Date,
    public loanExpiryDate: Date,
    public monthlyInstallment: number,
    public term: number
  ) {
    this.scoreGrid = DealCalculator.stringToNumberJSON(grid)
    if (this.scoreGrid?.term)
      this.terms = this.scoreGrid?.term.map((h) => {
        return {
          value: h.months as number,
          label: h?.months?.toString() ?? ''
        }
      })
    this.currentTerm = term
    this.principleDebtDisplay = this.principleDebt
    this.setRanges()
  }
  /**
   * This function returns the currentTerm property of the class.
   * @returns The current term of the server
   */

  getCurrentTerm(): number {
    return this.currentTerm
  }

  /**
   * It subscribes to the depositEvent and calls the handleDepositChanged function when the depositEvent
   * is triggered.
   */
  public subScriptions(): void {
    this.$depositEvent.subscribe((h) => {
      this.handleDepositChanged(h)
    })
  }

  /**
   * It takes the current installment value, the current term, the current deposit, the current baloon
   * percentage and the current interest rate type and returns the deposit slider value
   * @param {number} value - number - the value of the slider
   */
  public handleInstallmentChanges(value: number, checkBalloon: boolean): void {
    const result: SliderValues = this.findMontlyAmountSliderValues(
      value,
      this.currentTerm,
      this.currentDeposit,
      this.getBaloonPercentage(),
      this.currentIntererstRateType
    )

    if (result) {
      const nacd = this.getNACD(this.getMinInterestRate('L'))

      const tmpMaxDeposit = this.getMaxDepositAmount(this.principleDebt)
      let tmpMaxBalloon = 0

      if (checkBalloon) {
        tmpMaxBalloon = this.getMaxBalloonPercentage(tmpMaxDeposit, this.currentTerm)
      }
      const d1 = this.getDeposit(
        value,
        this.getBaloonPercentage(),
        this.currentTerm,
        this.getTotalPVFactors(
          tmpMaxBalloon,
          tmpMaxDeposit,
          this.currentTerm,
          this.price,
          this.retailPrice,
          0,
          nacd
        ),
        this.price,
        this.initiationFees
      )
      this.depositEvent.next(d1 > 0 ? d1 : 0)
    }
    this.principleDebtDisplay = this.getPrinicipleDept(result.deposit as number, 0)

    this.updateInterrestRate(this.currentIntererstRateType)
  }
  /**
   * It handles the deposit amount change
   * @param {number} deposit - number - the deposit amount
   */
  public handleDepositChanged(deposit: number): void {
    this.principleDebtDisplay = this.getPrinicipleDept(deposit, 0)
    this.currentDeposit = deposit
    this.minDeposit = this.getMinDeposit(this.price)
    if (deposit > this.minDeposit && deposit < this.maxDeposit) {
      this.maxBalloon = this.getMaxBalloonAmount(this.currentDeposit, this.currentTerm)

      this.setMaxBalloonFromDeposit(this.maxBalloon)

      this.setInstallment()

      this.updateInterrestRate(this.currentIntererstRateType)
    }
  }

  /**
   * It updates the current term, sets the monthly payment limits, sets the installment, updates the
   * interest rate and sets the max balloon amount
   * @param {number} term - number - the term of the loan in months
   */
  public handleTermChanged(term: number): void {
    this.currentTerm = term
    this.setMonthlyPaymentLimits(term)
    this.setInstallment()
    this.updateInterrestRate(this.currentIntererstRateType)
    this.maxBalloon = this.getMaxBalloonAmount(term, this.currentDeposit)
  }

  /**
   * It updates the current interest rate type and then updates the interest rate and installment
   * @param {InterestRateType} interrestType - InterestRateType - this is the type of interest rate that
   * the user has selected.
   */
  public handleTermTypeChange(interrestType: InterestRateType): void {
    this.currentIntererstRateType = interrestType
    this.updateInterrestRate(interrestType)
    this.setInstallment()
  }
  /**
   * It sets the maximum deposit value based on the current balloon value
   * @param {number} value - number - The current value of the balloon slider
   */

  public handleBalloonChanged(value: number): void {
    this.currentBalloon = value
    const balloonPercentage = (value / this.retailPrice) * 100

    if (balloonPercentage >= 1) {
      this.setMonthlyPaymentLimits(this.currentTerm)
      this.setInstallment()
      this.updateInterrestRate(this.currentIntererstRateType)

      const nacd = this.getNACD(this.getMinInterestRate('L'))

      /// let's update the deposit, by calculating the deposit based on the current balloon value
      /// this was not updating before, hence deposit would always be the same
      const tmpMaxDeposit = this.getMaxDepositAmount(this.principleDebt)
      const tmpMaxBalloon = this.getMaxBalloonPercentage(tmpMaxDeposit, this.currentTerm)

      const d1 = this.getDeposit(
        value,
        this.getBaloonPercentage(),
        this.currentTerm,
        this.getTotalPVFactors(
          tmpMaxBalloon,
          tmpMaxDeposit,
          this.currentTerm,
          this.price,
          this.retailPrice,
          0,
          nacd
        ),
        this.price,
        this.initiationFees
      )
      this.depositEvent.next(d1 > 0 ? d1 : 0)
    }

    this.setDepositMaxFromBalloon()
  }
  /**
   * It takes a deposit and totalExtras and returns the principle debt plus the totalExtras plus the
   * initiation fees minus the deposit
   * @param {number} deposit - The amount the user has paid upfront
   * @param {number} totalExtras - This is the total of all the extras that the user has selected.
   * @returns The principle debt is being returned.
   */
  public getPrinicipleDept(deposit: number, totalExtras: number): any {
    const pd = this.principleDebt + totalExtras + this.initiationFees - deposit

    if (pd < 30000) {
      return 30000
    } else {
      return pd
    }
  }

  /**
   * It returns an array of objects that contain the percentage and the value of the term
   * @returns An array of objects with two properties: per and value.
   */
  public getTermsRange(): any {
    const tmp: any[] = []
    const result: { per: string; value: any }[] = []
    if (this.scoreGrid?.term)
      this.scoreGrid.term.forEach((item) => {
        if (item.months !== this.getMaximumTerm() && item.months !== this.getMinimumTerm()) {
          tmp.push(item.months)
        }
      })
    let percentage = 0

    tmp.forEach((value) => {
      percentage += 100 / (tmp.length === 1 ? 2 : tmp.length)

      const per = percentage + '%'
      result.push({ per, value })
    })

    return result
  }

  /**
   * It returns the minimum monthly payment for a given number of months, number of days, and whether or
   * not to check for a balloon payment
   * @param {number} termsMonths - The number of months you want to pay off the loan.
   * @param {number} numDays - number of days in the month
   * @param [checkBalloon=true] - If true, the balloon payment will be checked. If false, the balloon
   * payment will be ignored.
   * @returns The monthly payment minimum.
   */
  public getMonthlyPaymentMinimum(
    termsMonths: number,
    numDays: number,
    checkBalloon = true
  ): number {
    const nacd = this.getNACD(this.getMinInterestRate('L'))

    const tmpMaxDeposit = this.getMaxDepositAmount(this.principleDebt)
    let tmpMaxBalloon = 0

    if (checkBalloon) {
      tmpMaxBalloon = this.getMaxBalloonPercentage(tmpMaxDeposit, termsMonths)
    }

    const pvFactors = this.getTotalPVFactors(
      tmpMaxBalloon,
      tmpMaxDeposit,
      termsMonths,
      this.price,
      this.retailPrice,
      numDays,
      nacd
    )
    if (this.allowLog) {
      console.log('TotalFactors', {
        pvFactors,
        tmpMaxBalloon,
        tmpMaxDeposit,
        termsMonths,
        price: this.price,
        retailPrice: this.retailPrice,
        numDays,
        nacd,
        firstInstallmentDate: this.firstInstallmentDate,
        date: this.loanExpiryDate
      })
    }
    return Math.abs(this.getInstallment(tmpMaxBalloon, pvFactors, tmpMaxDeposit))
  }

  /**
   * It returns the interest rate for a given deposit amount, terms, balloon percentage and rate type
   * @param {number} terms - number,
   * @param {number} depositAmount - The amount of money you want to deposit
   * @param {number} balloonPercentage - The percentage of the total cost that the customer wants to pay
   * upfront.
   * @param {number} totalCost - The total cost of the vehicle
   * @param {string} rateType - 'F' or 'L' for fixed or linked
   * @returns The interest rate for the given deposit amount, terms, balloon percentage, total cost, and
   * rate type.
   */
  public getInterestRate(
    terms: number,
    depositAmount: number,
    balloonPercentage: number,
    totalCost: number,
    rateType: string
  ): number {
    const currentDepositItem = this.getCurrentDepositItem(depositAmount, terms)

    if (rateType === 'F') {
      const cBallon = currentDepositItem
        ?.balloon!.filter((g) => g.percentage ?? 0 <= balloonPercentage)
        .sort((g) => g.percentage ?? 0)
      if (cBallon?.length) {
        return cBallon[0].fixedRate as number
      }
      return this.defaultFixedRate
    } else {
      const cBallon = currentDepositItem
        ?.balloon!.filter((g) => g.percentage ?? 0 <= balloonPercentage)
        .sort((g) => g.percentage ?? 0)
      if (cBallon?.length) {
        return cBallon[0].linkedRate as number
      }
      return this.defaultLinkedRate
    }
  }

  /**
   * "Get the minimum interest rate for the given interest type."
   *
   * The function is defined as public, so it can be called from outside the class. It takes one
   * parameter, interestType, which is of type InterestRateType. The function returns a number
   * @param {InterestRateType} interestType - InterestRateType - This is the type of interest rate you
   * want to get the minimum for.
   * @returns The minimum interest rate for the given interest type.
   */
  public getMinInterestRate(interestType: InterestRateType): number {
    if (interestType === 'F') {
      return this.scoreGrid.term![0].deposit![0]?.balloon![0].fixedRate as number
    } else {
      return this.scoreGrid.term![0].deposit![0]?.balloon![0].linkedRate as number
    }
  }

  /**
   * It takes in a bunch of parameters, does some calculations, and returns a number
   * @param {number} balloonPercentage - The percentage of the car's price that you want to pay at the
   * end of the loan.
   * @param {number} depositAmount - The amount of money you want to put down on the car.
   * @param {number} termsMonths - The number of months you want to pay the car off in.
   * @param {number} days - number of days in the month
   * @param {string} interestType - 'L' for Lease, 'F' for Finance
   * @returns The monthly payment
   */
  public getMonthlyPayment(
    balloonPercentage: number,
    depositAmount: number,
    termsMonths: number,
    days: number,
    interestType: string
  ): number {
    if (depositAmount < 500) {
      depositAmount = 0
    }
    this.addToLog('-------------------', '------------------')
    this.addToLog('getMonthlyPayment', 'called')
    this.addToLog('depositAmount', depositAmount)
    this.addToLog('termsMonths', termsMonths)
    this.addToLog('days', days)
    this.addToLog('interestType', interestType)

    const interrestRate = this.getInterestRate(
      termsMonths,
      depositAmount,
      balloonPercentage,
      this.price,
      interestType
    )
    this.addToLog('interrestRate', interrestRate)

    const nacd = this.getNACD(interrestRate)
    this.addToLog('nacd', nacd)
    const totalPVFactor = this.getTotalPVFactors(
      balloonPercentage,
      depositAmount,
      termsMonths,
      this.price,
      this.retailPrice,
      0,
      nacd
    )
    this.addToLog('totalPVFactor', JSON.stringify(totalPVFactor))

    const installment = this.getInstallment(balloonPercentage, totalPVFactor, depositAmount)
    this.addToLog('installment', installment)
    this.addToLog('', '')
    this.addToLog('-------------------', '------------------')
    this.writelog()

    if (depositAmount === 0 && balloonPercentage === 0 && this.currentIntererstRateType === 'L') {
      return this.maxMonthly
    }
    if (installment < this.minMonthly) {
      return this.minMonthly
    }
    return installment
  }

  /**
   * It returns the maximum interest rate for a given interest rate type (fixed or linked) from the score
   * grid
   * @param {InterestRateType} interestType - InterestRateType
   * @returns The maximum interest rate for the given interest type.
   */
  public getMaxInterestRate(interestType: InterestRateType): number {
    const maxDepositItems = this.scoreGrid.term![this.scoreGrid.term!.length - 1].deposit
    const maxBalloonItems = maxDepositItems![maxDepositItems!.length - 1]?.balloon
    const maxBalloonItem = maxBalloonItems![maxBalloonItems!.length - 1]

    if (interestType === 'F') {
      if (this.allowLog) console.log('fixedRate' + maxBalloonItem.fixedRate)
      return maxBalloonItem.fixedRate as number
    } else {
      if (this.allowLog) console.log('linkedRate' + maxBalloonItem.linkedRate)
      return maxBalloonItem.linkedRate as number
    }
  }

  /**
   * It calculates the daily interest rate for a given annual interest rate
   * @param {number} interestRate - The interest rate of the loan.
   * @returns The NACD is being returned.
   */
  public getNACD(interestRate: number): number {
    const baseNacd = interestRate / 1200 + 1
    const expNacd = 12 / 365
    const nacdTmp = Math.pow(baseNacd, expNacd)
    const nacdTmp2 = nacdTmp - 1
    return nacdTmp2 * 36500
  }

  /**
   * It takes a number of days and a NACD and returns a PV factor
   * @param {number} numberOfDays - The number of days between the date of the transaction and the date
   * of the valuation.
   * @param {number} NACD - Number of Actual Calendar Days
   * @returns The present value factor.
   */
  public getPVFactor(numberOfDays: number, NACD: number): number {
    const pvFactorBase = NACD / 36500 + 1
    Decimal.set({ precision: 40 })
    const pvFactor = Math.pow(pvFactorBase, numberOfDays)
    return 1 / pvFactor
  }

  /**
   * It takes the number of days, the balloon amount, and the NACD and returns the balloon PV factor
   * @param {number} numberOfDays - The number of days between the current date and the balloon date.
   * @param {number} balloonAmt - The amount of the balloon payment
   * @param {number} NACD - Net Annual Cost of Debt
   * @returns The balloonPVFactor
   */
  public getBalloonPVFactor(numberOfDays: number, balloonAmt: number, NACD: number): number {
    this.addToLog('Balloon PVFactors Start', '')
    this.addToLog('numberOfDays', numberOfDays)
    this.addToLog('balloonAmt', balloonAmt)
    this.addToLog('NACD', NACD)
    const pvFactorBase = NACD / 36500 + 1
    this.addToLog('pvFactorBase', pvFactorBase)
    const pvFactor = Math.pow(pvFactorBase, numberOfDays)
    this.addToLog('pvFactor', pvFactor)
    const balloonPVFactor = balloonAmt / pvFactor
    this.addToLog('balloonPVFactor', balloonPVFactor)
    this.writelog()
    return balloonPVFactor
  }

  /* Calculating the monthly payment maximum. */
  public getTotalPVFactors(
    balloonPercentage: number,
    deposit: number,
    numberOfInstalments: number,
    price: number,
    retailPrice: number,
    numDays: number,
    NACD: number
  ): PVFactors {
    this.addToLog('getTotalPVFactors', 'called')
    this.addToLog('balloonPercentage', balloonPercentage)
    this.addToLog('deposit', deposit)
    this.addToLog('numberOfInstalments', numberOfInstalments)
    this.addToLog('price', price)
    this.addToLog('retailPrice', retailPrice)
    this.addToLog('numDays', numDays)
    this.addToLog('NACD', NACD)

    BigNumber.config({
      DECIMAL_PLACES: 40,
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP
    })

    let balloonPVFactors = 0

    // tslint:disable-next-line:prefer-const

    const depositPerc = (deposit / price) * 100
    if (this.allowLog) console.log({ depositPerc })

    // Check if balloon payment > 0 then exclude 1 month
    if (balloonPercentage > 0) {
      numberOfInstalments--
    }

    let totalPVFactors = new BigNumber(this.getPVFactor(numDays, NACD)).toNumber()

    const pvFactor = totalPVFactors
    if (this.allowLog) console.log({ pvFactor })

    this.addLogSpace()
    this.addToLog('In loop', '')
    for (let i = 0; i < numberOfInstalments; i++) {
      // Have already calculated initial
      this.addLogSpace()

      numDays = this.getNumberOfDaysBetweenTwoDates(new Date(), this.firstInstallmentDate, i) + 1
      this.addToLog('numDays', numDays)
      // tslint:disable-next-line:no-shadowed-variable

      const innerPvFactor = new BigNumber(this.getPVFactor(numDays, NACD))
      if (this.allowLog) console.log({ pvFactor: innerPvFactor })

      this.addToLog('pvFactor', innerPvFactor)
      if (i === 0) {
        totalPVFactors = innerPvFactor.toNumber()
      } else {
        totalPVFactors = new BigNumber(totalPVFactors).plus(innerPvFactor).toNumber()
      }

      this.addToLog('totalPVFactors', totalPVFactors)
      this.addLogSpace()
      /* DEBUG */
    }

    // Calculate accumilated PV Factors according to the number of installments (months)
    if (balloonPercentage > 0) {
      const balloonNumDays = this.getNumberOfDaysBetweenTwoDates(
        new Date(),
        this.firstInstallmentDate,
        numberOfInstalments + 1
      )

      const ballonAmt = this.getBalloonAmount(balloonPercentage) // Get balloon amount
      balloonPVFactors = this.getBalloonPVFactor(balloonNumDays, ballonAmt, NACD)
    }

    const pv = new PVFactors()
    pv.numDays = numDays
    pv.balloonPVFactors = balloonPVFactors
    pv.totalPVFactors = totalPVFactors

    return pv
  }

  /**
   * It calculates the maximum monthly payment for a given number of months and number of days
   * @param {number} termsMonth - number of months
   * @param {number} numDays - number of days in the month
   * @returns The monthly payment maximum
   */
  public getMonthlyPaymentMaximum(termsMonth: number, numDays: number): number {
    const nacd = this.getNACD(this.getMaxInterestRate('F'))
    if (this.allowLog) console.log('nacd' + nacd)
    const tmpMinBalloon = 0

    const pvFactors = this.getTotalPVFactors(
      tmpMinBalloon,
      0,
      termsMonth,
      this.price,
      this.retailPrice,
      numDays,
      nacd
    )

    if (this.allowLog) console.log('pvFactors' + pvFactors)
    if (this.allowLog) console.log('tmpMinBalloon' + tmpMinBalloon)
    return this.getInstallment(tmpMinBalloon, pvFactors, 0)
  }

  /**
   * > This function returns the minimum term in months from the score grid
   * @returns The minimum term in months
   */
  public getMinimumTerm(): number {
    return this.scoreGrid.term![this.scoreGrid.term!.length - 1].months as number
  }

  /**
   * > This function returns the maximum term in months from the score grid
   * @returns The maximum term in months
   */
  public getMaximumTerm(): number {
    return this.scoreGrid.term![0].months as number
  }

  /**
   * It returns the minimum deposit percentage for the longest term
   * @param {number} cashPrice - The price of the car
   * @returns The minimum deposit percentage for the longest term.
   */
  public getMinDeposit(cashPrice: number): number {
    const minPercentage =
      this.scoreGrid.term![this.scoreGrid.term!.length - 1].deposit![
        this.scoreGrid.term![this.scoreGrid.term!.length - 1].deposit!.length - 1
      ].percentage
    return (minPercentage ?? 0 * cashPrice) / 100
  }
  /**
   * It returns the maximum deposit amount that a customer can pay for a car
   * @param {number} principleDebt - The amount of debt that the customer has to pay.
   * @returns The max deposit amount is being returned.
   */

  public getMaxDepositAmount(principleDebt: number): number {
    return principleDebt - 30000
  }

  /**
   * It returns the lowest percentage of the highest balloon item in the highest deposit item in the
   * highest term item in the score grid
   * @returns The lowest percentage of the last deposit item in the last term.
   */
  public getMinBalloon(): number {
    const maxDepositItems = this.scoreGrid.term![this.scoreGrid.term!.length - 1].deposit
    const maxBalloonItems = maxDepositItems![maxDepositItems!.length - 1]?.balloon

    return maxBalloonItems!.sort((g: { percentage: any }) => g.percentage)[0].percentage as number
  }
  /**
   * It returns the maximum balloon percentage for a given deposit and term
   * @param {number} deposit - number - the amount of money you want to deposit
   * @param {number} term - number - the term of the deposit in months
   * @returns The maximum balloon percentage for the given deposit and term.
   */
  public getMaxBalloonPercentage(deposit: number, term: number): number {
    const currentDepositItem = this.getCurrentDepositItem(deposit, term)

    return currentDepositItem?.balloon![0].percentage as number
  }

  /**
   * It takes a deposit and term and returns the maximum balloon amount for that deposit and term
   * @param {number} deposit - number - The deposit amount
   * @param {number} term - number - The term of the loan in months
   * @returns The maximum balloon amount
   */
  public getMaxBalloonAmount(deposit: number, term: number): number {
    if (term === 0) {
      term = this.getMaximumTerm()
    }
    const maxBalloonLookup =
      ((this.getCurrentDepositItem(deposit, term)?.balloon?.[0]?.percentage ?? 0) / 100) *
      this.retailPrice

    const maxBalloonCalc = (this.principleDebt - parseFloat(deposit?.toString())) * 0.8

    // TODO: figure out what to do when maxBalloonCalc is NaN
    //if (!isNaN(maxBalloonCalc)) return maxBalloonCalc

    if (maxBalloonLookup > maxBalloonCalc) {
      return maxBalloonCalc
    } else {
      return maxBalloonLookup
    }
  }

  /**
   * This function takes a percentage and a cash price and returns the maximum deposit.
   * @param {number} maxDepositPercentage - The maximum deposit percentage that the user can enter.
   * @param {number} cashPrice - The price of the car
   * @returns The maximum deposit amount.
   */
  public getMaxDeposit(maxDepositPercentage: number, cashPrice: number): number {
    return (parseFloat(maxDepositPercentage.toString()) * parseFloat(cashPrice.toString())) / 100
  }
  /**
   * It takes the current date, and the first installment date, and returns the number of days between
   * the two
   * @returns The number of days between the current date and the next installment date.
   */

  public getNumberDays(): number {
    const globalStartDate = moment()
    const globalStartDateFormat = moment(globalStartDate.format('YYYY-MM-DD'))
    const globalStartDateMonth = ('0' + (globalStartDate.month() + 1)).slice(-2)
    if (this.allowLog) console.log({ globalStartDateMonth })
    const globalInstallmentDefaultDate = moment(this.firstInstallmentDate)
    const installmentDate = moment(globalInstallmentDefaultDate)
    let nextInstalmentDate = installmentDate

    let numDays = nextInstalmentDate.diff(globalStartDateFormat, 'days')

    if (numDays < 15) {
      nextInstalmentDate = moment(this.firstInstallmentDate)

      const nextInstalmentDateFormat = moment(nextInstalmentDate.format('YYYY-MM-DD'))

      numDays += nextInstalmentDateFormat.diff(globalStartDateFormat, 'days')
    }

    return numDays
  }

  /**
   * It returns the number of days between the first installment date and the loan expiry date
   * @returns The number of days between the first installment date and the loan expiry date.
   */
  public getNumberDaysFirstInstallmentDateLoanExpiryDate(): number {
    return this.getNumberOfDaysBetweenTwoDates(this.firstInstallmentDate, this.loanExpiryDate, 0)
  }

  /**
   * The function takes in a balloon percentage, total PV factors, and a deposit amount. It then
   * calculates the loan amount, balloon amount, and principle debt. It then divides the principle debt
   * by the total PV factor and returns the result
   * @param {number} balloonPerc - The percentage of the balloon amount
   * @param {PVFactors} totalPVFactors - PVFactors
   * @param {number} deposit - The deposit amount
   * @returns The installment amount
   */
  public getInstallment(balloonPerc: number, totalPVFactors: PVFactors, deposit: number): number {
    BigNumber.config({
      DECIMAL_PLACES: 40,
      ROUNDING_MODE: BigNumber.ROUND_HALF_UP
    })

    let principleDebt = 0
    let balloonPVFactor = 0
    const numberOfDays = totalPVFactors.numDays // Total number of days
    if (this.allowLog) console.log({ numberOfDays })

    const totalPV = totalPVFactors.totalPVFactors // Total PV factors
    const loanAmount = this.getLoanAmount(deposit) // Loan amount
    const ballonAmt = this.getBalloonAmount(balloonPerc) // Balloon Amount

    if (ballonAmt > 0) {
      balloonPVFactor = totalPVFactors.balloonPVFactors as number // Balloon PV factor
      principleDebt = loanAmount - balloonPVFactor
    } else {
      principleDebt = loanAmount - ballonAmt // New principle debt
    }

    return principleDebt / totalPV! // Divide principle debt by total PV factor
  }

  /**
   * It takes a deposit amount and returns the loan amount
   * @param {number} deposit - The amount of money the user is putting down on the car.
   * @returns The loan amount
   */
  public getLoanAmount(deposit: number): number {
    return this.price - deposit + this.initiationFees
  }

  /**
   * It takes a percentage and returns the amount of the retail price that percentage represents
   * @param {number} balloonPerc - The percentage of the retail price that the balloon amount is.
   * @returns The balloon amount
   */
  public getBalloonAmount(balloonPerc: number): number {
    return (this.retailPrice * balloonPerc) / 100
  }

  /**
   * It takes a bunch of parameters, and returns a bunch of values
   * @param {number} terms - number of months
   * @param {number} deposit - The amount of deposit you want to put down
   * @param {number} balloon - The amount you want to pay at the end of the loan term.
   * @param {number} montlyAmount - The amount the user wants to pay per month
   * @param {number} days - number of days in the year
   * @param {InterestRateType} innterestType - InterestRateType - this is an enum that is used to
   * determine the interest rate type.
   * @returns A SliderValues object
   */
  public getBestFitDeposit(
    terms: number,
    deposit: number,
    balloon: number,
    montlyAmount: number,
    days: number,
    innterestType: InterestRateType
  ): SliderValues {
    const tempValues = new SliderValues()
    const depositAmount = this.getDepositFine(terms, deposit, balloon, montlyAmount)

    tempValues.monthlyInstallment = this.getMonthlyPayment(
      balloon,
      depositAmount,
      terms,
      days,
      innterestType
    )
    tempValues.terms = terms
    tempValues.baloon = balloon
    tempValues.deposit = depositAmount
    tempValues.monthlyInstallmentDiff = Math.abs(tempValues.monthlyInstallment - montlyAmount)

    return tempValues
  }

  /**
   * It takes a monthly amount, and returns the best fit term and deposit for that amount
   * @param {number} montlyAmount - The amount the user has selected
   * @param {number} terms - number of months
   * @param {number} deposit - number,
   * @param {number} balloon - number - the balloon amount
   * @param {InterestRateType} interestType - InterestRateType - this is the type of interest rate you
   * want to use.
   * @returns {
   *     term: number,
   *     deposit: number,
   *     balloon: number
   *   }
   */
  public findMontlyAmountSliderValues(
    montlyAmount: number,
    terms: number,
    deposit: number,
    balloon: number,
    interestType: InterestRateType
  ): SliderValues {
    const days: number = this.getNumberDays()

    // loop  throught all the terms to see which option is the closest to the selected amount

    const bestFitTermAndDeposit = this.getBestFitDeposit(
      terms,
      deposit,
      balloon,
      montlyAmount,
      days,
      interestType
    )

    const maxDeposit = this.getMaxDepositAmount(this.price)

    if (bestFitTermAndDeposit.deposit ?? 0 >= maxDeposit) {
      bestFitTermAndDeposit.deposit = maxDeposit
    }
    return bestFitTermAndDeposit
  }
  /**
   * It calculates the deposit amount for a given monthly fee, terms, balloon, price, initiation fees,
   * and retail price
   * @param {number} terms - number of months
   * @param {number} deposit - number,
   * @param {number} balloon - number,
   * @param {number} requiredMontlyFee - The monthly fee that the user has selected
   * @returns The deposit fine.
   */
  public getDepositFine(
    terms: number,
    deposit: number,
    balloon: number,
    requiredMontlyFee: number
  ): number {
    const days = this.getNumberDays()
    const interestRate = this.getInterestRate(terms, deposit, balloon, this.price, 'F')

    const nacd = this.getNACD(interestRate)

    const pvfactors: PVFactors = this.getTotalPVFactors(
      balloon,
      deposit,
      terms,
      this.price,
      this.retailPrice,
      days,
      nacd
    )

    const tmpDeposit = this.getDeposit(
      requiredMontlyFee,
      balloon,
      terms,
      pvfactors,
      this.price,
      this.initiationFees
    )
    let depositSliderValue = 0
    if (tmpDeposit > 0) {
      depositSliderValue = tmpDeposit
    }
    return depositSliderValue
  }

  /**
   * It takes a monthly payment, a balloon percentage, a number of months, a price, and initiation fees,
   * and returns the deposit amount
   * @param {number} monthlyPayment - The monthly payment amount that the user has entered.
   * @param {number} balloonPerc - The balloon percentage
   * @param {number} months - number of months
   * @param {PVFactors} totalPVFactors - PVFactors
   * @param {number} price - The price of the car
   * @param {number} initiationFees - The initiation fees that the user has to pay.
   * @returns The deposit amount
   */
  public getDeposit(
    monthlyPayment: number,
    balloonPerc: number,
    months: number,
    totalPVFactors: PVFactors,
    price: number,
    initiationFees: number
  ): number {
    if (this.allowLog) console.log({ months })
    let balloonPVFactor = 0

    const totalPV = totalPVFactors.totalPVFactors
    balloonPVFactor = totalPVFactors.balloonPVFactors as number

    /*
      Get principle debt
    */
    const reversePrincipleDebt = monthlyPayment * totalPV!
    /*
      Get loan amount
    */
    let reverseLoanAmount = 0
    reverseLoanAmount = Math.floor(reversePrincipleDebt)
    if (reverseLoanAmount !== price) {
      reverseLoanAmount = Math.floor(reversePrincipleDebt / 1) * 1
    }
    if (reverseLoanAmount !== price) {
      reverseLoanAmount = Math.ceil(reversePrincipleDebt)
    }
    if (reverseLoanAmount !== price) {
      reverseLoanAmount = Math.ceil(reversePrincipleDebt / 1) * 1
    }
    if (reverseLoanAmount !== price) {
      reverseLoanAmount = Math.round(reversePrincipleDebt)
    }
    if (reverseLoanAmount !== price) {
      reverseLoanAmount = Math.round(reversePrincipleDebt / 1) * 1
    }

    let reverseDeposit = price - reverseLoanAmount + initiationFees
    reverseDeposit = reverseDeposit - balloonPVFactor
    reverseDeposit = Math.round(reverseDeposit / 10) * 10

    // Max
    if (reverseDeposit < 0) {
      const minDepositInstallment = this.getInstallment(balloonPerc, totalPVFactors, 0)
      if (this.allowLog) console.log({ minDepositInstallment })
    }

    if (reverseDeposit > this.getMaxDepositAmount(price)) {
      const maxDepositInstallment = this.getInstallment(
        balloonPerc,
        totalPVFactors,
        this.getMaxDepositAmount(price)
      )
      if (this.allowLog) console.log({ maxDepositInstallment })
    }

    return reverseDeposit
  }

  public getCurrentDepositItem(depositAmount: number, term: number): Deposit {
    const currentTerms = this.scoreGrid.term!.filter((g) => g.months === term)[0]
    const depositPercentage = (depositAmount / this.price) * 100
    const maxDepositPercentage = Math.floor(depositPercentage / 1.0) * 1.0
    const deposit = currentTerms
      ?.deposit!.filter(
        (g: { percentage: number | undefined }) => g.percentage! <= maxDepositPercentage
      )
      .sort((g: { percentage: any }) => g.percentage)
    return deposit?.[0]
  }

  /**
   * It returns the number of days between two dates, taking into account the fact that the first date
   * may be the last day of the month, and the second date may be the last day of the month
   * @param {Date} dateOne - The date of the first payment
   * @param {Date} firstInstallmentDate - The date of the first installment.
   * @param {number} incrementMonths - This is the number of months to increment the firstInstallmentDate
   * by.
   * @returns The number of days between the two dates.
   */
  private getNumberOfDaysBetweenTwoDates(
    dateOne: Date,
    firstInstallmentDate: Date,
    incrementMonths: number
  ) {
    const start: moment.Moment = moment(dateOne)

    this.addToLog('Days in month', start.daysInMonth())
    this.addToLog('Day of month', start.get('D'))

    let end: moment.Moment = moment(firstInstallmentDate).add(incrementMonths, 'M')

    this.addToLog('StartDate', start)

    const isFirstInstallmentDateEndOfMonth =
      moment(firstInstallmentDate).daysInMonth() === moment(firstInstallmentDate).get('D')

    this.addToLog('isFirstInstallmentDateEndOfMonth', isFirstInstallmentDateEndOfMonth)

    if (isFirstInstallmentDateEndOfMonth) {
      end = end.endOf('month')
    }
    this.addToLog('End', end)
    const numDays: number = end.diff(start, 'd')

    return numDays - 1
  }

  /**
   * This function takes a key and a value, and adds them to the log array.
   * @param {string} key - The name of the parameter.
   * @param {any} value - The value of the input field.
   */
  private addToLog(key: string, value: any): void {
    this.log.push(`${key}:${value}`)
  }
  /**
   * It adds a space to the log
   */
  private addLogSpace(): void {
    this.log.push(` `)
  }
  /**
   * It writes the log to the console.
   */
  private writelog(): void {
    if (this.allowLog) console.log(this.log.join('\n\r'))
    this.log = []
  }

  /**
   * It takes an object, loops through all of its properties, and if the property is a number, it
   * converts it to a number
   * @param {any} obj - any - The object that you want to convert the strings to numbers.
   * @returns the object that was passed in.
   */
  public static stringToNumberJSON(obj: any): any {
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        this.handObjectType(obj, property)
      }
    }
    return obj
  }

  public static handObjectType(obj: any, property: string) {
    if (typeof obj[property] === 'object') {
      this.stringToNumberJSON(obj[property])
    } else {
      this.handleNumeringStringProperties(obj, property)
    }
  }

  public static handleNumeringStringProperties(obj: any, property: string) {
    if (obj.hasOwnProperty(property) && obj[property] !== null && !isNaN(obj[property])) {
      this.parseNumricString(obj, property)
    }
  }

  /**
   * It takes an object and a property name and if the property is a string that can be parsed as a
   * number, it will parse it as a number
   * @param {any} obj - the object that contains the property
   * @param {string} property - The property of the object that you want to parse.
   */
  public static parseNumricString(obj: any, property: string) {
    if (obj[property].toString().lastIndexOf('.') > -1) {
      obj[property] = parseFloat(obj[property])
    } else {
      // tslint:disable-next-line:radix
      obj[property] = parseInt(obj[property])
    }
  }

  /**
   * If the current deposit is greater than the maximum deposit, then set the current deposit to the
   * maximum deposit
   */
  private setDepositMaxFromBalloon(): void {
    this.maxDeposit = this.principleDebt - this.currentBalloon / 0.8

    if (this.maxDeposit < 0) {
      this.maxDeposit = 0
    }

    if (this.currentDeposit > this.maxDeposit) {
      this.depositEvent.next(this.maxDeposit)
      this.principleDebtDisplay = this.maxDeposit
    }
  }
  private setMonthlyPaymentLimits(term: number): void {
    this.maxMonthly = this.getMonthlyPaymentMaximum(
      parseFloat(term.toString()),
      this.getNumberDays()
    )

    this.minMonthly = this.round(
      this.getMonthlyPaymentMinimum(this.getMaximumTerm(), this.getNumberDays(), false),
      2
    )
  }

  /**
   * It updates the current interest rate based on the current term, deposit, baloon percentage and
   * interest rate type
   * @param {InterestRateType} iType - InterestRateType - The type of interest rate you want to get.
   */
  private updateInterrestRate(iType: InterestRateType): void {
    this.currentIntererstRate = this.getInterestRate(
      this.currentTerm,
      this.currentDeposit,
      this.getBaloonPercentage(),
      0,
      iType
    )
  }

  /**
   * It takes the current deposit, term, interest rate type, and number of days, and returns the monthly
   * payment
   */
  private setInstallment(): void {
    const display = this.getMonthlyPayment(
      this.getBaloonPercentage(),
      this.currentDeposit,
      this.currentTerm,
      this.getNumberDays(),
      this.currentIntererstRateType
    )

    this.installmentEvent.next(this.round(display, 2))
  }
  private round(value: number, digits: number): number {
    /* Rounding a number to a certain number of decimal places. */
    value = value * Math.pow(10, digits)
    value = Math.round(value)
    value = value / Math.pow(10, digits)
    return value
  }
  /**
   * If the max balloon from percentage is greater than the max balloon, then set the max balloon to the
   * max balloon. Otherwise, if the max balloon from percentage is less than or equal to the max balloon,
   * then set the max balloon to the max balloon from percentage. Otherwise, set the max balloon to 0
   * @param {number} maxBalloonFromPercentage - The maximum balloon amount based on the percentage of the
   * principle debt.
   */
  private setMaxBalloonFromDeposit(maxBalloonFromPercentage: number): void {
    const depositAmount = this.currentDeposit
    const maxBalloon = (this.principleDebt - depositAmount) * 0.8

    if (maxBalloonFromPercentage > maxBalloon && maxBalloon > 0) {
      this.maxBalloon = maxBalloon
      if (this.maxBalloon < 0) {
        this.maxBalloon = 0
      }
    } else {
      if (maxBalloonFromPercentage <= maxBalloon) {
        this.maxBalloon = maxBalloonFromPercentage
      } else {
        this.maxBalloon = 0
      }
    }
    if (this.currentBalloon > this.maxBalloon) {
      this.balloonEvent.next(this.maxBalloon)
    }

    if (this.maxBalloon === 0) {
      this.balloonEvent.next(0)
    }
  }
  /**
   * It returns the percentage of the retail price that the current balloon represents
   * @returns The percentage of the current balloon payment compared to the retail price.
   */
  private getBaloonPercentage(): number {
    return (this.currentBalloon / this.retailPrice) * 100
  }
  /**
   * It sets the minimum and maximum values for the deposit, monthly payment, and balloon payment
   */
  public setRanges(): void {
    if (!this) return
    try {
      this.minMonthly = Math.ceil(
        this.getMonthlyPaymentMinimum(this.terms[0].value, this.getNumberDays(), false)
      )
      this.maxMonthly = Math.floor(this.monthlyInstallment)
      this.minDeposit = this.getMinDeposit(this.price)
      this.maxDeposit = this.getMaxDepositAmount(this.principleDebt)
      this.maxTerm = this.getMaximumTerm()
      this.maxBalloon = this.getMaxBalloonAmount(this.minDeposit, this.maxTerm)
    } catch (error) {
      console.log({ error })
    }
  }
}
