import { Box, Flex, Stack } from '@chakra-ui/react'
import * as React from 'react'
import TellUsForm from '../../components/Form/TellUsForm'

function TellUsAboutYourVehicleScreen(): React.ReactElement {
  return (
    <Flex flexDirection="column">
      <Stack spacing={2} width={['400px', '500px', '100%']}>
        <Box pt={0}>
          <TellUsForm />
        </Box>
      </Stack>
    </Flex>
  )
}

export default TellUsAboutYourVehicleScreen
