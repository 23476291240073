import { Button, Flex, FormLabel, Stack, Text } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import VWRadioGroup from '../../../components/VWRadioGroup'
import { StepsNavigation } from '../../../containers/ApplicationSection'
import { useAppContext } from '../../../context/AppProvider'
import { useAuth } from '../../../context/AuthProvider'
import { usePreApprovalSteps } from '../../../context/PreApprovalStepsProvider'
import { FormSelectionData, PaymentType, useData } from '../../../context/UserDataProvider'
import {
  FinanceApplicationStatus,
  VehicleModelOption,
  VehicleModelPayload,
  useAllVehicleMakesLazyQuery,
  useAllVehicleModelsLazyQuery,
  useOneVehicleLazyQuery
} from '../../../generated'
import { AboutVehicle } from '../../../models/AboutVehicle'
import { RadioInput } from '../../../models/RadioInput'
import formatFinanceAmount, {
  reverseFormatNumber
} from '../../../utils/math/format/formatFinanceAmount'
import {
  aboutVehicleDefaultValues,
  aboutVehicleSchema
} from '../../../utils/schemas/tellUsAboutYourVehicleSchema'
import { SelectInput, TextInput } from '../../FormElements'
import {
  audiBaseStyles,
  audifsTheme,
  vwBaseStyles,
  vwfsTheme
} from '../../FormElements/SelectInput'
import dayjs from 'dayjs'

type FormProps = {
  onClose?: () => void
}

type ValidYearType = {
  new: boolean
  used: boolean
  demo: boolean
}

type YearErrorMessageType = {
  new: string
  used: string
  demo: string
}

const vehicleConditions: Array<FormSelectionData> = [
  { value: 'New', label: 'New' },
  { value: 'Used', label: 'Used' },
  { value: 'Demo', label: 'Demo' }
]

const MIN_PURCHASE_PRICE = 30000

const TellUsForm: React.FC<FormProps> = ({ onClose }) => {
  const { user, appName } = useAuth()
  const { setIsStepLoading, isStepLoading, next, currentStep } = usePreApprovalSteps()
  const {
    mmCodeError,
    paymentMode,
    setPaymentMode,
    persistedProgress,
    persistProgress,
    updateNewUsedVehicle
  } = useData()
  const { aboutVehicle, setAboutVehicle } = useAppContext()
  const savedVehicleData = persistedProgress?.progress?.[1]
  const methods = useForm({
    defaultValues: {
      ...aboutVehicleDefaultValues,
      condition: { label: savedVehicleData?.condition, value: savedVehicleData?.condition },
      year: savedVehicleData?.year ?? aboutVehicleDefaultValues.year,
      purchasePrice:
        formatFinanceAmount(savedVehicleData?.purchasePrice) ||
        aboutVehicleDefaultValues.purchasePrice,
      make: { label: savedVehicleData?.make, value: savedVehicleData?.make } as any, // Type asserted to any to silence type errors on AsyncSelect
      model: { label: savedVehicleData?.model, value: savedVehicleData?.model } as any // Type asserted to any to silence type errors on AsyncSelect
    },
    resolver: zodResolver(aboutVehicleSchema)
  })
  const watchYear = methods.watch('year')
  const validYear = methods.getFieldState('year').invalid
  const watchMake = methods.watch('make')?.value
  const watchModel = methods.watch('model')?.value
  const watchCondition = methods.watch('condition')
  const watchPurchasePrice = methods.watch('purchasePrice')

  React.useEffect(() => {
    if (watchYear !== savedVehicleData?.year) {
      methods.resetField('make', { defaultValue: '' })
      methods.resetField('model', { defaultValue: '' })
      methods.resetField('purchasePrice', { defaultValue: '' })
    }
  }, [watchYear])

  React.useEffect(() => {
    if (watchMake !== savedVehicleData?.make) {
      methods.resetField('model', { defaultValue: '' })
      methods.resetField('purchasePrice', { defaultValue: '' })
    }
  }, [watchMake])

  React.useEffect(() => {
    if (watchModel !== savedVehicleData?.model) {
      methods.resetField('purchasePrice', { defaultValue: '' })
    }
  }, [watchModel])

  const [vehicle, setVehicle] = React.useState<VehicleModelPayload>()
  const [oneVehicle] = useOneVehicleLazyQuery({
    onCompleted: (res) => {
      setVehicle(res.oneVehicle as VehicleModelPayload)
    }
  })

  React.useEffect(() => {
    if (watchMake && watchModel && watchYear) {
      oneVehicle({
        variables: {
          filter: {
            make: watchMake,
            variant: watchModel,
            regYear: watchYear
          }
        }
      })
    }
  }, [watchYear, watchMake, watchModel])

  const [allVehicleMakes, setAllVehicleMakes] = React.useState<Array<VehicleModelOption>>([])
  const [fetchAllVehicleMakes, { loading: loadingMake }] = useAllVehicleMakesLazyQuery({
    onCompleted: (res) => {
      setAllVehicleMakes(res.allVehicleMakes as VehicleModelOption[])
    }
  })

  React.useEffect(() => {
    fetchAllVehicleMakes({
      variables: {
        filter: {
          condition: watchCondition.value,
          regYear: watchYear
        }
      }
    })
  }, [watchCondition.value, watchYear])

  const [allVehicleModels, setAllVehicleModels] = React.useState<Array<VehicleModelOption>>([])
  const [fetchAllVehicleModels] = useAllVehicleModelsLazyQuery({
    onCompleted: (res) => {
      setAllVehicleModels(res.allVehicleModels as VehicleModelOption[])
    }
  })

  React.useEffect(() => {
    fetchAllVehicleModels({
      variables: {
        filter: {
          regYear: watchYear,
          make: watchMake
        }
      }
    })
  }, [watchYear, watchMake])

  React.useEffect(() => {
    if (watchCondition.value) {
      updateNewUsedVehicle?.(watchCondition?.value?.[0])
    }

    if (watchCondition.value !== savedVehicleData?.condition) {
      methods.resetField('year', { defaultValue: '' })
      methods.resetField('make', { defaultValue: '' })
      methods.resetField('model', { defaultValue: '' })
      methods.resetField('purchasePrice', { defaultValue: '' })
    }
  }, [watchCondition])

  const paymentTypeValues: RadioInput<PaymentType>[] = [
    { value: 'IFC', message: 'I’ll pay cash' },
    { value: 'IFF', message: 'Finance Agreement' }
  ]

  const filterMakes = async (inputValue?: string) => {
    const res = await fetchAllVehicleMakes({
      variables: {
        filter: {
          condition: watchCondition.value,
          searchTerm: inputValue,
          regYear: watchYear
        }
      }
    })

    methods.setValue('model', { label: undefined, value: undefined })

    return res?.data?.allVehicleMakes as VehicleModelOption[]
  }

  const filterModels = async (inputValue: string) => {
    const res = await fetchAllVehicleModels({
      variables: {
        filter: {
          make: watchMake,
          searchTerm: inputValue,
          regYear: watchYear
        }
      }
    })

    return res.data?.allVehicleModels as VehicleModelOption[]
  }

  const promiseModelOptions = (inputValue: string) =>
    Promise.resolve<VehicleModelOption[]>(filterModels(inputValue))

  const promiseMakeOptions = (inputValue?: string) =>
    Promise.resolve<VehicleModelOption[]>(filterMakes(inputValue))

  const validateYear = (): ValidYearType => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const enteredYear = parseInt(methods.getValues().year)
    return {
      new: currentYear - 2 < enteredYear && currentYear + 2 > enteredYear,
      used: currentYear - 21 < enteredYear && currentYear + 1 > enteredYear,
      demo: currentYear - 1 === enteredYear || currentYear === enteredYear
    }
  }

  const yearIsValid = (): boolean =>
    validateYear()[watchCondition.value?.toLowerCase() as `new` | `used` | `demo`]

  const getYearErrorMessage = (): YearErrorMessageType => ({
    new: 'Please enter either this year, next year or last year',
    used: 'Vehicles older than 20 years do not qualify for finance',
    demo: 'Please enter either this year, or the previous year'
  })

  const yearErrorMessage = (): string =>
    getYearErrorMessage()[watchCondition.value?.toLowerCase() as `new` | `used` | `demo`]

  React.useEffect(() => {
    if (watchYear.length < 4 || !yearIsValid()) {
      methods.setError('year', {
        type: 'custom',
        message: yearErrorMessage()
      })
    } else {
      methods.clearErrors('year')
    }
  }, [watchYear, watchCondition])

  React.useEffect(() => {
    if (reverseFormatNumber(watchPurchasePrice) < MIN_PURCHASE_PRICE) {
      methods.setError('purchasePrice', {
        type: 'custom',
        message: 'Purchase price cannot be less than R30,000.00'
      })
    } else {
      methods.clearErrors('purchasePrice')
    }
  }, [watchPurchasePrice])

  const handleSubmit = async (data: AboutVehicle): Promise<void> => {
    const todayDate = dayjs(`${new Date()}`).format('DD/MM/YYYY')
    setAboutVehicle(data)
    if (user) {
      persistProgress?.({
        id: persistedProgress?.id as string,
        status: FinanceApplicationStatus.Pending,
        isCompleted: false,
        progress: {
          ...persistedProgress?.progress,
          currentStep: currentStep?.() && currentStep?.() + 1,
          mmCode: data.mmCode,
          1: {
            ...data,
            vehiclePageCompletedAt: todayDate
          }
        }
      })
    }

    if (currentStep?.() === 1) next?.()
  }

  async function submit(): Promise<void> {
    setIsStepLoading?.(true)
    const formData = methods.getValues()

    const inputData = {
      ...formData,
      purchasePrice: reverseFormatNumber(formData.purchasePrice).toString(),
      model: formData.model.value,
      make: formData.make.value,
      carHistory: formData.condition.value,
      condition: formData.condition.value,
      mmCode: vehicle?.mmCode,
      vehicleType: vehicle?.vehicleType
    }
    handleSubmit({ ...inputData })
    setIsStepLoading?.(false)
  }

  /**
   * This function handles input change for a specific input field, ensuring the value is within a given
   * range.
   * @param e - React.ChangeEvent<HTMLInputElement> - This is the event object that is triggered when
   * the input value changes. It contains information about the event, such as the target element and
   * the new value.
   * @param {number} max - The maximum value that the input can have.
   * @param {number} min - The minimum value that the input can have.
   * @param name - The parameter `name` is a string that specifies the name of the input field that is
   * being changed. In this case, it is set to `'year'`.
   * @returns The function is not returning anything explicitly, but it may return early if the input
   * value is greater than the maximum or less than the minimum. In those cases, the function sets the
   * input value to the maximum or minimum value respectively and then returns.
   */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    max: number,
    min: number,
    name: 'year'
  ) => {
    const value = parseInt(e.target.value, 10)
    if (value > max) {
      methods.setValue(name, max.toString())
      return
    } else if (value < min) {
      methods.setValue(name, min.toString())
      return
    }
    methods.setValue(name, value.toString())
  }

  const handlePurchasePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: 'purchasePrice'
  ) => {
    const { value } = e.target
    const numericValue = value.replace(/\D/g, '') // remove non-numeric characters
    const formattedValue = formatFinanceAmount(numericValue)
    methods.setValue(name, formattedValue)
  }

  const baseStyles = appName === 'audifs' ? audiBaseStyles : vwBaseStyles
  const baseTheme = appName === 'audifs' ? audifsTheme : vwfsTheme

  return (
    <FormProvider {...methods}>
      <Stack spacing={2}>
        <SelectInput
          label="What is the condition of the car?"
          data-testid="about-vehicle-condition-input"
          id="vehicle-condition"
          defaultValue={vehicleConditions.find(
            (option) => option.value === persistedProgress?.progress?.[1]?.condition
          )}
          placeholder={'Select condition'}
          name="condition"
          options={vehicleConditions}
        />
        <TextInput
          data-testid="about-vehicle-year-input"
          defaultValue={aboutVehicle?.year ?? ''}
          label="Year"
          name="year"
          type="number"
          placeholder={'Enter year'}
          onChange={(e) =>
            handleInputChange(
              e,
              new Date().getFullYear() + 1,
              // we can change the minimum year here, for now i'll leave it as 0
              0,
              'year'
            )
          }
          isDisabled={!watchCondition.value || isStepLoading}
          customError={methods.formState.errors.year?.message as string}
        />
        <FormLabel id="make">Make</FormLabel>
        <Controller
          name="make"
          control={methods.control}
          render={({ field }) => (
            <AsyncSelect
              {...field}
              placeholder={'Select Make'}
              data-testid="about-vehicle-make-input"
              name="make"
              aria-labelledby="make"
              defaultOptions={allVehicleMakes}
              loadOptions={promiseMakeOptions}
              isLoading={loadingMake}
              isDisabled={validYear || isStepLoading}
              styles={{
                control: (styles) => ({
                  ...styles,
                  ...baseStyles,
                  height: '40px',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                })
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  ...baseTheme
                }
              })}
              cacheOptions={false}
            />
          )}
        />
        <FormLabel id="model">Model</FormLabel>
        <Controller
          name="model"
          control={methods.control}
          render={({ field }) => (
            <AsyncSelect
              {...field}
              data-testid="about-vehicle-model-input"
              placeholder={'Select model'}
              name="model"
              aria-labelledby="model"
              defaultOptions={allVehicleModels}
              loadOptions={promiseModelOptions}
              isDisabled={watchYear.length < 4 || !watchMake || isStepLoading}
              styles={{
                control: (styles) => ({
                  ...styles,
                  ...baseStyles,
                  height: '40px',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                })
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  ...baseTheme
                }
              })}
            />
          )}
        />
        <TextInput
          data-testid="about-vehicle-purchase-price-input"
          leftIcon={<Text>R</Text>}
          label="Purchase price (R)"
          name="purchasePrice"
          placeholder={'Enter amount'}
          isDisabled={watchYear.length < 4 || !watchModel || isStepLoading}
          onChange={(e) => handlePurchasePriceChange(e, 'purchasePrice')}
        />
        <Flex alignItems="center">
          <FormLabel data-testid="about-vehicle-initiation-fee-label">
            How would you like to pay the initiation fee of
          </FormLabel>
          <Text
            data-testid="about-vehicle-initiation-fee-description"
            fontWeight={700}
            padding={0}
            m={0}
          >
            R 1207.50?
          </Text>
        </Flex>
        <VWRadioGroup
          currentValue={String(paymentMode)}
          setValue={(value) => {
            setPaymentMode?.(value as PaymentType)
          }}
          values={paymentTypeValues}
          isDisabled={isStepLoading}
        />
        <Text data-testid="about-vehicle-error-message" color={'red'}>
          {mmCodeError}
        </Text>
        {currentStep?.() === 1 ? (
          <Stack spacing={6}>
            <StepsNavigation
              primaryActionId="submit-vehicle-details-button"
              isLoading={isStepLoading}
              secondaryAction={() => {
                // Do nothing
              }}
              primaryAction={async () => {
                if (methods.formState.errors?.purchasePrice) return false
                await methods.handleSubmit(submit)()
                return false
              }}
            />
          </Stack>
        ) : (
          <Button
            my={6}
            data-testid="expense-calculator-submit-button"
            size="lg"
            onClick={async () => {
              await methods.handleSubmit(submit)()
              onClose?.()
            }}
          >
            Save changes
          </Button>
        )}
      </Stack>
    </FormProvider>
  )
}

export default TellUsForm
