import { Link, Stack, Text } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

function TermsAndConditionsBody(): React.ReactElement {
  const subHeadAndParagraph = (
    heading: string,
    paragraph1: string,
    paragraph2?: string,
    paragraph3?: string,
    paragraph4?: string,
    paragraph5?: string,
    paragraph6?: string
  ): ReactElement => {
    return (
      <>
        <Text textStyle="h5">{heading}</Text>
        <Text textStyle="p">{paragraph1}</Text>
        {paragraph2 && <Text textStyle="p">{paragraph2}</Text>}
        {paragraph3 && <Text textStyle="p">{paragraph3}</Text>}
        {paragraph4 && <Text textStyle="p">{paragraph4}</Text>}
        {paragraph5 && <Text textStyle="p">{paragraph5}</Text>}
        {paragraph6 && <Text textStyle="p">{paragraph6}</Text>}
      </>
    )
  }

  //Paragraphs are written in this way to speed up development because if
  //these parahgraphs were put directly into Text components, we would have
  //to append every apostrophe and quote. This method also provides neat
  //segmentation
  const openingParagraph = subHeadAndParagraph(
    '',
    `Volkswagen Financial Services South Africa (Pty) Ltd (“VWFS SA”) Online Services is brought to you by Volkswagen 
    Financial Services ("us", "we", "the Service Provider and Credit Provider"), a Registered Credit Provider NCRCP6635`,
    `These terms and conditions as amended from time to time ("the Terms") govern our respective 
    rights and obligations when you use Volkswagen Financial Services Online Services and come into 
    effect when you register for Volkswagen Financial Services Online Services.`,
    `By making use of Volkswagen Financial Services Online Services, you acknowledge that you have 
    read, understood and agree to the most recent version of the Terms, and that you have read, understood 
    and agree to our Privacy Policy and consent to our collection, processing, storage and sharing certain 
    of your personal information within the Bank in the ordinary course of our business of providing Volkswagen 
    Financial Services Online Services to you.`,
    `No term or provision contained in these Terms is to be interpreted or construed so as to exclude any rights 
    granted by Chapter VII of the Electronic Communications and Transactions Act 25 of 2002 to any natural person
     who enters or intends entering into an electronic 
    transaction with us on this website.`
  )
  const productAgreements = subHeadAndParagraph(
    'Product agreements',
    `All products and services provided by Volkswagen Financial Services are subject to their own terms and 
    conditions ("product agreements") such as your Instalment sale, Lease or Rental agreements terms and conditions, 
    as well as account or facility terms and conditions ("other terms") and your use of those products and services 
    will also be subject to those additional Volkswagen Financial Services terms.`,
    `These Terms must be read together with and form part of each credit agreement. In the event of conflict between 
    these Terms and Volkswagen Financial Services terms, the provisions of the Volkswagen Financial Services terms or 
    other terms will prevail to the extent of the conflict.`
  )

  const amendmentToTerms = subHeadAndParagraph(
    'Amendments to these Terms',
    `We reserve the right, in our sole discretion to amend (including without limitation, by the addition of new terms and 
  conditions) these Terms at any time and from time to time, without prior notification to you. Any such amendments shall 
  come into effect immediately and automatically. You undertake to review these Terms prior to using Volkswagen Financial 
  Services Online Services for any such changes.`,
    `For convenience only, the date on which these Terms were last amended is shown below the main heading of these Terms.`
  )

  const accessCodes = subHeadAndParagraph(
    'Access codes',
    `For security, identification and verification purposes, when using Volkswagen Financial Services Online Services, you 
   will make use of a, One Time Pin ("OTP"), Personal Identification Number ("PIN"), account number, User Identification 
   ("User ID") and Password. These are all called your "access codes".`,
    `You are responsible for maintaining the confidentiality and secrecy of your access codes. Only you may use your access 
    codes. Do not keep your access codes together with other Volkswagen Financial Services Online Services documents.`,
    `If you call Volkswagen Financial Services Online Services helpline, we may, for the purposes of identification and 
    verification, request certain information such as your account number or identity number. However, there will never be 
    a reason for Volkswagen Financial Services Online Services helpline or any of our staff, to request or know your Password, 
    User ID or PIN.`,
    `If you believe your access codes have been compromised, you must immediately contact Volkswagen Financial Services Online 
    Services helpline on 0861 500 400 and request that they deactivate your access codes or you can reset your access codes on 
    the website with your User ID and OTP. You must keep any reference number given to you by Volkswagen Financial Services 
    Online Services helpline as verification of your notification to us of any such compromise.`,
    `Until you notify us to deactivate your access codes we will not be held responsible or liable for any transactions that 
    are performed without your knowledge or consent and you hereby indemnify us from any loss and damages you suffer as a result.`
  )

  const fraudlentActivities = subHeadAndParagraph(
    'Fraudulent activities',
    `We are committed to ensuring the security of your use of Volkswagen Financial Services Online Services and are entitled to 
    investigate any loss suffered by you which is alleged to have occurred as a result of fraud via the website.`,
    `Where you have been a victim of a fraudulent transaction on Volkswagen Financial Services Online Services, you must inform 
    us as soon as you become aware that a suspicious transaction has occurred on your accounts. You must also open a case at 
    your nearest South African Police Service office. You will be required to co-operate with us and the South African Police 
    Service in any investigation conducted into losses that you suffered as a result of such Volkswagen Financial Services Online 
    Services fraud. Only if you have complied with the safety measures described in our Security Centre, will we reimburse you 
    after conclusion of the investigation for any actual losses suffered due to such Volkswagen Financial Services Online Services 
    fraud. This is your sole remedy and Volkswagen Financial Services ‘s only obligation and liability, with respect to losses 
    suffered as a result of such Volkswagen Financial Services Online Services fraud.`
  )

  const registrationToOnlineServices = subHeadAndParagraph(
    'Registration to Online Services',
    `Visit the homepage of the website, click on the "Register" button and proceed to register for Volkswagen Financial Services 
    Online Services. You will not be able to transact until such time as your details have been validated by Volkswagen 
    Financial Services. For more information visit our website or call the Volkswagen Financial Services Online Services 
    on 0861 500 400.`
  )

  const useOfSoftwareAndHardware = subHeadAndParagraph(
    'Use of software and hardware',
    `You should only use the most up-to-date Internet browser to access the website and our online services, provided that where 
    we specify hardware or software requirements on the website, only such specified hardware of software may be used. See our site 
    settings requirements for more information. Your failure to do this may result in a security risk to you and/or cause some or 
    all of the functionality of the website or online service not to operate properly or at all.`,
    `Software, if any, made available for download on or via the website may be governed by licence conditions that establish a 
    legal relationship with the licensor. You are solely responsible for identifying and familiarising yourself with and agreeing 
    such terms and conditions which will govern your relationship with such third-party licensor. You agree that we shall not be 
    responsible or liable for any damage, damages or loss caused or alleged to be caused by or in connection with your interaction, 
    correspondence or business dealings with such third-party licensor and further, you indemnify us against any breach by you of 
    these licence conditions. We give no warranty and make no representation, whether expressly or implied, as to the quality or 
    fitness for purpose of such software. You acknowledge that such third party is not our agent.`
  )

  const fees = subHeadAndParagraph(
    'Fees',
    `The use of Volkswagen Financial Services Online Services is free of charge.
  Your instructions
  `,
    `Because we communicate through the Internet, you agree that we are entitled to assume that you have authorised all transactions 
    once your access codes have been entered. This will not apply once we receive notification from you to disable access to your 
    accounts as a result of your access codes having been misplaced or lost and you have a reference number as verification of such 
    notification. Our records of your transactions will be proof of any instruction you have given us, unless you can prove the contrary.`,
    `You are responsible for giving correct information and instructions when registering for Volkswagen Financial Services Online Services 
    and when conducting transactions on the website.`,
    `We provide you with an opportunity to review the entire transaction, to correct any mistakes and to withdraw from the transaction, 
    before finally submitting any transaction. We will try to assist if you make a mistake when giving us an instruction, but we will not 
    be responsible for any loss or damage caused by your error. We will also not be liable for payments made by you to unintended 
    recipients due to the input of incorrect information, nor are we responsible for the verification of the identity of recipients. We 
    cannot reverse or duplicate erroneous payments you make to other accounts without the specific consent of the accountholder. We will 
    not be liable for any act or omission on the part of any institution where such other account is held.`,
    `You hereby acknowledge that certain transactions once performed, may not be reversed or terminated, such as the purchase of pre-paid 
    products made available via Volkswagen Financial Services Online Services.`,
    `Unless otherwise stated in a product agreement, all transactions will be subject to the same turnaround times that apply to those 
    transactions, accounts and customer profile, if any, if done at a Volkswagen Financial Services branch.`
  )

  const endingASession = subHeadAndParagraph(
    'Ending a session',
    `You must log off the website once you have finished using 
  Volkswagen Financial Services Online Services. If you do not do this, unauthorised transactions may result, for which we will 
  not be liable.`,
    `We will however, terminate your use of and access to and/or prohibit your future access to use of Volkswagen Financial Services 
  Online Services immediately and without any notice to you if:`,
    `• we determine in the exercise of our discretion that your behaviour was inappropriate, illegal or constitutes misconduct.`,
    `• you breached these Terms.`,
    `• a fraudulent transaction is conducted (or attempted) directly or indirectly by you.`,
    `In the event of us terminating your access to Volkswagen Financial Services Online Services, we will not be liable for any 
    losses or damages, of any nature, suffered by you or a third party. Our rights in this regard are expressly reserved.`
  )

  const endingUseOfOnlineServices = subHeadAndParagraph(
    'Ending your use of Online Services',
    `We may end your use of and access
   to Volkswagen Financial Services Online Services at any time, for any reason whatsoever, on notice to you. Instructions given
    to us prior to such termination will not be affected by such termination.`
  )

  const applicationForProductsAndServices = subHeadAndParagraph(
    'Application for products and services via this website',
    `Any application submitted for products and services via this website does not constitute a guarantee of what is being applied for 
    will be granted.`,
    `An auto-response to your application may not be construed as formal approval of your application. It is only once we expressly 
    notify you of approval that you will have been approved for the particular product or service for which you applied.`
  )

  const privacy = subHeadAndParagraph(
    'Privacy',
    `We respect your privacy and your personal information. For this reason, we have a Privacy Policy (the terms of which are 
      incorporated into these Terms) to let you know how we will treat any personal information that we may collect, collate, 
      process, disclose or store about you. We will take all reasonable measures, in accordance with our Privacy Policy, to 
      protect your personal information and to keep it confidential, even when you are no longer our customer.`,
    `If you call our helpline you permit and hereby consent to us recording your calls for security reasons, and for the purposes 
      of customer care.`
  )

  const security = subHeadAndParagraph(
    'Security',
    `Information that is transmitted over the Internet or via other networks (wireless or otherwise) may be subject to interception. 
    While we will take all reasonable precautions to ensure that Volkswagen Financial Services Online Services is secure, we shall 
    not be liable for any loss or damage you suffer as a result of your use of Volkswagen Financial Services Online Services.`,
    `You acknowledge that you have read and understood the security tips in the Security Centre which we publish on the website 
    and that you shall take the precautions mentioned therein. Please visit our Security Centre for more information.`
  )

  const monitoringOfCommunication = subHeadAndParagraph(
    'Monitoring of communications',
    `You expressly give your consent for us to 
  monitor your internet and e-mail traffic on our website. You acknowledge that we monitor internet and e-mail traffic on the website 
  primarily to ensure that users and consumers are not acting illegally, unlawfully or in breach of these Terms and:`,
    `• to maintain the integrity and security of our website and information technology systems;`,
    `• to investigate and detect any unauthorised use of our website and information technology systems; and`,
    `• as an inherent part of and to secure the effective operation of our website and information technology systems.`
  )

  const noWarranties = subHeadAndParagraph(
    'No Warranties',
    `To the fullest extent permissible by law, no warranties, whether express 
  or implied, are made of any kind in respect of Volkswagen Financial Services Online Services including in respect of the performance, 
  quality, security, content, information, availability, accuracy, safety or reliability of Volkswagen Financial Services Online Services.`
  )

  const limitatioOfLiablity = subHeadAndParagraph(
    'Limitation of liability',
    `Although we have taken reasonable care to prevent harm or loss to you, you agree that we and our affiliates, shareholders, 
    agents, consultants or employees (in whose favour this constitutes a stipulation for the benefit of another) are not liable 
    for any direct, indirect, special, incidental or consequential damages or loss of any kind whatsoever or howsoever caused 
    (whether arising under contract, delict or otherwise and whether the loss was actually foreseen or reasonably foreseeable)
     arising as a result of your use of or inability to use Volkswagen Financial Services Online Services unless such loss or 
     damage arises from our gross negligence or wilful intent.
  You furthermore indemnify us against any damage, damages, liability, claims or demand by third parties or loss suffered by us 
  arising from your use of Volkswagen Financial Services Online Services or your breach of these Terms.
  `
  )

  const linksToThirdPartyWebsites = subHeadAndParagraph(
    'Links to third party websites',
    `For your convenience, this website may contain links to other websites belonging to or operated by third parties 
    ("third party websites"). By making the hyperlinks available we are not endorsing third party websites, their content, 
    products or services they offer or the owners of these third-party websites. It is your responsibility to ensure that you 
    obtain all information relevant to making a decision and that you read the privacy and security policy on such third-party 
    websites. We have no control over such third-party websites and will not be liable for any loss or damage you suffer, whether 
    directly or indirectly, as a result of your use of third-party websites. You agree that where you access third party websites, 
    you do so entirely at your own risk.`
  )

  const usingContentFromWebsite = subHeadAndParagraph(
    'Using content from our website',
    `The content on Volkswagen Financial Services Online Services, including all registered and unregistered trademarks, 
    constitutes our intellectual property rights. You may not copy, reproduce, display or use any intellectual property in 
    any manner whatsoever without our prior written permission and nothing contained on this website should be construed as 
    granting any licence or right of use of any intellectual property.`,
    `You may not establish a hyperlink, frame, meta tag or similar reference, whether electronically or otherwise ("linking") 
    to this website or any other subsidiary pages without our prior written consent, which consent is at our sole discretion. 
    You may apply to establish such a link by submitting your request to service@vwfs.co.za. In the event that you have not 
    heard from us within 5 (five) working days, please consider your request as having been rejected.`
  )

  const noticesConfirmationStatements = subHeadAndParagraph(
    'Notices, confirmation and statements',
    `You agree that our 
  publishing a notice on our website or sending you a notice once you log into Volkswagen Financial Services`,
    `Online Services, e-mail, SMS or post will constitute sufficient notice to you.`,
    `Should you fail to raise an objection with us within 5 (five) hours after we sent you a notice (including by SMS) 
    confirming a transaction or the changing of any setting on your accounts, you will be deemed to have accepted the 
    transactions or new setting as being valid and binding in all respects.`
  )

  const definitionOfBusinessHours = subHeadAndParagraph(
    'Definition of Business Hours',
    `Whenever a reference is made to hours, 
  the following applies:`,
    `Business Hours (Weekdays): 08h00 - 16h00`,
    `Business Hours (Saturdays): 09h00 - 12h00`,
    `After Hours: All other times`
  )

  const modificationSuspentionTerminationOfOnlineServices = subHeadAndParagraph(
    'Modification, suspension or termination of Online Services',
    `We may discontinue providing Volkswagen Financial Services Online Services or any of the products or services offered 
    via the website at any time at our sole discretion. We will however, notify you of this within a reasonable time of 
    these changes being made.`
  )

  const submittingAComplaint = subHeadAndParagraph(
    'Submitting a complaint to us',
    `If you wish to lodge a complaint, please notify us in writing of this by completing our enquiry form available on our 
    website at https://www.vwfs.co.za/contact-us.html.We will provide you with a reference number as soon as possible after
     we receive your enquiry and logged your complaint.`,
    `We will respond to your complaint as soon as possible. If you do not hear from us within 5 (five) working days from 
     sending your complaint, we request that you contact us at service@vwfs.co.za and 0861 500 400 to check whether your 
     complaint has been received. `,
    `Please quote your reference number when you contact us directly.`
  )

  const disputesWhichCannotBeResolved = subHeadAndParagraph(
    'Disputes which cannot be resolved',
    `If we cannot resolve your complaint within a reasonable time you are free to lodge a formal complaint at the office of 
    the Ombudsman, provided that your complaint falls within the jurisdiction of the selected Ombudsman.`,
    `NOTE: You have the right to: complain about this credit agreement to us directly (as noted above “Submitting a complaint 
      to us”); refer a dispute to an Ombud with jurisdiction; file a complaint with the National Credit Regulator; or apply to 
      the National Consumer Tribunal as allowed in the NCA.`,
    `The contact details to file complaints / disputes via telephone or e-mail address are:`,
    `• The National Credit Regulator: on 0860 627 627; www.ncr.org.za`,
    `• The National Consumer Tribunal: on 012 294 1450; www.thenct.org.za`,
    `• The Ombud for Financial Services Providers: on 012 762 5000 or 012 470 9080; `
  )

  const adressForLegalNotice = subHeadAndParagraph(
    'Address for legal notices',
    `All legal notices relating to products must be delivered as provided for in the product agreements. Where such address is 
    not specified, such notices must be sent to our Registered Address (see Contact Volkswagen Financial Services). We may send 
    you legal notices to any of the addresses you have specified on any of your application/registration forms with us or at 
    the address you actually work or live.`
  )

  const lawOfGoverningRelationShip = subHeadAndParagraph(
    'The law governing our relationship',
    `We both agree that these Terms shall be governed by and construed and interpreted in accordance with the laws of the 
    Republic of South Africa notwithstanding the fact that an instruction may have emanated from outside the Republic of 
    South Africa. Accessing Volkswagen Financial Services Online Services from another country may be an offence in that 
    country. You agree that use of Volkswagen Financial Services Online Services outside South Africa will be at your own risk.`
  )

  const capacity = subHeadAndParagraph(
    'Capacity',
    `In using the website and Volkswagen Financial Services Online Services you represent and warrant that you are of full 
    legal age, are emancipated or have your guardian's consent and assistance and have the required legal capacity to enter 
    into and be bound by these Terms or any other product agreement regarding your use of the website and Volkswagen Financial 
    Services Online Services.`
  )

  const general = subHeadAndParagraph(
    'General',
    `The headings appearing in these Terms are inserted for convenience only and will not be taken into account when 
    interpreting these Terms.`,
    `Where dates and times need to be calculated in terms of these Terms, the international standard time (GMT) plus 2 (two) hours will be used.`,
    `The 'cooling-off' rights, as set out in section 44 of the Electronic Communications and Transactions Act 2002, do not apply to any 
    financial services or transactions conducted by way of Volkswagen Financial Services Online Services.`,
    `The indulgence, extension of time, waiver or relaxation of any of the provisions or terms of these Terms, or failure or delay on our 
    part to exercise any of our rights will not operate as an estoppel against us nor shall it constitute a waiver by us of such right. 
    We shall not thereby be prejudiced or stopped from exercising any of our rights against you which may have arisen in the past or 
    which might arise in the future.`,
    `A certificate issued by our website administrator will serve as proof of which version of these Terms as applied to your use of Volkswagen 
    Financial Services Online Services at a specific date.`,
    `Any provision in these Terms which is or may become illegal, invalid or unenforceable shall be ineffective to the extent of such prohibition 
    or unenforceability and shall be treated as if it were not written and severed from these Terms, without invalidating the remaining provisions 
    of these Terms.`
  )
  return (
    <Stack flexDirection="column" padding={4}>
      <Text textStyle="h4" data-testid="t&c-heading">
        Terms And Conditions
      </Text>
      <Text pb="2rem">Last Updated: 21 January 2019</Text>
      <Text textStyle="p">{openingParagraph}</Text>
      {productAgreements}
      {amendmentToTerms}
      {accessCodes}
      {fraudlentActivities}
      {registrationToOnlineServices}
      {useOfSoftwareAndHardware}
      {fees}
      <Text textStyle="p">{`An instruction will only be deemed to have been received by us once we have confirmed receipt, or responded thereto, whichever 
    is the earlier. If you are unsure as to whether a transaction has been processed you should contact us before you re-submit the 
    instruction. Re-submitting an instruction may cause us to process the same transaction twice, for which we will not be liable.`}</Text>

      {endingASession}
      <Text textStyle={'p'}>
        {`You may terminate your use of Volkswagen Financial Services Online Services by contacting the Volkswagen Financial Services Online 
    Services helpline on 0861 500 400.`}
      </Text>
      {endingUseOfOnlineServices}
      {applicationForProductsAndServices}
      {privacy}
      {security}
      {monitoringOfCommunication}
      {noWarranties}
      {limitatioOfLiablity}
      {linksToThirdPartyWebsites}
      {usingContentFromWebsite}
      {noticesConfirmationStatements}
      {definitionOfBusinessHours}
      {modificationSuspentionTerminationOfOnlineServices}
      {submittingAComplaint}
      {disputesWhichCannotBeResolved}
      <Text textStyle="p">{`www.faisombud.co.za`}</Text>
      {adressForLegalNotice}
      {lawOfGoverningRelationShip}
      {capacity}
      {general}
      {
        <Link
          color="brand.100"
          href="https://www.vwfs.co.za/content/dam/bluelabel/valid/www-vwfs-co-za/documents/VWFS%20Customer%20Privacy%20Policy%202021%20.pdf"
        >
          Privacy Policy
        </Link>
      }
    </Stack>
  )
}

export default TermsAndConditionsBody
