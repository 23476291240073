import { FormLabel, HStack, Input, InputGroup, Text } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import { StepsNavigation } from '../../../containers/ApplicationSection'
import { useAuth } from '../../../context/AuthProvider'
import { usePreApprovalSteps } from '../../../context/PreApprovalStepsProvider'
import { useData } from '../../../context/UserDataProvider'
import { FinanceApplicationStatus, Suburb, useAllSuburbsLazyQuery } from '../../../generated'

import { padDigit } from '../../../utils'
import { addressSchema } from '../../../utils/schemas/address'
import { SelectInput, TextInput } from '../../FormElements'
import {
  audiBaseStyles,
  audifsTheme,
  vwBaseStyles,
  vwfsTheme
} from '../../FormElements/SelectInput'
import dayjs from 'dayjs'

const RESIDENTIAL_TYPE = [
  {
    value: 'owner',
    label: 'Owner'
  },
  {
    value: 'tenant',
    label: 'Tenant'
  },
  {
    value: 'lodger',
    label: 'Lodger'
  }
]

function AddressForm(): React.ReactElement {
  const { user, appName } = useAuth()
  const baseStyles = appName === 'audifs' ? audiBaseStyles : vwBaseStyles
  const baseTheme = appName === 'audifs' ? audifsTheme : vwfsTheme
  const [suburbs, setSuburbs] = React.useState<Array<Suburb>>([])
  const [allSuburbs] = useAllSuburbsLazyQuery({
    onCompleted: (res) => {
      setSuburbs(res.allSuburbs as Suburb[])
    }
  })

  const getSavedResidentialType = (residentialType: typeOfResidentialAddress) => {
    return RESIDENTIAL_TYPE.find((type) => type.value === residentialType)
  }

  type typeOfResidentialAddress = 'owner' | 'tenant' | 'lodger'

  const [error, setError] = React.useState<string>()

  const { saveUserAddress, persistProgress, persistedProgress } = useData()
  const { next, setIsStepLoading, isStepLoading, currentStep } = usePreApprovalSteps()

  React.useEffect(() => {
    allSuburbs()
  }, [])

  const savedAddress = persistedProgress?.progress[currentStep?.() ?? 2]
  const addressDefaultValues = React.useMemo(() => {
    return {
      addressLine1: savedAddress?.addressLine1 ?? '',
      addressLine2: savedAddress?.addressLine2 ?? '',
      suburb: savedAddress?.suburb && {
        label: savedAddress?.suburb,
        value: savedAddress?.postalCode
      },
      residentialStatus: getSavedResidentialType(savedAddress?.residentialStatus),
      timeOfLivingYears: savedAddress?.timeOfLivingYears,
      timeOfLivingMonths: savedAddress?.timeOfLivingMonths,
      userId: savedAddress?.userId ?? ''
    }
  }, [savedAddress])

  const methods = useForm({
    defaultValues: addressDefaultValues as any,
    resolver: zodResolver(addressSchema),
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true // input errors will be retained with value update
    }
  })

  const filterSuburbs = async (inputValue: string) => {
    await allSuburbs({
      variables: {
        filter: {
          searchTerm: inputValue
        }
      }
    })

    return suburbs
  }

  const promiseSuburbOptions = (inputValue: string) =>
    Promise.resolve<Suburb[]>(filterSuburbs(inputValue))

  /**
   * The function checks if the time of living fields are valid and returns a boolean value.
   * @returns a boolean value, which is the result of the comparison between the timeOfLivingMonths and
   * timeOfLivingYears values obtained from the methods.getValues() function. If at least one of these
   * values is greater than 0, the function returns true, otherwise it returns false.
   */
  const checkTimeFieldsAreValid = (): boolean => {
    const validInputs =
      methods.getValues().timeOfLivingMonths > 0 || methods.getValues().timeOfLivingYears > 0
    if (!validInputs) {
      setError('Please add a valid time of living, both fields cannot be 0')
    }
    return validInputs
  }
  const yearsAndMonthsAreValid = (): boolean => {
    const months = methods.getValues().timeOfLivingMonths.toString()
    const year = methods.getValues().timeOfLivingYears.toString()

    return parseInt(months) > 0 || parseInt(year) > 0
  }

  const onSubmit = async (): Promise<void> => {
    setError('')

    const timeIsValid = checkTimeFieldsAreValid() //For some reason time check isn't workingx
    if (!timeIsValid) {
      return
    }

    if (!yearsAndMonthsAreValid()) {
      setError('Please add a valid time of living, both fields cannot be 0')
      return
    }

    setIsStepLoading?.(true)
    const addressData = methods.getValues()
    addressData.userId = undefined
    addressData.timeOfLivingMonths = parseInt(`${methods.getValues('timeOfLivingMonths')}`)
    addressData.timeOfLivingYears = parseInt(`${methods.getValues('timeOfLivingYears')}`)
    const todayDate = dayjs(`${new Date()}`).format('DD/MM/YYYY')

    try {
      if (saveUserAddress && user) {
        if (
          await saveUserAddress({
            ...addressData,
            suburb: addressData.suburb?.label,
            postalCode: padDigit(Number(addressData.suburb?.value), 4),
            residentialStatus: addressData.residentialStatus?.value as string
          })
        ) {
          await persistProgress?.({
            id: persistedProgress?.id as string,
            isCompleted: false,
            status: FinanceApplicationStatus.Pending,
            progress: {
              ...persistedProgress?.progress,
              currentStep: currentStep?.() && currentStep?.() + 1,
              [`${currentStep?.()}`]: {
                ...addressData,
                suburb: addressData.suburb?.label,
                postalCode: padDigit(Number(addressData.suburb?.value), 4),
                residentialStatus: addressData.residentialStatus?.value as string,
                addressPagecompletedAt: todayDate
              }
            }
          })
          next?.()
        }
      }
    } catch (e) {
      setError(`${e}`)
    }
    setIsStepLoading?.(false)
  }

  /**
   * This function handles input change for a specific input field, ensuring the value is within a given
   * range.
   * @param e - React.ChangeEvent<HTMLInputElement> - This is the event object that is triggered when the
   * input field value changes.
   * @param {number} max - The maximum value that the input can have.
   * @param {number} min - The minimum value that the input can have.
   * @param {'timeOfLivingMonths' | 'timeOfLivingYears'} name - The `name` parameter is a string that
   * specifies the name of the input field that is being changed. It can only be one of two values:
   * `'timeOfLivingMonths'` or `'timeOfLivingYears'`. This parameter is used to set the value of the
   * corresponding input field in the
   * @returns The function does not return anything explicitly, but it may return early if the input
   * value is outside the specified range.
   */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    max: number,
    min: number,
    name: 'timeOfLivingMonths' | 'timeOfLivingYears'
  ) => {
    const value = parseInt(e.target.value, 10)
    if (value > max) {
      methods.setValue(name, max)
      return
    } else if (value < min) {
      methods.setValue(name, min)
      return
    }
    methods.setValue(name, value)
  }

  return (
    <FormProvider {...methods}>
      <TextInput
        data-testid="address-line-1-input"
        defaultValue={savedAddress?.addressLine1}
        label="Address line 1"
        name="addressLine1"
        type="text"
        lineHeight={'1rem'}
        isDisabled={isStepLoading}
      />

      <TextInput
        data-testid="address-line-2-input"
        defaultValue={savedAddress?.addressLine2}
        label="Address line 2"
        name="addressLine2"
        type="text"
        isDisabled={isStepLoading}
      />

      <FormLabel id="suburb" data-testid="address-suburb-label" padding={0} margin={0}>
        Suburb
      </FormLabel>
      <Controller
        name="suburb"
        control={methods.control}
        render={({ field }) => (
          <>
            <AsyncSelect
              {...field}
              data-testid="address-suburb-select"
              placeholder={'Select suburb'}
              name="suburb"
              aria-labelledby="suburb"
              defaultOptions={suburbs}
              loadOptions={promiseSuburbOptions}
              styles={{
                control: (styles) => ({
                  ...styles,
                  ...baseStyles,
                  height: '40px',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                })
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  ...baseTheme
                }
              })}
            />
            {methods.formState.errors.suburb?.message && (
              <Text data-testid="select-suburb-error" color="red.500" fontSize="14px">
                {methods.formState.errors.suburb?.message as string}
              </Text>
            )}
          </>
        )}
      />

      {/* Since the surbub input comes with postal codes, this input will be prefilled and disabled */}
      <FormLabel id="postalCode" data-testid="address-postal-code-label" padding={0} margin={0}>
        Postal code
      </FormLabel>
      <InputGroup>
        <Input
          data-testid="address-postal-code-input"
          placeholder="Select suburb to get postal code"
          value={padDigit(Number(methods.watch('suburb')?.value ?? '0'), 4)}
          fontWeight={300}
          isDisabled
          borderRadius={appName === 'vwfs' ? '8px' : '0'}
          defaultValue={savedAddress?.postalCode}
          aria-labelledby="postalCode"
        />
      </InputGroup>

      <SelectInput
        label="Your residential status"
        data-testid="address-residential-status-select"
        defaultValue={getSavedResidentialType(savedAddress?.residentialStatus)}
        placeholder="Please select"
        name="residentialStatus"
        options={RESIDENTIAL_TYPE}
        isDisabled={isStepLoading}
      />
      <FormLabel data-testid="address-timeOfLivingYears-label">
        How long have you lived here?
      </FormLabel>
      <HStack pb="0.8rem">
        <TextInput
          data-testid="address-timeOfLivingYears-input"
          defaultValue={savedAddress?.timeOfLivingYears}
          label="Years"
          name="timeOfLivingYears"
          type="number"
          isDisabled={isStepLoading}
          onChange={(e) => handleInputChange(e, 100, 0, 'timeOfLivingYears')}
        />
        <TextInput
          data-testid="address-timeOfLivingMonths-input"
          defaultValue={savedAddress?.timeOfLivingMonths}
          label="Months"
          name="timeOfLivingMonths"
          type="number"
          max={11}
          isDisabled={isStepLoading}
          onChange={(e) => handleInputChange(e, 11, 0, 'timeOfLivingMonths')}
        />
      </HStack>
      <Text data-testid="address-error-message" align="center" color={'red.400'}>
        {error}
      </Text>
      <StepsNavigation
        primaryActionId="submit-income-details"
        isLoading={isStepLoading}
        secondaryAction={() => {
          // do nothing
        }}
        primaryAction={async () => {
          await methods.handleSubmit(onSubmit)()
          return false
        }}
      />
    </FormProvider>
  )
}

export default AddressForm
