import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { omit, round, sum, sumBy, toNumber } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Products, SelectedProductRow, StepsHeader, UploadDocumentsButton } from '../../components'
import DataInfoCard from '../../components/DataInfoCard'
import InfoRowCard from '../../components/InfoRowCard'
import VWModal from '../../components/Modal'
import RemoveVAPsModal from '../../components/Modal/RemoveVAPsModal'
import { SelectedProduct } from '../../components/Products'
import { useAppContext } from '../../context/AppProvider'
import { useAuth } from '../../context/AuthProvider'
import { useData } from '../../context/UserDataProvider'
import { Product, useSaveUserProductsMutation } from '../../generated'
import { calculateProductPrice, IN_PROGRESS_FINANCE_APPLICATION_KEY } from '../../utils'
import formatFinanceAmount from '../../utils/math/format/formatFinanceAmount'
import { FinanceDetails } from '../../utils/types'

// Todo: delete this object after development
const xFinanceDetails = {
  amountToBeFinanced: '700500',
  monthlyInstallment: '10000',
  deposit: '250500',
  term: '24',
  balloonPerc: '12',
  initiationFee: '2783'
}

function ApprovalScreen(): React.ReactElement {
  const navigate = useNavigate()
  const { financeData, setPersistedProgress, persistedProgress } = useData()
  const { aboutVehicle } = useAppContext()
  const { user, appName, baseURL } = useAuth()
  const vafResponse = JSON.parse(financeData ? financeData : JSON.stringify({}))
  // Todo: delete this operator after development
  const financeDetails: FinanceDetails = vafResponse.financeDetails ?? xFinanceDetails
  const referenceNumber = vafResponse?.financeIdentifiers?.referenceNumber ?? '#£'
  const savedVehicleData = persistedProgress?.progress?.[1]
  const vehicle = `${savedVehicleData?.make ?? aboutVehicle.make} ${savedVehicleData?.model ?? aboutVehicle.model}`
  const amountToFinance = financeDetails.amountToBeFinanced
  const monthlyInstallment = financeDetails.monthlyInstallment
  const deposit = financeDetails.deposit
  const paymentTerm = financeDetails.term
  const balloonPayment = financeDetails.balloonPerc
  const initialFee = financeDetails.initiationFee
  const [selectedProduct, setSelectedProduct] = React.useState<SelectedProduct>()
  const [removedProduct, setRemovedProduct] = React.useState<string>('')
  const {
    isOpen: isOpenRemoveModal,
    onOpen: onOpenRemoveModal,
    onClose: onCloseRemoveModal
  } = useDisclosure()

  const handleRemoveProduct = (category: string) => () => {
    setRemovedProduct(category)
    onOpenRemoveModal()
  }

  const handleSelectProduct = (category: string, product: Product) => () => {
    setSelectedProduct({ ...selectedProduct, [`${category}`]: product })
  }

  const removeSelectedProduct = (category: string) => () => {
    const newProducts = omit(selectedProduct, [category])
    setSelectedProduct(newProducts)
    onCloseRemoveModal()
  }

  React.useEffect(() => {
    setPersistedProgress?.(undefined)
    sessionStorage.removeItem(IN_PROGRESS_FINANCE_APPLICATION_KEY)
  })

  const toast = useToast()

  const [saveUserProducts, { loading: isSavingUserProducts }] = useSaveUserProductsMutation({
    onError: () => {
      toast({
        title: 'Error!',
        description:
          'We are currently experiencing a high volume of traffic. Kindly submit the products again. Thank you for your patience.',
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    },
    onCompleted: (res) => {
      toast({
        title: 'VAPs products submitted successfully!',
        description: res.saveUserProducts,
        status: 'success',
        duration: 9000,
        isClosable: true,
        colorScheme: 'primaryDark'
      })
      navigate(`${baseURL}auth/dashboard`)
      setSelectedProduct(undefined)
    }
  })

  const handleSubmitProducts = async () => {
    await saveUserProducts({
      variables: {
        input: {
          products: Object.values(selectedProduct ?? {}).map((product) => {
            return {
              id: product?.id as string,
              longName: product?.longName as string
            }
          }),
          dealReferenceNumber: referenceNumber,
          userId: user?.id as string,
          appName: appName as string
        }
      }
    })
  }

  const totalEstimatedCost = React.useMemo(() => {
    const vapsTotalCost = sumBy(Object.values(selectedProduct ?? {}), function (product) {
      return toNumber(product?.pricingValue) > 0
        ? round(toNumber(product?.pricingValue))
        : calculateProductPrice(amountToFinance, product?.pricingValue ?? '')
    })
    return sum([Number(monthlyInstallment), Number(vapsTotalCost)])
  }, [selectedProduct])

  return (
    <Flex flexDirection="column" padding="3%">
      <VWModal
        showFooter={false}
        onClose={onCloseRemoveModal}
        size="full"
        isOpen={isOpenRemoveModal}
        modalBody={
          <RemoveVAPsModal
            removeHandler={removeSelectedProduct(removedProduct)}
            onClose={onCloseRemoveModal}
            productName={selectedProduct?.[removedProduct]?.shortName ?? ''}
          />
        }
      >
        <Box />
      </VWModal>
      <Stack>
        <StepsHeader
          subfield={referenceNumber}
          subFieldId="deal-referenceNumber"
          title="Deal Reference Number"
          subtitle="Your deal has been approved, subject to the verification of your information"
        />
        <Flex flexWrap="wrap" justify={['normal', 'normal', 'space-between']} width="100%" py={2}>
          <DataInfoCard vehilcle={vehicle} width={['100%', '100%', '55%']} approval>
            <Flex flexWrap="wrap" justifyContent={['normal', 'normal', 'space-between']}>
              <Flex flexDirection="column" padding="16px" gap="4px" alignItems="flex-start">
                <InfoRowCard
                  label="Vehicle Make"
                  value={savedVehicleData?.make ?? aboutVehicle.make}
                />
                <InfoRowCard
                  label="Vehicle Model "
                  value={savedVehicleData?.model ?? aboutVehicle.model}
                />
                <InfoRowCard
                  label="Finance Amount"
                  value={`R ${formatFinanceAmount(amountToFinance.toString())}`}
                />
                <InfoRowCard
                  label="Monthly Installment"
                  value={`R ${formatFinanceAmount(monthlyInstallment.toString())}`}
                />
              </Flex>

              <Flex flexDirection="column" padding="16px" gap="4px" alignItems="flex-start">
                <InfoRowCard
                  label="Deposit"
                  value={`R ${formatFinanceAmount(deposit.toString())}`}
                />
                <InfoRowCard label="Payment Term" value={`${paymentTerm} Months`} />
                <InfoRowCard
                  label="Balloon Payment"
                  value={`R ${formatFinanceAmount(balloonPayment.toString())}`}
                />
                <InfoRowCard
                  label="Initial Fee"
                  value={`R ${formatFinanceAmount(initialFee.toString())}`}
                />
              </Flex>
              <Flex justifyContent="flex-end" flexDirection="column" padding={4}>
                <UploadDocumentsButton />
              </Flex>
            </Flex>
          </DataInfoCard>
          {Object.values(selectedProduct ?? {}).length > 0 && (
            <Card
              flexDirection="column"
              background="#FFFFFF"
              border="1px solid #E4E7EC"
              borderRadius="8px"
              width={['100%', '100%', '44%']}
              height={237}
              alignSelf="flex-start"
              shadow="none"
              marginY={[4, 4, 0]}
            >
              <Flex padding="16px 20px 16px 16px" height="64px" alignItems="center" gap="4px">
                <Text variant="label">Estimated Cost of Ownership</Text>
              </Flex>
              <Divider />
              <CardBody p={4} overflowY="scroll">
                <Flex direction="column">
                  <InfoRowCard
                    label="Monthly Installment"
                    value={`R ${formatFinanceAmount(monthlyInstallment.toString())}`}
                    labelWidth="300px"
                  />
                  {Object.keys(selectedProduct ?? {}).map((category) => {
                    const product = selectedProduct?.[category]
                    return (
                      <SelectedProductRow
                        labelWidth="300px"
                        key={product?.id}
                        label={product?.shortName ?? ''}
                        value={`R ${
                          toNumber(product?.pricingValue) > 0
                            ? round(toNumber(product?.pricingValue))
                            : calculateProductPrice(amountToFinance, product?.pricingValue ?? '')
                        }`}
                        onDelete={handleRemoveProduct(category)}
                      />
                    )
                  })}
                </Flex>
              </CardBody>
              <Divider />
              <CardFooter justify="space-between">
                <Flex justify="flex-start" alignItems="center">
                  <Text variant="faint">Total:</Text>
                  <Text variant="label" fontWeight={700} color="primaryDark.700" ml={2}>
                    R {formatFinanceAmount(totalEstimatedCost.toString())}
                  </Text>
                </Flex>
                <Button
                  data-testid="dashboard-link"
                  rightIcon={<ChevronRightIcon />}
                  variant="outline"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  onClick={handleSubmitProducts}
                  isLoading={isSavingUserProducts}
                >
                  I am interested
                </Button>
              </CardFooter>
            </Card>
          )}
        </Flex>
        <Products
          financeAmount={amountToFinance}
          selectedProduct={selectedProduct ?? {}}
          handleSelectProduct={handleSelectProduct}
        />
      </Stack>
    </Flex>
  )
}

export default ApprovalScreen
