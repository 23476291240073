import { Icon, QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AiOutlineCalculator } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { StepsNavigation } from '../../../containers/ApplicationSection'
import { useAuth } from '../../../context/AuthProvider'
import { usePreApprovalSteps } from '../../../context/PreApprovalStepsProvider'
import { FormSelectionData, useData } from '../../../context/UserDataProvider'
import {
  CalculatorInput,
  FinanceApplicationStatus,
  useUpdateMonthlyIncomeMutation
} from '../../../generated'
import { ERROR_MESSAGES, SERVER_ERROR_MESSAGE, exployeeDetailsSchema } from '../../../utils'
import formatFinanceAmount, {
  reverseFormatNumber
} from '../../../utils/math/format/formatFinanceAmount'
import DebtSettlementDrawer from '../../DebtSettlementDrawer'
import { SelectInput, TextInput } from '../../FormElements'
import InfoCard from '../../InfoCard'
import VWModal from '../../Modal'
import ApprovedPreApprovalModal from '../../Modal/ApprovedPreApprovalModal'
import ApprovedRejecetionDealModal from '../../Modal/ApprovedRejecetionDealModal'
import DeclinedPreApprovalModal from '../../Modal/DeclinedPreApprovalModal'
import TermsAndConditionsBody from '../../Modal/TermsAndConditionsBody'
import MonthlyExpenseCalculator from '../../MontlyExpenseCalculator'

export type ExpenseCategory =
  | 'rent'
  | 'householdExpenses'
  | 'transportCost'
  | 'entertainmentExpense'
  | 'educationExpense'
  | 'childMaintenance'
  | 'otherCosts'

export type MonthlyExpense = { [key in ExpenseCategory]: string }

function IncomeDetailForm(): React.ReactElement {
  const {
    employed,
    getBanks,
    banks,
    affordabilityError,
    persistProgress,
    persistedProgress,
    preApprovalRequestBody,
    setFinanceData
  } = useData()
  const { user, baseURL } = useAuth()
  const { onClose: onCloseDrawer, isOpen: isOpenDrawer, onOpen: onOpenDrawer } = useDisclosure()
  const { next, setIsStepLoading, isStepLoading, currentStep } = usePreApprovalSteps()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalBodyType, setModalBodyType] = React.useState<React.ReactElement>(
    <InfoCard {...{ title: '', info: '' }} />
  )
  const [modalFooterElement, setModalFooterElement] = React.useState<React.ReactElement>()
  const [showModalFooter, setShowModalFooter] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string>('')
  const [approved, setApproved] = React.useState<boolean>(false)

  /**
   * It takes a string as an argument and returns a FormSelectionData object if the string matches the
   * label or value of a bank
   * @param {string} value - The value of the input field.
   * @returns The return value is a FormSelectionData object.
   */
  const reverseBankNameToCodeAndBack = (
    value: string | undefined
  ): FormSelectionData | undefined => {
    return banks?.find((bank) => bank.label === value || bank.value === value)
  }

  const savedIncomeData = persistedProgress?.progress?.[0]
  const [monthyExpenseDetails, setMonthyExpenseDetails] = React.useState<MonthlyExpense>(
    savedIncomeData?.monthlyExpenseDetails
  )
  const savedBanksWith = reverseBankNameToCodeAndBack(savedIncomeData?.banksWith?.value)
  const employeeDetailsDefaultValues = React.useMemo(() => {
    return {
      monthlyGross: formatFinanceAmount(savedIncomeData?.monthlyGross) || '',
      monthlyNet: formatFinanceAmount(savedIncomeData?.monthlyNet) || '',
      additionalIncome: formatFinanceAmount(savedIncomeData?.additionalIncome) || '',
      monthlyExpenses: formatFinanceAmount(savedIncomeData?.monthlyExpenses) || '',
      email: user?.email ?? savedIncomeData?.email,
      banksWith: savedIncomeData?.banksWith,
      marketingConsent: savedIncomeData?.marketingConsent ?? user?.marketingConsent
    }
  }, [savedIncomeData, user])

  const methods = useForm({
    defaultValues: employeeDetailsDefaultValues,
    resolver: zodResolver(exployeeDetailsSchema)
  })

  React.useEffect(() => {
    methods.setValue('banksWith', savedBanksWith)
  }, [savedBanksWith])

  const [updateMonthliIncomeMutation] = useUpdateMonthlyIncomeMutation({
    onCompleted: async (response) => {
      const formData = methods.getValues()
      const todayDate = dayjs(`${new Date()}`).format('DD/MM/YYYY')
      const responseData = response?.updateMonthlyIncome

      if (['A', 'R'].includes(responseData?.vafAmount?.status as string)) {
        setFinanceData?.(responseData?.vafAmount?.data ?? '') // financeData
        showApprovedModal()
        await persistProgress?.({
          id: persistedProgress?.id as string,
          status: FinanceApplicationStatus.Pending,
          isCompleted: false,
          progress: {
            ...persistedProgress?.progress,
            currentStep: currentStep?.() && currentStep?.() + 1,
            [`${currentStep?.()}`]: {
              ...getValidInputData(),
              preApprovalAttempts: responseData?.preApprovalAttempts,
              monthlyExpenseDetails: monthyExpenseDetails,
              banksWith: formData.banksWith,
              marketingConsent: formData.marketingConsent,
              marketingConsentDate: todayDate,
              preApprovalDecision: 'Approved',
              preaApprovalAmount: responseData?.vafAmount?.amount
            }
          }
        })
      } else if (responseData?.vafAmount?.status === 'D') {
        await persistProgress?.({
          id: persistedProgress?.id as string,
          status: FinanceApplicationStatus.Declined,
          isCompleted: true,
          progress: {
            ...persistedProgress?.progress,
            currentStep: 0,
            [`${currentStep?.()}`]: {
              ...getValidInputData(),
              monthlyExpenseDetails: monthyExpenseDetails,
              banksWith: formData.banksWith,
              marketingConsent: formData.marketingConsent,
              marketingConsentDate: todayDate,
              preApprovalDecision: 'Declined',
              preApprovalAttempts: responseData?.preApprovalAttempts
            }
          }
        })
        showDeclinedModal()
      }

      setIsStepLoading?.(false)

      setError(
        ERROR_MESSAGES[responseData?.vafAmount?.errorMessage as string] ??
          responseData?.vafAmount?.errorMessage
      )
    },
    onError: () => {
      setIsStepLoading?.(false)
      setError(SERVER_ERROR_MESSAGE)
    },
    fetchPolicy: 'no-cache'
  })

  async function onNext() {
    setIsStepLoading?.(true)
    setError('')
    const preApprovalReqBody = JSON.parse(preApprovalRequestBody ?? '{}')
    const input = getValidInputData()
    const preApprovalRequestBodyJSON = JSON.stringify({
      ...preApprovalReqBody,
      income: {
        netIncome: input.monthlyNet,
        grossIncome: input.monthlyGross
      },
      expenses: {
        totalMonthlyExpenses: input.monthlyExpenses
      }
    })

    await updateMonthliIncomeMutation({
      variables: {
        input: {
          ...input,
          eventType: 'ESTIMATE_AFFORDABILITY',
          banksWith: methods.getValues().banksWith?.label as string,
          preApprovalRequestBody: preApprovalRequestBodyJSON
        }
      }
    })
  }

  /**
   * It takes the form data, parses it, and returns a CalculatorInput object
   * @returns the input data for the calculator.
   */
  const getValidInputData = (): CalculatorInput => {
    const formData = methods.getValues()
    const monthlyGross =
      typeof formData.monthlyGross === 'string'
        ? reverseFormatNumber(formData.monthlyGross)
        : formData.monthlyGross
    const monthlyNet =
      typeof formData.monthlyNet === 'string'
        ? reverseFormatNumber(formData.monthlyNet)
        : formData.monthlyNet
    const additionalIncome =
      typeof formData.additionalIncome === 'string'
        ? reverseFormatNumber(formData.additionalIncome)
        : formData.additionalIncome
    const monthlyExpenses =
      typeof formData.monthlyExpenses === 'string'
        ? reverseFormatNumber(formData.monthlyExpenses)
        : formData.monthlyExpenses
    return {
      ...formData,
      monthlyGross,
      monthlyNet,
      additionalIncome: formData.additionalIncome ? additionalIncome : 0,
      monthlyExpenses,
      employed,
      banksWith: formData.banksWith?.value ?? savedIncomeData?.banksWith
    } as CalculatorInput
  }

  const infoData = {
    monthlyIncome: `It's your monthly income before tax and other deductions are taken off.
			If you don't have any additional sources of income, your gross monthly income is the same as your gross salary, which you should be able to find on your payslip.`,
    netMonthly: `It's your monthly income after tax and other deductions are taken off.
			If you receive a salary, your net income is essentially the amount that gets paid into your account at the end of the month. It’s almost always less that than your gross income.`,
    monthlyExpense: 'Click to Edit monthly expenses'
  }

  function onCalculateExpenses(): void {
    setShowModalFooter(true)
    setModalBodyType(
      <MonthlyExpenseCalculator
        calculateTotal={handleCalculateTotal}
        monthlyExpense={monthyExpenseDetails}
        setMonthlyExpense={setMonthyExpenseDetails ?? savedIncomeData?.monthlyExpenseDetails}
      />
    )
    onOpen()
  }

  const handleCalculateTotal = (total: number) => {
    methods.setValue('monthlyExpenses', formatFinanceAmount(total.toString()))
    onClose()
  }

  React.useEffect(() => {
    /**
     * If the getBanks function is not defined, return. Otherwise, try to call the getBanks function. If
     * it fails, set the error state and log the error
     */
    getBanks?.().catch((e) => {
      setError('We are unable to fetch the current list of banks')
      console.log({ e })
    })
  }, [])

  const showTsAndCsModal = (): void => {
    setShowModalFooter(true)
    setModalBodyType(<TermsAndConditionsBody />)
    setModalFooterElement(
      <Button data-testid="detault-modal-close-button" onClick={onClose}>
        {'GOT IT'}
      </Button>
    )
    onOpen()
  }

  const onCompleteDetailsHandler = () => {
    // Close the modal
    onClose()
    // Go to next step
    next?.()
  }

  const showApprovalModal = (approved: boolean): void => {
    setShowModalFooter(false)
    setModalBodyType(
      <ApprovedRejecetionDealModal
        dealApproved={approved}
        onCompleteDetails={onCompleteDetailsHandler}
        onGoToDashboard={() => {
          // Close the modal
          onClose()
          // Go to the dashboard
          navigate(`${baseURL}auth/dashboard`)
        }}
      />
    )
    onOpen()
  }

  const showApprovedModal = () => {
    setApproved(true)
    setShowModalFooter(false)
    setModalBodyType(
      <ApprovedPreApprovalModal
        onOpenSettlementDrawer={() => {
          onClose()
          onOpenDrawer()
        }}
        onCompleteDetails={onCompleteDetailsHandler}
        onGoToDashboard={() => {
          // Close the modal
          onClose()
          // Go to the dashboard
          navigate(`${baseURL}auth/dashboard`)
        }}
      />
    )
    onOpen()
  }

  const showDeclinedModal = () => {
    setApproved(false)
    setShowModalFooter(false)
    setModalBodyType(
      <DeclinedPreApprovalModal
        onOpenSettlementDrawer={onOpenDrawer}
        onClose={onClose}
        onGoToDashboard={() => {
          // Close the modal
          onClose()
          // Go to the dashboard
          navigate(`${baseURL}auth/dashboard`)
        }}
      />
    )
    onOpen()
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: 'monthlyGross' | 'monthlyNet' | 'additionalIncome' | 'monthlyExpenses'
  ) => {
    const { value } = e.target
    const numericValue = value.replace(/\D/g, '') // remove non-numeric characters
    const formattedValue = formatFinanceAmount(numericValue)
    methods.setValue(name, formattedValue)
  }

  return (
    <>
      <FormProvider {...methods}>
        <VWModal
          showFooter={showModalFooter}
          onClose={onClose}
          size="full"
          isOpen={isOpen}
          modalBody={modalBodyType}
          footerElement={modalFooterElement}
        >
          <Box />
        </VWModal>
        <Stack spacing={1}>
          <TextInput
            label="Your gross monthly income (before deductions)"
            name="monthlyGross"
            leftIcon={<Text>R</Text>}
            rightIcon={
              <Tooltip label={infoData.monthlyIncome}>
                <QuestionOutlineIcon />
              </Tooltip>
            }
            isRequired
            isDisabled={isStepLoading}
            onChange={(e) => handleInputChange(e, 'monthlyGross')}
          />
          <TextInput
            label="Your net monthly income (after deductions)"
            name="monthlyNet"
            leftIcon={<Text>R</Text>}
            rightIcon={
              <Tooltip label={infoData.netMonthly}>
                <QuestionOutlineIcon />
              </Tooltip>
            }
            isRequired
            isDisabled={isStepLoading}
            onChange={(e) => handleInputChange(e, 'monthlyNet')}
          />
          <TextInput
            leftIcon={<Text>R</Text>}
            label="Total additional income (optional)"
            name="additionalIncome"
            isDisabled={isStepLoading}
            onChange={(e) => handleInputChange(e, 'additionalIncome')}
          />
          <TextInput
            label="Your total monthly expenses (click on the calculator to edit)"
            name="monthlyExpenses"
            leftIcon={<Text>R</Text>}
            rightIcon={
              <Tooltip label={infoData.monthlyExpense}>
                <IconButton
                  data-testid="expense-calculator-button"
                  border={'none'}
                  size="sm"
                  variant="outline"
                  background={'white'}
                  aria-label="Need help working this out?"
                  icon={<Icon as={AiOutlineCalculator} boxSize={5} />}
                  onClick={onCalculateExpenses}
                />
              </Tooltip>
            }
            isRequired
            isDisabled={true}
            onChange={(e) => handleInputChange(e, 'monthlyExpenses')}
          />
          <SelectInput
            label="Who do you bank with?"
            placeholder="Select Bank"
            name="banksWith"
            options={banks}
            isDisabled={isStepLoading}
            defaultValue={savedBanksWith}
          />
          <TextInput
            label="Email address"
            name="email"
            type="email"
            isRequired
            defaultValue={user?.email ?? savedIncomeData?.email}
            isDisabled={isStepLoading}
          />
          <Checkbox
            py={4}
            size="md"
            color="primaryDark.500"
            data-testid="marketing-consent-checkbox"
            name="marketingConsent"
            defaultChecked={savedIncomeData?.marketingConsent ?? user?.marketingConsent}
            onChange={(event) => methods.setValue('marketingConsent', event.target.checked)}
          >
            I would like to receive relevant marketing information via email
          </Checkbox>
          <Text>
            {`By continuing you hereby consent to Volkswagen Financial Services South Africa (Pty) Ltd
          performing a full credit assessment of you, accessing your credit profile from the credit
          bureau and processing the information obtained during this process in accordance with
          Volkswagen Financial Services South Africa (Pty) Ltd's privacy policy`}
          </Text>
          <Button
            data-testid="terms-and-conditions-link"
            justifyContent="left"
            padding={0}
            variant="link"
            color="primaryDark.600"
            onClick={showTsAndCsModal}
          >
            READ FULL TERMS AND CONDITIONS
          </Button>
          <Text color="red">{error}</Text>
          {affordabilityError && <Text color="red">{affordabilityError}</Text>}
          <StepsNavigation
            primaryActionId="submit-income-details"
            isLoading={isStepLoading}
            secondaryAction={() => {
              // Add a confirmation modal
            }}
            primaryAction={async () => {
              await methods.handleSubmit(onNext)()
              return false
            }}
          />
        </Stack>
      </FormProvider>
      {isOpenDrawer && (
        <DebtSettlementDrawer
          isApproved={approved}
          onClose={onCloseDrawer}
          isOpen={isOpenDrawer}
          banksWith={methods.getValues().banksWith?.label as string}
          incomeData={getValidInputData}
          showApprovalModal={showApprovalModal}
          onCloseDrawer={onCloseDrawer}
        />
      )}
    </>
  )
}

export default IncomeDetailForm
