import { z } from 'zod'

///////Create User checks
export const aboutVehicleDefaultValues = {
  year: '',
  make: '',
  model: '',
  purchasePrice: '',
  condition: ''
}

export type AboutVehicleData = typeof aboutVehicleDefaultValues

export const aboutVehicleSchema = z.object({
  year: z
    .string()
    .regex(/^[0-9]*$/, 'Please enter a valid year')
    .min(4, 'Please enter a valid year'),
  make: z
    .object({
      label: z.string(),
      value: z.string({ required_error: 'Make is required' })
    })
    .required(),
  model: z
    .object({
      label: z.string(),
      value: z.string({ required_error: 'Model is required' })
    })
    .required(),
  condition: z
    .object({
      label: z.string(),
      value: z.string({ required_error: 'Condition is required' })
    })
    .required(),
  purchasePrice: z
    .string()
    .min(4, 'Purchase price is requred.')
    .max(1000000000, 'Please add a maximum value of 1 billion')
})
