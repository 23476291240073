import { Button, Spinner, Stack, Text, useToast } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { TextArea, TextInput } from '../'
import { useSendSupportRequestMutation } from '../../generated'
import { EMAIL_VALID_REGEX, SA_PHONE_NUMBER_REGEX } from '../../utils'

const defaultValues = {
  name: '',
  email: '',
  phoneNumber: '',
  description: ''
}

export const createUserSchema = z.object({
  name: z.string(),
  email: z
    .string()
    .min(5, 'Email address is required')
    .regex(EMAIL_VALID_REGEX, 'Please enter a valid email'),
  phoneNumber: z
    .string()
    .min(10, 'Phone number is required')
    .regex(SA_PHONE_NUMBER_REGEX, 'Please enter a valid phone number, without country code'), //Limits input to numbers only
  description: z.string().min(8, 'Message is required')
})

type Props = {
  onClosePopover: () => void
}

const SupportRequestMessage: React.FC<Props> = ({ onClosePopover }) => {
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(createUserSchema)
  })
  const toast = useToast()

  const [error, setError] = React.useState('')

  const [sendSupportRequest, { loading }] = useSendSupportRequestMutation({
    onError: (error) => {
      setError(error.message)
    },
    onCompleted: (res) => {
      toast({
        title: 'Support Request Sent!',
        description: res.sendSupportRequest,
        status: 'success',
        duration: 9000,
        isClosable: true,
        colorScheme: 'primaryDark'
      })

      methods.reset()
      onClosePopover()
    }
  })

  const onSubmit = async () => {
    const input = methods.getValues()
    await sendSupportRequest({
      variables: {
        input: { ...input }
      }
    })
  }

  return (
    <FormProvider {...methods}>
      <Text textAlign="center" py={4} fontWeight={600} fontSize={18}>
        Send us a message
      </Text>
      <Stack spacing={2} justify="center" color="brand.200">
        <TextInput
          data-testid="support-name-text-input"
          label="Name"
          name="name"
          id="name-input"
          type="text"
        />
        <TextInput data-testid="support-email-text-input" label="Email" name="email" type="email" />
        <TextInput
          label="Phone Number"
          name="phoneNumber"
          data-testid="support-phone-number-text-input"
          type="text"
        />
        <TextArea data-testid="support-message-text-input" label="Message" name="description" />
        <Stack spacing={6}>
          <Text data-testid="signup-error-text" fontSize="sm" color="red.300">
            {error}
          </Text>
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            data-testid="support-submit-button"
            loadingText=""
            size="lg"
            id="login-button"
          >
            {loading ? <Spinner /> : 'Send message'}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default SupportRequestMessage
