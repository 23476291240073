import { useSteps } from '@chakra-ui/react'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  AddressScreen,
  AffordabilityCalculatorScreen,
  EmployeeDetailsScreen,
  MaritalStatusScreen,
  PaymentStructureScreen,
  TellUsAboutYourVehicleScreen
} from '../../containers'

type StepsProps = {
  label: string
  description: string
}
type PreApprovalStepsProviderProps = {
  contents: Array<React.ReactElement>
  next: () => void
  previous: () => void
  currentStep: () => number
  setCurrentStep: (step: number) => void
  currentContent: () => React.ReactElement
  steps: Array<StepsProps>
  currentStepInfo: () => StepsProps
  isStepLoading: boolean
  setIsStepLoading: (value: boolean) => void
}

const PreApprovalStepsContext = React.createContext<Partial<PreApprovalStepsProviderProps>>({})

export const usePreApprovalSteps = () => React.useContext(PreApprovalStepsContext)

export default function PreApprovalStepsProvider({
  children
}: {
  children: React.ReactElement
}): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams()
  const routeStep = searchParams.get('step') ?? undefined
  const {
    goToNext: nextStep,
    goToPrevious: prevStep,
    activeStep,
    setActiveStep: setStep
  } = useSteps({
    index: routeStep ? Number(routeStep) : 0
  })

  const contents = [
    <AffordabilityCalculatorScreen key="Affordability" />,
    <TellUsAboutYourVehicleScreen key="aboutVehicle" />,
    <AddressScreen key="address" />,
    <MaritalStatusScreen key="marital-status" />,
    <EmployeeDetailsScreen key="employment-details" />,
    <PaymentStructureScreen key="payment-structure" />
  ]

  const next = () => {
    setSearchParams({ step: `${activeStep + 1}` })
    nextStep()
  }
  const previous = () => {
    setSearchParams({ step: `${activeStep - 1}` })
    prevStep()
  }
  const currentStep = () => activeStep
  const setCurrentStep = (step: number) => {
    setStep(step)
  }
  const currentContent = () => contents[activeStep]
  const steps = [
    { label: 'Estimating Affordability', description: 'Estimating Affordability' },
    { label: 'Tell us about the vehicle', description: 'Tell us about the vehicle' },
    { label: 'Where do you live?', description: 'Where do you live?' },
    {
      label: 'Marital Status',
      description: 'Choose the option that describes your currrent status'
    },
    { label: 'Employment Details', description: 'Employment Details' },
    { label: 'Payment Structure', description: 'Payment Structure' }
  ]
  const [isStepLoading, setIsStepLoading] = React.useState(false)

  const currentStepInfo = () => steps[activeStep]

  const stateData = {
    next,
    previous,
    currentStep,
    setCurrentStep,
    contents,
    currentContent,
    steps,
    currentStepInfo,
    isStepLoading,
    setIsStepLoading
  }

  return (
    <PreApprovalStepsContext.Provider value={stateData}>
      {children}
    </PreApprovalStepsContext.Provider>
  )
}
