import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box, Button, FormLabel, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import AboutVehicleModal from '../../../components/Form/TellUsForm'
import VWModal from '../../../components/Modal'
import ApprovedRejecetionDealModal from '../../../components/Modal/ApprovedRejecetionDealModal'
import { StepsNavigation } from '../../../containers/ApplicationSection'
import { useAppContext } from '../../../context/AppProvider'
import { useAuth } from '../../../context/AuthProvider'
import { usePreApprovalSteps } from '../../../context/PreApprovalStepsProvider'
import { useData } from '../../../context/UserDataProvider'
import {
  DealerExtra,
  FinanceApplicationStatus,
  IndustryRoleOption,
  IndustryRolesPayload,
  useAllEmployerTypesLazyQuery,
  useAllOccupationTypesLazyQuery,
  useOneIndustryRoleLazyQuery,
  useUpdateEmploymentDataMutation
} from '../../../generated'
import { RadioInput } from '../../../models/RadioInput'
import { ERROR_MESSAGES, SERVER_ERROR_MESSAGE, formatPhoneNumber } from '../../../utils'
import { employeeDetailSchema } from '../../../utils/schemas/employeeDetailsSchema'
import { TextInput } from '../../FormElements'
import {
  audiBaseStyles,
  audifsTheme,
  vwBaseStyles,
  vwfsTheme
} from '../../FormElements/SelectInput'
import InfoCard from '../../InfoCard'
import VWRadioGroup from '../../VWRadioGroup'
import dayjs from 'dayjs'

function EmploymentDetailsForm() {
  const { baseURL, appName } = useAuth()
  const { aboutVehicle } = useAppContext()
  const { next, setIsStepLoading, isStepLoading, currentStep } = usePreApprovalSteps()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const {
    newUsedVehicle,
    persistedProgress,
    persistProgress,
    paymentMode,
    setFinanceData,
    updatePreApprovalId,
    preApprovalRequestBody
  } = useData()
  const savedEmployeeDetails = persistedProgress?.progress?.[currentStep?.() ?? 4]

  const methods = useForm({
    defaultValues: {
      companyName: savedEmployeeDetails?.companyName ?? '',
      industryCategory: savedEmployeeDetails?.industryCategory,
      timeOfEmploymentYears: savedEmployeeDetails?.timeOfEmploymentYears,
      timeOfEmploymentsMonths: savedEmployeeDetails?.timeOfEmploymentsMonths,
      phoneNumber: formatPhoneNumber(savedEmployeeDetails?.phoneNumber) ?? '',
      occupation: savedEmployeeDetails?.occupation
    },
    resolver: zodResolver(employeeDetailSchema)
  })

  const [modalBodyType, setModalBodyType] = React.useState<React.ReactElement>(
    <InfoCard {...{ title: '', info: '' }} />
  )

  const [allEmployerTypes, setAllEmployerTypes] = React.useState<Array<IndustryRoleOption>>([])
  const [fetchAllEmployerTypes, { loading: loadingEmployerTypes }] = useAllEmployerTypesLazyQuery({
    onCompleted: (res) => {
      setAllEmployerTypes(res.allEmployerTypes as IndustryRoleOption[])
    }
  })

  React.useEffect(() => {
    fetchAllEmployerTypes({
      variables: {
        filter: {
          searchTerm: ''
        }
      }
    })
  }, [])

  const filterEmployerTypes = async (inputValue?: string) => {
    const res = await fetchAllEmployerTypes({
      variables: {
        filter: {
          searchTerm: inputValue
        }
      }
    })

    methods.setValue('industryCategory', { label: undefined, value: undefined })

    return res?.data?.allEmployerTypes as IndustryRoleOption[]
  }

  const promiseEmployerTypeOptions = (inputValue?: string) =>
    Promise.resolve<IndustryRoleOption[]>(filterEmployerTypes(inputValue))

  const [allOccupationTypes, setAllOccupationTypes] = React.useState<Array<IndustryRoleOption>>([])
  const [fetchAllOccupationTypes, { loading: loadingOccupationTypes }] =
    useAllOccupationTypesLazyQuery({
      onCompleted: (res) => {
        setAllOccupationTypes(res.allOccupationTypes as IndustryRoleOption[])
      }
    })

  const watchEmployerType = methods.watch('industryCategory')?.value

  React.useEffect(() => {
    fetchAllOccupationTypes({
      variables: {
        filter: {
          employerType: watchEmployerType,
          searchTerm: ''
        }
      }
    })
  }, [watchEmployerType])

  const filterOccupationTypes = async (inputValue?: string) => {
    const res = await fetchAllOccupationTypes({
      variables: {
        filter: {
          employerType: watchEmployerType,
          searchTerm: inputValue
        }
      }
    })

    methods.setValue('occupation', { label: undefined, value: undefined })

    return res?.data?.allOccupationTypes as IndustryRoleOption[]
  }

  const promiseOccupationTypeOptions = (inputValue?: string) =>
    Promise.resolve<IndustryRoleOption[]>(filterOccupationTypes(inputValue))

  const [industryRole, setIndustryRole] = React.useState<IndustryRolesPayload>()
  const [fetchIndustryRole] = useOneIndustryRoleLazyQuery({
    onCompleted: (res) => {
      setIndustryRole(res.oneIndustryRole as IndustryRolesPayload)
    }
  })

  const watchOccupationType = methods.watch('occupation')?.value

  React.useEffect(() => {
    fetchIndustryRole({
      variables: {
        filter: {
          employerType: watchEmployerType,
          OccupationType: watchOccupationType
        }
      }
    })
  }, [watchOccupationType, watchEmployerType])

  const showApprovalModal = (approved: boolean): void => {
    setModalBodyType(
      <ApprovedRejecetionDealModal
        dealApproved={approved}
        onCompleteDetails={() => {
          // Close the modal
          onClose()
          // Go to next step
          next?.()
        }}
        onGoToDashboard={() => {
          // Close the modal
          onClose()
          // Go to the dashboard
          navigate(`${baseURL}auth/dashboard`)
        }}
      />
    )
    onOpen()
  }

  const showVehicleModal = () => {
    setModalBodyType(<AboutVehicleModal onClose={onClose} />)
    onOpen()
  }

  const [error, setError] = useState('')

  const [isAGraduate, setIsAGraduate] = useState<boolean>(false)

  type typeOfResidentialAddress = 'true' | 'false'

  const isGraduateValues: RadioInput<typeOfResidentialAddress>[] = [
    { value: 'true', message: 'Yes' },
    { value: 'false', message: 'No' }
  ]

  const [updateEmploymentDataMutation] = useUpdateEmploymentDataMutation({
    onCompleted: async (response) => {
      const responseData = response?.updateEmploymentData
      if (responseData?.status === 'D') {
        onOpen()
        showApprovalModal(false)
        setIsStepLoading?.(false)
        return
      } else if (responseData?.status === 'A') {
        setFinanceData?.(responseData?.data ?? '') // financeData
        updatePreApprovalId?.(responseData?.data ?? '')
        onOpen()
        showApprovalModal(true)
        setIsStepLoading?.(false)

        const values = methods.getValues()

        values.industryCategory = {
          label: industryRole?.employerType ?? savedEmployeeDetails?.industryCategory?.label,
          value: industryRole?.employerTypeCode ?? savedEmployeeDetails?.industryCategory?.value
        }
        values.occupation = {
          label: industryRole?.occupationType ?? savedEmployeeDetails?.occupation?.label,
          value: industryRole?.occupationTypeCode ?? savedEmployeeDetails?.occupation?.value
        }
        const industry = {
          label: industryRole?.industryType ?? savedEmployeeDetails?.industry?.label,
          value: industryRole?.industryTypeCode ?? savedEmployeeDetails?.industry?.value
        }

        const savedVehicleData = persistedProgress?.progress?.[1]
        const asset = {
          year: savedVehicleData?.year,
          mmcode: persistedProgress?.progress?.mmCode,
          price: parseFloat(savedVehicleData?.purchasePrice),
          buyingType: 'D',
          dealerCode: aboutVehicle?.dealerCode ?? '00006018',
          newUsed: newUsedVehicle ?? 'N'
        }
        const dealerExtra: DealerExtra = {
          amount: 1207.5,
          code: paymentMode ?? 'IFC'
        }

        const nextStep = currentStep?.() && currentStep?.() + 1

        const todayDate = dayjs(`${new Date()}`).format('DD/MM/YYYY')

        await persistProgress?.({
          id: persistedProgress?.id as string,
          status: FinanceApplicationStatus.Pending,
          isCompleted: false,
          progress: {
            ...persistedProgress?.progress,
            currentStep: nextStep,
            financeData: responseData?.data,
            [`${nextStep && nextStep - 1}`]: {
              ...values,
              industry,
              isAGraduate,
              asset,
              dealerExtra,
              employeePagecompletedAt: todayDate
            }
          }
        })
      }

      setIsStepLoading?.(false)
      setError(ERROR_MESSAGES[responseData?.errorMessage as string] ?? responseData?.errorMessage)
    },
    onError: () => {
      setIsStepLoading?.(false)
      setError(SERVER_ERROR_MESSAGE)
    }
  })

  const onSubmit = async (): Promise<void> => {
    // check if the user has provided a valid duration of employment
    if (
      methods.getValues().timeOfEmploymentYears === '0' &&
      methods.getValues().timeOfEmploymentsMonths === '0'
    )
      return setError('Duration of employment is required')

    setError('')
    setIsStepLoading?.(true)

    const values = methods.getValues()
    const savedVehicleData = persistedProgress?.progress?.[1]

    const asset = {
      year: savedVehicleData?.year,
      mmcode: persistedProgress?.progress?.mmCode,
      price: parseFloat(savedVehicleData?.purchasePrice),
      buyingType: 'D',
      dealerCode: aboutVehicle?.dealerCode ?? '00006018',
      newUsed: newUsedVehicle ?? 'N'
    }

    const dealerExtra: DealerExtra = {
      amount: 1207.5,
      code: paymentMode ?? 'IFC'
    }
    const preApprovalReqBody = JSON.parse(preApprovalRequestBody ?? '{}')

    const input = {
      phone: values.phoneNumber,
      companyName: values.companyName,
      occupation: values.occupation.label,
      industryCategory: values.industryCategory?.label,
      employmentTimeInMonths: parseInt(values.timeOfEmploymentsMonths),
      employmentTimeInYears: parseInt(values.timeOfEmploymentYears),
      industry: industryRole?.industryType ?? savedEmployeeDetails?.industry.label,
      isAGraduate,
      asset,
      dealerExtra
    }

    const preApprovalRequestJSON = JSON.stringify({
      ...preApprovalReqBody,
      customerProfile: {
        ...preApprovalReqBody.customerProfile,
        occupation: {
          ...preApprovalReqBody.customerProfile?.occupation,
          durationMonth: input.employmentTimeInMonths,
          durationYear: input.employmentTimeInYears
        }
      },
      preApproval: {
        preApprovalType: 'V'
      },
      dealerExtras: [input.dealerExtra],
      asset: input.asset
    })

    await updateEmploymentDataMutation({
      variables: { input: { ...input, preApprovalRequestBody: preApprovalRequestJSON } }
    })
  }

  /**
   * This function handles input change for time of employment fields, ensuring the input value is within
   * a specified range.
   * @param e - e is an event object of type React.ChangeEvent<HTMLInputElement>, which is triggered when
   * the input value changes.
   * @param {number} max - The maximum value that the input field can accept.
   * @param {number} min - The minimum value that the input can have.
   * @param {'timeOfEmploymentsMonths' | 'timeOfEmploymentYears'} name - The `name` parameter is a string
   * that specifies the name of the input field that is being changed. It can be either
   * `'timeOfEmploymentsMonths'` or `'timeOfEmploymentYears'`.
   * @returns The function is not returning anything explicitly. It may return undefined implicitly if
   * none of the conditions in the if-else block are met.
   */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    max: number,
    min: number,
    name: 'timeOfEmploymentsMonths' | 'timeOfEmploymentYears'
  ) => {
    const value = parseInt(e.target.value, 10)
    if (value > max) {
      methods.setValue(name, max.toString())
      return
    } else if (value < min) {
      methods.setValue(name, min.toString())
      return
    }
    methods.setValue(name, value.toString())
  }

  const baseStyles = appName === 'audifs' ? audiBaseStyles : vwBaseStyles
  const baseTheme = appName === 'audifs' ? audifsTheme : vwfsTheme

  return (
    <FormProvider {...methods}>
      <VWModal
        showFooter={false}
        onClose={onClose}
        size="full"
        isOpen={isOpen}
        modalBody={modalBodyType}
      >
        <Box />
      </VWModal>
      <Stack spacing={1} justify="center" color="brand.200">
        <FormLabel data-testid="employee-details-degree-label">
          Have you obtained a degree or diploma within the last 3 years?
        </FormLabel>
        <VWRadioGroup
          values={isGraduateValues}
          setValue={(value) => setIsAGraduate(JSON.parse(value))}
          currentValue={isAGraduate.toString()}
          isDisabled={isStepLoading}
        />
        <TextInput
          data-testid="employee-details-company-input"
          defaultValue={savedEmployeeDetails?.companyName}
          label="Company name"
          name="companyName"
          type="text"
          isDisabled={isStepLoading}
        />
        <FormLabel id="make">Industry category</FormLabel>
        <Controller
          name="industryCategory"
          control={methods.control}
          render={({ field }) => (
            <AsyncSelect
              {...field}
              placeholder={'Industry category'}
              data-testid="employee-details-industry-category-input"
              name="industryCategory"
              aria-labelledby="industryCategory"
              defaultOptions={allEmployerTypes}
              loadOptions={promiseEmployerTypeOptions}
              isLoading={loadingEmployerTypes}
              isDisabled={isStepLoading}
              styles={{
                control: (styles) => ({
                  ...styles,
                  ...baseStyles,
                  height: '40px',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                })
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  ...baseTheme
                }
              })}
              cacheOptions={false}
            />
          )}
        />
        <FormLabel id="make">Occupation</FormLabel>
        <Controller
          name="occupation"
          control={methods.control}
          render={({ field }) => (
            <AsyncSelect
              {...field}
              placeholder="Occupation"
              data-testid="employee-details-occupation-input"
              name="occupation"
              aria-labelledby="occupation"
              defaultOptions={allOccupationTypes}
              loadOptions={promiseOccupationTypeOptions}
              isLoading={loadingOccupationTypes}
              isDisabled={isStepLoading}
              styles={{
                control: (styles) => ({
                  ...styles,
                  ...baseStyles,
                  height: '40px',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                })
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  ...baseTheme
                }
              })}
              cacheOptions={false}
            />
          )}
        />
        <TextInput
          data-testid="employee-details-phone-number"
          label="Phone number"
          name="phoneNumber"
          isDisabled={isStepLoading}
        />
        <FormLabel>How long have you worked here?</FormLabel>

        <HStack pb="0.8rem">
          <TextInput
            data-testid="employee-details-timeOfEmploymentYears"
            label="Years"
            name="timeOfEmploymentYears"
            type="number"
            onChange={(e) => handleInputChange(e, 100, 0, 'timeOfEmploymentYears')}
            defaultValue={savedEmployeeDetails?.timeOfEmploymentYears}
            isDisabled={isStepLoading}
          />
          <TextInput
            data-testid="employee-details-timeOfEmploymentsMonths"
            label="Months"
            name="timeOfEmploymentsMonths"
            type="number"
            max={11}
            onChange={(e) => handleInputChange(e, 11, 0, 'timeOfEmploymentsMonths')}
            defaultValue={savedEmployeeDetails?.timeOfEmploymentsMonths}
            isDisabled={isStepLoading}
          />
        </HStack>
        <HStack pb="0.8rem" justifyContent="space-between" alignItems="center">
          <Text
            data-testid="employee-details-error-message"
            id="employee-detail-error-message"
            fontSize={'sm'}
            color={'red.300'}
          >
            {error}
          </Text>
          {(error === 'Model not found' || error?.includes('Model was discontinued')) && (
            <Button
              data-testid="update-vehicle-link"
              color="primaryDark.500"
              variant="link"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              height="20px"
              gap="8px"
              onClick={showVehicleModal}
            >
              Change vehicle model here
              <ArrowForwardIcon />
            </Button>
          )}
        </HStack>
        <StepsNavigation
          isLoading={isStepLoading}
          primaryActionId="employee-detail-form-button"
          secondaryAction={() => {
            // do nothing
          }}
          primaryAction={async () => {
            await methods.handleSubmit(onSubmit)()
            return false
          }}
        />
      </Stack>
    </FormProvider>
  )
}

export default EmploymentDetailsForm
