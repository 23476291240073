import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import React, { ReactNode } from 'react'
import { fetchJwt } from '../../utils/helpers'
import { USER_STORAGE_KEY } from '../../constants'

export type ApolloGQLProviderProps = {
  children: ReactNode
}

const ApolloGQLProvider: React.FC<ApolloGQLProviderProps> = ({
  children
}: ApolloGQLProviderProps) => {
  const httpLink = createHttpLink({
    uri: import.meta.env.VITE_API_ENDPOINT
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = fetchJwt()
    // return the headers to the context so httpLink can read them

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const errorLink = onError((res) => {
    if ((res?.networkError as any)?.result?.errors[0].message.includes('Invalid token.')) {
      window.localStorage.removeItem(USER_STORAGE_KEY)
      window.sessionStorage.removeItem(USER_STORAGE_KEY)
      window.location.replace(process.env.REACT_APP_CLIENT_HOST || '')
    }
  })

  const link = authLink.concat(errorLink).concat(httpLink)

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache()
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloGQLProvider
